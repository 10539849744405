import React, { useContext, useEffect } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ReservationForm from "../../components/reservation/ReservationForm";
import { InitialContext } from "../../hooks/InitialContext";
import { PagoContext } from "../../hooks/PagoContext";
import { PasajerosContext } from "../../hooks/PasajerosContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

function Reservation() {
  const { fullRestart } = useContext(InitialContext);

  const { fullRestartResultados } = useContext(ResultadosContext);

  const { fullRestartPasajeros } = useContext(PasajerosContext);

  const { fullRestartPago } = useContext(PagoContext);

  useEffect(() => {
    fullRestart();
    fullRestartResultados();
    fullRestartPasajeros();
    fullRestartPago();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-width-4 mx-auto">
      <div className="btrz-page-header clearfix flex items-center pt2 pb2 sm-pt3 sm-pb3">
        <Breadcrumb title="Limousines" />
        <div id="content"></div>
      </div>
      <ReservationForm />
    </div>
  );
}

export default Reservation;
