import React, { useContext } from "react";
import NumberInput from "../common/NumberInput";
import { InitialContext } from "../../hooks/InitialContext";

type TipoTicketsProps = {
  tipoTicket: any;
};

const TipoTicket: React.FC<TipoTicketsProps> = ({ tipoTicket }) => {
  const { faltantes } = useContext(InitialContext);

  return (
    <div className="btrz-card mb3 p4 sm-p5 bg-white box-shadow-black-10">
      <div className="clearfix remove-mb4-last-child sm-mxn4 sm-flex flex-wrap">
        {tipoTicket?.map((e: any) => {
          return (
            <div key={e.id} className="col-12 sm-col-6 sm-px4 mb4">
              <NumberInput title={e?.name} />
            </div>
          );
        })}
      </div>
      {faltantes.includes("pasajes") && (
        <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
          <span>
            <i className="fa fa-exclamation-circle mr1"></i>Por favor seleccione
            una tarifa
          </span>
        </div>
      )}
      {faltantes.includes("companion") && (
        <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
          <span>
            <i className="fa fa-exclamation-circle mr1"></i>
            {
              tipoTicket?.find((e: any) => e.companion === "requiresCompanion")
                .error_message
            }
          </span>
        </div>
      )}
    </div>
  );
};

export default TipoTicket;
