import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../common/Button";
import TipoTicket from "./TipoTicket";
import Datos from "./Datos";
import Loader from "../common/Loader";
import Error from "../common/Error";
import { getTicketTypes } from "../../services/TipoTicketService";
import { fetchDataDestinos, handleSearch } from "../../hooks/Reservation/hooks";
import { getCiudades } from "../../services/CiudadesService";

import { useAuth } from "../../utils/AuthContext";
import { InitialContext } from "../../hooks/InitialContext";
import { PagoContext } from "../../hooks/PagoContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

const ReservationForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tipoTicket, setTipoTicket] = useState([
    { name: "", id: "", default_quantity: 0, companion: "", error_message: "" },
  ]);
  const [isLoadingDestinos, setIsLoadingDestino] = useState(true);
  const [origenes, setOrigenes] = useState([{}]);
  const [destinos, setDestinos] = useState([{}]);

  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAuth();

  const { reserva, updateFaltantes, updateResultados, updateReserva } =
    useContext(InitialContext);

  const { firstUpdateTarifaSeleccionada, setIdaVuelta, idaVuelta } =
    useContext(ResultadosContext);

  const { mensajeOrder, setMensajeOrder, nroSolicitud, textMensaje } =
    useContext(PagoContext);

  const campos_obligatorios = ["origen", "destino", "salida", "pasajes"];

  const abortController = new AbortController();

  const fetchTickets = async () => {
    try {
      let temp: any = await getTicketTypes(logout);
      setTipoTicket(temp);
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }

    setIsLoading(false);
  };

  const fetchCiudades = async () => {
    try {
      let temp = await getCiudades(logout);
      temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setOrigenes(temp);
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const temp = tipoTicket?.map((e) => {
      let existe = reserva.pasajes.find((m) => m.id === e.id);

      if (existe) {
        return {
          name: e.name,
          cantidad: existe.cantidad,
          id: e.id,
          companion: e.companion,
          error_message: e.error_message,
        };
      } else {
        return {
          name: e.name,
          cantidad: e.default_quantity,
          id: e.id,
          companion: e.companion,
          error_message: e.error_message,
        };
      }
    });

    updateReserva({ pasajes: temp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoTicket]);

  // SOLICITO LOS ORIGENES
  useEffect(() => {
    setIsLoading(true);
    fetchCiudades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CUANDO CAMBIA EL ORIGEN
  // SOLICITO LOS DESTINOS
  useEffect(() => {
    setIsLoadingDestino(true);

    const fetchData = async () => {
      try {
        setDestinos([]);
        updateReserva({
          destino: {
            name: "",
            id: "",
            company: "",
          },
        });

        const result = await fetchDataDestinos(reserva, logout);
        setDestinos(result);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      } finally {
        setIsLoadingDestino(false);
      }
    };

    if (reserva.origen.id && reserva.origen.company) {
      fetchData();
    }

    return () => abortController.abort(); // Cleanup: Abortar la solicitud en caso de que el componente se desmonte

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.origen, location.pathname]);

  // INICIALIZO VALORES
  useEffect(() => {
    setIdaVuelta("departures");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader isLoading={isLoading}>
      {error && (
        <Error
          message="No hay disponibilidad o no hay viajes programados para las fechas seleccionadas"
          onClose={setError}
        />
      )}
      {mensajeOrder && (
        <Error
          message={`${textMensaje}
        Solicitud: ${nroSolicitud}`}
          onClose={setMensajeOrder}
        />
      )}
      <form>
        <Datos
          origenes={origenes}
          destinos={destinos}
          isLoadingDestinos={isLoadingDestinos}
        />
        <TipoTicket tipoTicket={tipoTicket} />
        <div className="clearfix mb3 sm-pt4 sm-pb4 pt3 pb3 ">
          <div className="col col-6 sm-col-4 pr2"></div>
          <div className="col col-6 sm-col-4 col-right pl2">
            <Button
              text="Buscar viajes"
              handleClick={() =>
                handleSearch(
                  campos_obligatorios,
                  reserva,
                  updateFaltantes,
                  setIsLoading,
                  setError,
                  updateResultados,
                  firstUpdateTarifaSeleccionada,
                  navigate,
                  idaVuelta,
                  logout
                )
              }
            />
          </div>
        </div>
      </form>
    </Loader>
  );
};

export default ReservationForm;
