import { ReserveAsientoType } from "../types/ReserveAsiento";
import { send } from "./apiService";

export async function getReserve(
  query: FormData,
  logout: any
): Promise<ReserveAsientoType> {
  const types = await send<ReserveAsientoType>(
    "/route/reserve/",
    query,
    logout
  );
  return types;
}
