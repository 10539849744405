import React, { useContext, useEffect, useState } from "react";
import { getPayment } from "../../services/PaymentService";
import Cash from "./tipos/Cash";
import Debit from "./tipos/Debit";
import Credit from "./tipos/Credit";
// import OnlineCredit from "./tipos/OnlineCredit";
import {
  agregaPago,
  cambiaTipo,
  getResto,
  montoInvalido,
} from "../../hooks/Pago/hooks";

import { useAuth } from "../../utils/AuthContext";
import { PagoContext } from "../../hooks/PagoContext";

type PaymentProps = {
  payment: any;
  setPayment: any;
};

const Payment: React.FC<PaymentProps> = ({ payment, setPayment }) => {
  const [tipos, setTipos] = useState<any>([]);
  // lo uso para chequear que no sea invalido el monto recibido en efectivo
  const [recibidoValido, setRecibidoValido] = useState(false);
  // lo uso para chequear que no haya campos requeridos faltantes
  const [faltantes, setFaltantes] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  const { totalPrice, pagosRealizados, setPagosRealizados } =
    useContext(PagoContext);

  const { logout } = useAuth();

  // pido los metodos de pago
  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await getPayment(logout);
        setTipos(response);
        setIsLoading(false);
      } catch (error) {
        setTipos([]); 
        console.error("Error en la solicitud:", error);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // faltaPagar arranca con el total ---> a medida que ingresan pagos se va actualizando
  // monto es el monto del pago actual ---> arranca con el total
  useEffect(() => {
    setPayment((prevPayment: any) => {
      return {
        ...prevPayment,
        faltaPagar: getResto(pagosRealizados, totalPrice),
        monto: getResto(pagosRealizados, totalPrice),
        quierePagar: getResto(pagosRealizados, totalPrice),
        method_data: tipos?.find((e: any) => e.method === "cash"),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, tipos, pagosRealizados.length]);

  return (
    <div className="max-width-4 mx-auto clearfix">
      <div className="btrz-card mb3 p4 sm-p5 bg-white box-shadow-black-10">
        <h3 className="sm-fs4 fs5 sm-mb4 mb3 uppercase color-accent">
          Información de pagos
        </h3>
        <div className="clearfix sm-mxn4 remove-mb4-last-child">
          <div className="col-12 sm-col sm-col-4 sm-px4 mb4">
            <div className="btrz-select-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Método de pago
              </label>
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <div className="absolute flex justify-end pointer-events-none height100 width100">
                  <div className="mr3">
                    <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
                      <i className="fa fa-chevron-down "></i>
                    </span>
                  </div>
                </div>
                <select
                  id="_gt2y0o4ew"
                  name="selectPaymentMethod"
                  className="ff-max-height font-family-site rounded border-none col-12 sm-fs3 fs4 py3 px3 bg-transparent color-grey-darkest pr4"
                  data-focusonload="true"
                  onChange={(e) =>
                    cambiaTipo(
                      e.target.value,
                      pagosRealizados,
                      totalPrice,
                      tipos,
                      setPayment,
                      setRecibidoValido,
                      setFaltantes
                    )
                  }
                  value={payment.method}
                >
                  {tipos?.map((e: any) => {
                    return (
                      <option key={e._id} value={e.method}>
                        {e.displayName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 sm-col sm-col-4 sm-px4 mb4">
            <div className="btrz-input-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Monto a pagar
              </label>
              <div
                tabIndex={0}
                className="right mb2 pointer sm-fs4 fs6 color-accent"
              >
                <i className="fa fa-pencil mr1"></i>Editar
              </div>
              <div
                className={`rounded border mb1px relative border ${
                  montoInvalido(payment) || faltantes.includes("monto")
                    ? " border-danger"
                    : "border-grey-lightest border-accent-hover"
                }`}
              >
                <div className="absolute flex justify-start pointer-events-none height100 width100">
                  <div className="ml3">
                    <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
                      <i className="fa fa-usd "></i>
                    </span>
                  </div>
                </div>
                <input
                  id="_n3rfg62l4"
                  name="amountToPay"
                  type="number"
                  value={payment.monto ? payment.monto : 0}
                  step="0.01"
                  onChange={(e) =>
                    setPayment((prevPayment: any) => {
                      return {
                        ...prevPayment,
                        monto: parseFloat(e.target.value),
                        quierePagar: parseFloat(e.target.value),
                      };
                    })
                  }
                  autoComplete="on"
                  className={`font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  text-indent`}
                />
              </div>
              {(montoInvalido(payment) || faltantes.includes("monto")) && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Importe
                    inválido
                  </span>
                </div>
              )}
            </div>
          </div>

          {payment.method === "cash" && (
            <Cash
              payment={payment}
              setPayment={setPayment}
              setRecibidoValido={setRecibidoValido}
              faltantes={faltantes}
            />
          )}
          {payment.method === "debit" && (
            <Debit
              payment={payment}
              setPayment={setPayment}
              faltantes={faltantes}
            />
          )}
        </div>
        {payment.method === "credit" && (
          <Credit
            payment={payment}
            setPayment={setPayment}
            faltantes={faltantes}
            tipos={tipos?.find((e: any) => e.method === "credit").creditCards}
          />
        )}
        <div className="border-top border-grey-lighter"></div>
        <div className="clearfix sm-mxn4 remove-mb4-last-child sm-flex items-end">
          <div className="col-12 sm-col sm-col-4 sm-px4 mb4">
            <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest invisible">
              Action
            </label>
            <button
              tabIndex={0}
              type="button"
              onClick={() =>
                agregaPago(
                  pagosRealizados,
                  payment,
                  totalPrice,
                  tipos,
                  setPayment,
                  setRecibidoValido,
                  setFaltantes,
                  setPagosRealizados
                )
              }
              className={`relative font-family-title rounded col-12 color-white border py3 sm-fs3 px4 fs4 ${
                montoInvalido(payment) ||
                recibidoValido ||
                0 === parseFloat(getResto(pagosRealizados, totalPrice)) ||
                isLoading
                  ? "pointer-events-none  border-grey-lightest bg-accent-lighter"
                  : "pointer border-accent box-shadow-black-50 bg-accent bg-black-10-hover"
              }`}
              disabled={
                montoInvalido(payment) ||
                recibidoValido ||
                0 === parseFloat(getResto(pagosRealizados, totalPrice)) ||
                isLoading
              }
            >
              <span>Agregar pago</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
