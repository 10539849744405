import React, { useState } from "react";
import { useAuth } from "../utils/AuthContext";

import { handleChange, handleLogin } from "../hooks/login/hooks";

function LoginPage() {
  const [faltantes, setFaltantes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ user: "", password: "" });
  const [mensaje, setMensaje] = useState(false);

  const { login } = useAuth();

  return (
    <div className="bg-background" id="root">
      <div
        id="loginBox"
        className="bg-background max-width-2 py2 mx-auto border-info"
      >
        <div className="bg-background mt3 sm-mt4 p4 sm-p5 mx4 sm-mx0">
          <div id="indexContainer" className="">
            <div id="logo" className="mb5">
              <img
                src="/images/btrz-new-logo.svg"
                className="col-5"
                alt="logo"
              />
            </div>
            <div>
              <fieldset className="border-none p0 m0">
                <div className="mb4">
                  <label
                    htmlFor="email"
                    className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest"
                  >
                    Correo electrónico
                  </label>
                  <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                    <input
                      id="email"
                      className="outline-grey-lighter-focus font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent"
                      type="email"
                      name="credentials[email]"
                      required={true}
                      onChange={(e) =>
                        handleChange(e.target.value, "user", setUser)
                      }
                    />
                  </div>
                  <div
                    id="btrz-error"
                    className={`btrz-error ${
                      !faltantes.includes("user") && "display-none"
                    } bg-danger text-shadow-black color-white p1 rounded sm-fs4 fs6`}
                  >
                    <i className="fa fa-exclamation-circle mr1"></i>
                    <span id="inner-error">Requerido</span>
                  </div>
                  {mensaje && (
                    <div
                      id="btrz-error"
                      className="btrz-error bg-danger text-shadow-black color-white p1 rounded sm-fs4 fs6"
                    >
                      <i className="fa fa-exclamation-circle mr1"></i>
                      <span id="inner-error">
                        Usuario o contraseña incorrectos
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb5">
                  <label
                    htmlFor="password"
                    className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest"
                  >
                    Contraseña
                  </label>
                  <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                    <input
                      id="password"
                      className="outline-grey-lighter-focus font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent"
                      type="password"
                      name="credentials[password]"
                      required={true}
                      autoComplete="off"
                      onChange={(e) =>
                        handleChange(e.target.value, "password", setUser)
                      }
                    />
                  </div>
                  <div
                    id="btrz-error"
                    className={`btrz-error ${
                      !faltantes.includes("password") && "display-none"
                    } bg-danger text-shadow-black color-white p1 rounded sm-fs4 fs6`}
                  >
                    <i className="fa fa-exclamation-circle mr1"></i>
                    <span id="inner-error">Requerido</span>
                  </div>
                </div>

                <button
                  className={`relative font-family-title rounded col-12 color-white border py3 sm-fs3 px4 fs4 ${
                    isLoading
                      ? "pointer-events-none  border-grey-lightest bg-accent-lighter"
                      : "pointer border-accent box-shadow-black-50 bg-accent bg-black-10-hover"
                  }`}
                  type="submit"
                  onClick={() =>
                    handleLogin(
                      user,
                      setFaltantes,
                      setIsLoading,
                      login,
                      setMensaje
                    )
                  }
                  onKeyDown={(e) =>
                    e.key === "Enter" &&
                    handleLogin(
                      user,
                      setFaltantes,
                      setIsLoading,
                      login,
                      setMensaje
                    )
                  }
                  disabled={isLoading}
                >
                  {isLoading ? "Cargando..." : "Log In"}
                </button>
                {/* <div className="mt4">
                  <a
                    className="outline-grey-lighter-focus font-family-site pointer block center mb4 color-grey-darkest color-accent-hover"
                    href="/accounts/forgot"
                  >
                    ¿Se le olvidó su contraseña?
                  </a>
                </div> */}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
