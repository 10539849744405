import React, { useContext, Dispatch, SetStateAction } from "react";
import { handleCloseGEB } from "../../hooks/Resultados/hooks";
import { InitialContext } from "../../hooks/InitialContext";
import { TimerContext } from "../../hooks/TimerContext";
import { useAuth } from "../../utils/AuthContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type DetalleAsientoProps = {
  pasaje: any;
  mapa: any;
  segmento: any;
  setLoaderColectivo: Dispatch<SetStateAction<boolean>>;
};

const DetalleAsiento: React.FC<DetalleAsientoProps> = ({
  pasaje,
  mapa,
  segmento,
  setLoaderColectivo,
}) => {
  const { reserva } = useContext(InitialContext);
  const { pasajesReservados, updatePasajesReservados, setMensajeReserva } =
    useContext(ResultadosContext);
  const { resetTimer } = useContext(TimerContext);

  const { logout } = useAuth();

  return ( 
    <div>
      <div
        draggable="false"
        className="btrz-pill mb1 relative rounded-max border flex items-center  fs6 sm-fs5 px2 py2 mb1 color-white bg-success border-success inverted-color"
      >
        <span className="px2 flex-auto">
          {mapa.cupo} asientos -{" "}
          {pasaje?.occupation?.findIndex(
            (m: any) => m?.number === pasaje?.number
          ) + 1}{" "}
          - {pasaje?.number}
        </span>
        <span
          tabIndex={0}
          className="right mr2 px1 pointer color-grey-darkest"
          onClick={() =>
            handleCloseGEB(
              pasajesReservados,
              segmento,
              updatePasajesReservados,
              pasaje,
              resetTimer,
              setLoaderColectivo,
              setMensajeReserva,
              reserva.vuelta === null ? "s" : "r",
              logout
            )
          }
        >
          <i className="fa fa-times opacity8"></i>
        </span>
      </div>
    </div>
  );
};

export default DetalleAsiento;
