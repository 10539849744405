import React from "react";

type MethodProps = {
  payment: any;
  setPayment: any;
  faltantes: any;
};

const Debit: React.FC<MethodProps> = ({ payment, setPayment, faltantes }) => {
  return (
    <div className="col-12 sm-col sm-col-4 sm-px4 mb4">
      <div className="btrz-input-form">
        <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
          Autorización #
        </label>
        <div
          className={`rounded border mb1px relative  ${
            faltantes.includes("autorizacion")
              ? " border-danger"
              : "border-grey-lightest border-accent-hover"
          } border`}
        >
          <input
            id="_b05t7bp9b"
            value={payment?.autorizacion ? payment?.autorizacion : ""}
            onChange={(e) =>
              setPayment((prevPayment: any) => {
                return { ...prevPayment, autorizacion: e.target.value };
              })
            }
            name="autorizacion"
            autoComplete="on"
            className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  text-indent"
          />
        </div>
        {faltantes.includes("autorizacion") && (
          <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
            <span>
              <i className="fa fa-exclamation-circle mr1"></i>Código inválido
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Debit;
