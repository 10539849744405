import React, { useState, FC, ReactNode } from "react";
import { IPasajerosContext } from "../types/PasajerosType";

const defaultState = {
  fullRestartPasajeros: () => ({}),
  cartID: {},
  setCartID: () => ({}),
  mensajeCart: false,
  setMensajeCart: () => ({}),
  textMensajeCart: "",
  setTextMensajeCart: () => ({}),
  textAsientos: "",
  setTextAsientos: () => ({}),
};

export const PasajerosContext =
  React.createContext<IPasajerosContext>(defaultState);

interface PasajerosContextProps {
  children: ReactNode;
}

// ---------------------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------- SE ACTUALIZAN EN VISTA DETALLES PASAJEROS ---------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------
export const PasajerosProvider: FC<PasajerosContextProps> = ({ children }) => {
  const [cartID, setCartID] = useState(defaultState.cartID);
  const [mensajeCart, setMensajeCart] = useState(defaultState.mensajeCart);
  const [textMensajeCart, setTextMensajeCart] = useState(
    defaultState.textMensajeCart
  );
  const [textAsientos, setTextAsientos] = useState(defaultState.textAsientos);

  const fullRestartPasajeros = () => {
    setCartID(defaultState.cartID);
    setMensajeCart(defaultState.mensajeCart);
    setTextMensajeCart(defaultState.textMensajeCart);
    setTextAsientos(defaultState.textAsientos);
  };

  return (
    <PasajerosContext.Provider
      value={{
        fullRestartPasajeros,
        cartID,
        setCartID,
        mensajeCart,
        setMensajeCart,
        textMensajeCart,
        setTextMensajeCart,
        textAsientos,
        setTextAsientos,
      }}
    >
      {children}
    </PasajerosContext.Provider>
  );
};
