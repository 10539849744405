import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthContext";

function Header() {
  const [mensajeInicio, setMensajeInicio] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { logout } = useAuth();

  const handleRedirect = () => {
    setMensajeInicio(false);
    navigate("/");
  };

  const handleShowMessage = () => {
    if (location.pathname !== "/") {
      if (location.pathname === "/sales-confirmation") {
        navigate("/");
      } else {
        setMensajeInicio(true);
      }
    }
  };

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/sales-confirmation"
    ) {
      // if (location.pathname === "/sales-confirmation") {
      //   navigate("/");
      // } else {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        const mensaje = "¿Seguro que quieres abandonar la página?";
        event.returnValue = mensaje;
        return mensaje;
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
      // }
    }
  }, [location.pathname]);

  return (
    <>
      {mensajeInicio && (
        <div className="btrz-slideInDown--initial no-content-animation--initial items-center zmax fixed top-0 left-0 right-0 btrz-slideInDown no-content-animation p3 fs6 sm-fs4 box-shadow-black-30 mb3 flex bg-info color-white letter-spacing col-6 msj-medio flex-column">
          <div className="flex mb4">
            <div className="line-height-5 mb1">
              <i className="fa mr3 fa-exclamation-circle"></i>
            </div>
            <div className="flex left-align flex-column">
              <span className="mb1">Atención!</span>
              <span>
                Ud. está volviendo al inicio. Perderá los datos de la reserva
              </span>
            </div>
            <div className="inverted-color">
              <div
                tabIndex={1}
                className="p2 color-grey-lighter-hover pointer opacity8"
                onClick={() => setMensajeInicio(false)}
              >
                <i className="fa fa-times"></i>
              </div>
            </div>
          </div>
          <div className="flex col-12 justify-between">
            <button
              className="relative font-family-title rounded col-5 color-info-darker border border-info-lightest box-shadow-black-50 bg-info-lightest bg-black-10-hover py2 sm-fs4 px2 fs4 pointer"
              onClick={() => handleRedirect()}
            >
              Volver al inicio
            </button>
            <button
              className="relative font-family-title rounded col-5 color-info-darker border border-info-lightest box-shadow-black-50 bg-info-lightest bg-black-10-hover py2 sm-fs4 px2 fs4 pointer"
              onClick={() => setMensajeInicio(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
      <nav className="fixed bg-white top-0 right-0 left-0 box-shadow-black-30 flex justify-between zmax">
        <ul className="list-style-none fs6 flex pl0">
          <li className="outline-grey-lighter-focus outline-offset-n3-focus fs6 flex items-center bg-black-10-hover">
            <div
              data-shortcut-keycode="68"
              title="Dashboard (Alt+D)"
              tabIndex={1}
              onClick={() => handleShowMessage()}
              className="focus-none fs6 px3 pointer text-decoration-none color-grey-darkest"
            >
              <div className="btrz-logo"></div>
            </div>
          </li>
          <li className="outline-grey-lighter-focus outline-offset-n3-focus fs6 flex items-center bg-black-10-hover">
            <div
              className="font-family-title fs5 menu-btn-left p3 text-decoration-none color-grey-darker pointer border-top1 border-white border-accent-hover underline-animation menu-animation "
              tabIndex={1}
              onClick={() => handleShowMessage()}
            >
              <i className="sm-hide md-hide lg-hide fa fa-lg color-grey-dark fs3 fa-ticket"></i>
              <span className="xs-hide p2 inline-block first-underline">
                Ventas
              </span>
            </div>
          </li>
        </ul>
        <div className="outline-grey-lighter-focus outline-offset-n3-focus fs6 flex items-center bg-black-10-hover">
          <div
            className="font-family-title fs5 menu-btn-left p3 text-decoration-none color-grey-darker pointer border-top1 border-white border-accent-hover underline-animation menu-animation "
            tabIndex={1}
            onClick={() => logout()}
          >
            <i className="sm-hide md-hide lg-hide fa fa-lg color-grey-dark fs3 fa-ticket"></i>
            <span className="xs-hide p2 inline-block first-underline">
              Log out
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
