import Cookies from "universal-cookie";

const cookies = new Cookies();

export async function request<TResponse>(
  url: string,
  logout: any
): Promise<TResponse> {
  const configValue: string | undefined = process.env.REACT_APP_URL;

  var myHeaders = new Headers();
  myHeaders.append("auth", cookies.get("accessToken"));

  try {
    const response = await fetch(configValue + url, {
      method: "GET",
      credentials: "include",
      headers: myHeaders,
      redirect: "follow",
    });

    // if (!response.ok) {
    //   if (response.status === 401) {
    //     logout();
    //   }

    //   throw new Error("Error:" + response.status + " " + response.statusText);
    // }
    // const responseData: TResponse = await response.json();

    const responseData: TResponse | any = await response.json();
    if (responseData.errorCode === "401" || responseData.text === "Unauthorized") {
      logout();
    }

    return responseData;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function send<TResponse>(
  url: string,
  data: FormData,
  logout: any
): Promise<TResponse> {
  const configValue: string | undefined = process.env.REACT_APP_URL;

  try {
    var myHeaders = new Headers();
    myHeaders.append("auth", cookies.get("accessToken"));
    myHeaders.append("X-CSRFToken", cookies.get("XCSRFToken"));

    const response = await fetch(configValue + url, {
      method: "PUT",
      credentials: "include",
      redirect: "follow",
      headers: myHeaders,
      body: data,
    });

    // if (!response.ok) {
    //   if (response.status === 401) {
    //     logout();
    //   }

    //   throw new Error("Error:" + response.status + " " + response.statusText);
    // }
    // const responseData: TResponse = await response.json();

    const responseData: TResponse | any = await response.json();
    if (responseData.errorCode === "401" || responseData.text === "Unauthorized") {
      logout();
    }

    return responseData;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function put<TResponse>(
  url: string,
  data: string,
  logout: any
): Promise<TResponse> {
  const configValue: string | undefined = process.env.REACT_APP_URL;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth", cookies.get("accessToken"));
  myHeaders.append("X-CSRFToken", cookies.get("XCSRFToken"));

  try {
    const response = await fetch(configValue + url, {
      method: "POST",
      credentials: "include",
      headers: myHeaders,
      redirect: "follow",
      body: data,
    });

    if (!response.ok) {
      // if (response.status === 401) {
      //   logout();
      // }

      const errorText = await response.text();
      const errorObject = JSON.parse(errorText);
      const returnObject = errorObject.hasOwnProperty("text")
        ? errorObject.text
        : JSON.stringify({
            code: errorObject.errorCode,
            message: errorObject.errorMessage,
          });

      throw new Error(returnObject);
    }

    // const responseData: TResponse = await response.json();

    const responseData: TResponse | any = await response.json();
    if (responseData.errorCode === "401" || responseData.errorCode === "500" || responseData.text === "Unauthorized") {
      logout();
    }

    return responseData;
  } catch (error: any) {
    return Promise.reject(error.message);
  }
}

export async function login<TResponse>(
  url: string,
  data: string
): Promise<TResponse> {
  const configValue: string | undefined = process.env.REACT_APP_URL;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  try {
    const response = await fetch(configValue + url, {
      method: "POST",
      credentials: "include",
      redirect: "follow",
      headers: myHeaders,
      body: data,
    });

    if (!response.ok) {
      throw new Error("Error:" + response.status + " " + response.statusText);
    }

    const responseData: TResponse = await response.json();
    return responseData;
  } catch (error) {
    return Promise.reject(error);
  }
}
