import React from "react";

type ErrorProps = {
  message: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const Error: React.FC<ErrorProps> = ({ message, onClose }) => {
  return (
    <div className="btrz-slideInDown--initial no-content-animation--initial items-center zmax fixed top-0 left-0 right-0 btrz-slideInDown no-content-animation p3 fs6 sm-fs4 box-shadow-black-30 mb3 flex bg-danger color-white letter-spacing">
      <div className="line-height-5 mb1">
        <i className="fa mr3 fa-exclamation-circle"></i>
      </div>
      <div className="flex-auto left-align">{message}</div>
      <div className="inverted-color">
        <div
          tabIndex={0}
          className="p2 color-grey-lighter-hover pointer opacity8"
          onClick={() => onClose(false)}
        >
          <i className="fa fa-times"></i>
        </div>
      </div>
    </div>
  );
};

export default Error;
