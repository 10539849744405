import Cookies from "universal-cookie";

const cookies = new Cookies();

const setAuthCookie = (
  shortToken: string,
  csrfToken: string,
  email: string
) => {
  cookies.set("accessToken", shortToken);
  cookies.set("XCSRFToken", csrfToken);
  cookies.set("mail", email);
};

const removeAuthCookie = () => {
  cookies.remove("accessToken");
  cookies.remove("XCSRFToken");
  cookies.remove("mail");
};

const isLogged = (): boolean => {
  return (
    cookies.get("accessToken") &&
    cookies.get("XCSRFToken") &&
    cookies.get("mail")
  );
};

// handleFallaToken

export { setAuthCookie, removeAuthCookie, isLogged };
