import React, { useState, FC, ReactNode } from "react";
import { IPagoContext } from "../types/PagoType";

const defaultState = {
  totalPrice: 0,
  setTotalPrice: () => ({}),
  pagosRealizados: [],
  setPagosRealizados: () => ({}),
  fullRestartPago: () => ({}),
  mensajeOrder: false,
  setMensajeOrder: () => ({}),
  nroSolicitud: "",
  setNroSolicitud: () => ({}),
  resultadoOrder: "",
  setResultadoOrder: () => ({}),
  textMensaje: "",
  setTextMensaje: () => ({}),
  tickets: "",
  setTickets: () => ({}),
};

export const PagoContext = React.createContext<IPagoContext>(defaultState);

interface PagoContextProps {
  children: ReactNode;
}

// ---------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------- SE ACTUALIZAN EN VISTA PAGO ----------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------
export const PagoProvider: FC<PagoContextProps> = ({ children }) => {
  const [totalPrice, setTotalPrice] = useState(defaultState.totalPrice);
  const [pagosRealizados, setPagosRealizados] = useState<any>(
    defaultState.pagosRealizados
  );
  const [mensajeOrder, setMensajeOrder] = useState(defaultState.mensajeOrder);
  const [nroSolicitud, setNroSolicitud] = useState(defaultState.nroSolicitud);
  const [resultadoOrder, setResultadoOrder] = useState(
    defaultState.resultadoOrder
  );
  const [textMensaje, setTextMensaje] = useState(defaultState.textMensaje);
  const [tickets, setTickets] = useState(defaultState.tickets);

  const fullRestartPago = () => {
    setPagosRealizados(defaultState.pagosRealizados);
  };

  return (
    <PagoContext.Provider
      value={{
        totalPrice,
        setTotalPrice,
        pagosRealizados,
        setPagosRealizados,
        fullRestartPago,
        mensajeOrder,
        setMensajeOrder,
        nroSolicitud,
        setNroSolicitud,
        resultadoOrder,
        setResultadoOrder,
        textMensaje,
        setTextMensaje,
        tickets,
        setTickets,
      }}
    >
      {children}
    </PagoContext.Provider>
  );
};
