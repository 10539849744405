import React, {
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import DetalleAsientoLIM from "./DetalleAsientoLIM";
import CantidadPasajes from "./CantidadPasajes";
import {
  handleExpireLIM,
  handleReservaLIM,
} from "../../hooks/Resultados/hooks";
import { TimerContext } from "../../hooks/TimerContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type AsientosLIMProps = {
  segmento: any;
  cantidadPasajes: number;
  isShowing: boolean;
  segments: number;
  setSegments: Dispatch<SetStateAction<number>>;
  totalSegmentos: number;
};

declare global {
  interface Window {
    seatmapReady: () => void;
    addSeatToSelectionNew: (
      seatLocationObject: any,
      iFrameInformationObject: any
    ) => void;
    unSelectSeat: (seatId: string) => void;
  }
}

export const unSelectSeatLIM = (numero: any) => {
  window.unSelectSeat(numero);
};

const AsientosLIM: React.FC<AsientosLIMProps> = ({
  segmento,
  cantidadPasajes,
  isShowing,
  segments,
  setSegments,
  totalSegmentos,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { updatePasajesReservados, pasajesReservados } =
    useContext(ResultadosContext);
  const { muestraExpira, setMuestraExpira } = useContext(TimerContext);

  // PARAMETROS DE URL
  const isBackoffice = false;
  const legFromIndex = "0"; // index in the schedule for the departing station

  // const ttlSec = ""; // Optional. The expiration time in seconds of a selected seat

  window.addSeatToSelectionNew = function (
    seatLocationObject,
    iFrameInformationObject
  ) {
    // you can use document.getElementById if you add an id to the iframe
    const seatmap = document.getElementsByTagName("iframe")[0].contentWindow;
    // save the seatLocationObject and iFrameInformationObject in the client context
    if (seatmap) {
      const postMessage = () => {
        seatmap.postMessage(
          {
            eventName: "selectedSeatFromOtherDomain",
            seatId: seatLocationObject.seatId,
          },
          "*"
        );
      };

      handleReservaLIM(
        seatLocationObject,
        pasajesReservados,
        segmento,
        updatePasajesReservados,
        postMessage
      );
    }
  };

  window.unSelectSeat = function (seatId: string) {
    const seatmap = document.getElementsByTagName("iframe")[0].contentWindow; // you can use document.getElementById if you add an id to the iframe
    if (seatmap) {
      seatmap.postMessage(
        { eventName: "unSelectSeatFromOtherDomain", seatId },
        "*"
      );
      // your code to handle this event in your own environment
    }
  };

  window.seatmapReady = function () {
    // do something to interact with the seatmap or nothing
    // This function can be a no-op but it needs to be here
  };

  window.addEventListener(
    "message",
    function (event) {
      if (event.data.eventName === "addSeatToSelectionNew") {
        const { seatLocationObject, iFrameInformationObject } = event.data;

        window.addSeatToSelectionNew(
          seatLocationObject,
          iFrameInformationObject
        );
      }

      if (event.data.eventName === "seatmapReady") {
        setIsLoading(false);
        window.seatmapReady();
      }

      if (event.data.eventName === "expiredSeats") {
        event.data.expired.map((e: any, i: number) => {
          const deselecciona = () => {
            try {
              handleExpireLIM(
                pasajesReservados,
                segmento,
                updatePasajesReservados,
                e,
                unSelectSeatLIM,
                muestraExpira,
                setMuestraExpira
              );
            } finally {
              unSelectSeatLIM(e.seat_id);
            }
          };

          return deselecciona();
        });
      }

      if (event.data.eventName === "unSelectSeat") {
        const { seatLocationObject } = event.data;

        window.unSelectSeat(seatLocationObject.seatId);
      }
    },
    false
  );

  useEffect(() => {
    setIsLoading(true);
  }, [segments]);

  // todo:usar el mismo timer para desreservar con otras vistas (handleCancel)

  // ## la desreserva de asientos LIM al cambiar de tarifa o itinerario seleccionada se realiza en InitialContext

  return (
    <>
      {segmento !== undefined && (
        <>
          {isShowing && (
            <p style={{ visibility: `${isLoading ? "visible" : "hidden"}` }}>
              Cargando...
            </p>
          )}
          {isShowing && (
            <>
              <div
                style={{ visibility: `${!isLoading ? "visible" : "hidden"}` }}
              >
                <CantidadPasajes
                  cantidadPasajes={cantidadPasajes}
                  isShowing={isShowing}
                  segments={segments}
                  setSegments={setSegments}
                  segmento={segmento}
                  totalSegmentos={totalSegmentos}
                />
              </div>
              <div>
                <div className="relative" style={{ minHeight: "450px" }}>
                  {segmento.seatMapId !== undefined &&
                    segmento.route_id !== undefined &&
                    segmento.schedule_id !== undefined &&
                    segmento.departure_date !== undefined &&
                    segmento.origin_id !== undefined &&
                    segmento.destination_id !== undefined &&
                    segmento.provider_id !== undefined && (
                      <iframe
                        title="Seatmap limousines"
                        data-autofocus=""
                        id="seatmap-iframe"
                        src={`${process.env.REACT_APP_DOMAINMAP}/operations/selectable-seatmaps/${segmento.seatMapId}?routeId=${segmento.route_id}&scheduleId=${segmento.schedule_id}&isBackoffice=${isBackoffice}&date=${segmento.departure_date}&legFrom=${legFromIndex}&legTo=${segmento.stops.length}&originId=${segmento.origin_id}&destinationId=${segmento.destination_id}&providerId=${segmento.provider_id}&x-api-key=${process.env.REACT_APP_XAPIKEY}&currency=MXN`}
                        data-schedule-id={segmento.schedule_id}
                        frameBorder={0}
                        className="col-12 img-map"
                        style={{
                          minHeight: "450px",
                          minWidth: "200px",
                          visibility: `${!isLoading ? "visible" : "hidden"}`,
                        }}
                      ></iframe>
                    )}
                </div>
                <div>
                  {pasajesReservados
                    ?.find((e: any) => e.display_name === segmento.display_name)
                    ?.pasajes?.filter((e: any) => {
                      return e.numero !== "";
                    })
                    .map((e: any, i: number) => {
                      return (
                        <div key={i}>
                          <DetalleAsientoLIM pasaje={e} segmento={segmento} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AsientosLIM;
