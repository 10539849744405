import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import HeaderResultados from "../../../components/resultados/HeaderResultados";
import Asientos from "../../../components/resultados/Asientos";
import Resultados from "../../../components/resultados/Resultados";
import { InitialContext } from "../../../hooks/InitialContext";
import { areResultsValid } from "../../../hooks/Resultados/hooks";
import { ResultadosContext } from "../../../hooks/ResultadosContext";
import Error from "../../../components/common/Error";
import { PasajerosContext } from "../../../hooks/PasajerosContext";

const SelectTrip: React.FC = () => {
  const [cantidadPasajes, setCantidadPasajes] = useState(0);
  const [puedePasar, setPuedePasar] = useState(false);
  const [showError, setShowError] = useState(false);
  // QUE SEGMENTO ESTAMOS MOSTRANDO
  const [segments, setSegments] = useState(1);

  const navigate = useNavigate();

  const { resultados, reserva } = useContext(InitialContext);

  const {
    pasajesReservados,
    idaVuelta,
    setIdaVuelta,
    updateTarifaSeleccionada,
  } = useContext(ResultadosContext);

  const { textAsientos } = useContext(PasajerosContext);

  const ANTERIOR = "/";
  const SIGUIENTE = "/detalles";

  // CON F5 REDIRECCIONO AL INICIO
  useEffect(() => {
    if (reserva.origen.name.length === 0 || !areResultsValid(resultados)) {
      navigate(ANTERIOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.origen.name, resultados]);

  const handleRedirect = () => {
    if (puedePasar) {
      if (
        idaVuelta === "departures" &&
        reserva.vuelta &&
        areResultsValid(resultados)
      ) {
        let temp: any = resultados["returns" as keyof typeof resultados];

        if (temp) {
          updateTarifaSeleccionada(
            {
              ...temp?.[0],
              fare_classes: temp?.[0]?.fare_classes?.[0],
            },
            "returns"
          );

          setSegments(1);
        }

        setIdaVuelta("returns");
      } else {
        window.scrollTo(0, 0);
        navigate(SIGUIENTE);
      }
    }
  };

  useEffect(() => {
    let temp = pasajesReservados?.map((e: any) => {
      return e.pasajes;
    });
    const arrayUnificado = [];

    for (const innerArray of temp) {
      for (const objeto of innerArray) {
        arrayUnificado.push(objeto);
      }
    }

    let flag = arrayUnificado.some((e) => e.numero === "");

    setPuedePasar(!flag);
  }, [pasajesReservados, cantidadPasajes]);

  useEffect(() => {
    textAsientos !== "" ? setShowError(true) : setShowError(false);
  }, [textAsientos]);

  return (
    <div className="clearfix">
      {showError && <Error message={textAsientos} onClose={setShowError} />}
      <div>
        <div className="col col-12 sm-col-9 pr2 mb5">
          <div className="btrz-page-header clearfix flex items-center pt2 pb2 sm-pt3 sm-pb3">
            <Breadcrumb title="Seleccione un viaje" />
            <div id="content"></div>
          </div>
          <HeaderResultados
            resultado={resultados[idaVuelta as keyof typeof resultados]?.[0]}
          />
          <Resultados
            resultados={resultados[idaVuelta as keyof typeof resultados]}
            setSegments={setSegments}
          />
          <div className="mt3 color-grey-darker center fs6"></div>
          <div className="clearfix pb3">
            <div className="col col-6 sm-col-4 md-col-3 pr2">
              <Link to={ANTERIOR}>
                <button
                  tabIndex={0}
                  type="button"
                  className="relative font-family-title rounded col-12 color-grey-darkest border border-grey bg-transparent bg-black-10-hover bg-black-10-focus py3 sm-fs3 px4 fs4 pointer "
                  slot="left"
                >
                  <i className="fa mr2 fa-caret-left left mr3 mln2 sm-ml0 mt05"></i>
                  <span>Anterior</span>
                </button>
              </Link>
            </div>
            <div className="col col-6 sm-col-4 md-col-3 col-right pl2">
              <button
                tabIndex={-1}
                type="button"
                onClick={() => handleRedirect()}
                className={`relative font-family-title rounded col-12 color-white border py3 sm-fs3 px4 fs4 ${
                  puedePasar
                    ? "pointer border-accent box-shadow-black-50 bg-accent bg-black-10-hover"
                    : "pointer-events-none  border-grey-lightest bg-accent-lighter"
                }`}
                slot="right"
              >
                <i className="fa mr2 fa-caret-right right ml3 mrn2 sm-mr0 mt05"></i>
                <span>Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <Asientos
          cantidadPasajes={cantidadPasajes}
          setCantidadPasajes={setCantidadPasajes}
          segments={segments}
          setSegments={setSegments}
        />
      </div>
    </div>
  );
};

export default SelectTrip;
