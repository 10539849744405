import React, { useContext, useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.css";
import es from "date-fns/locale/es";
import { InitialContext } from "../../hooks/InitialContext";

interface CalendarProps {
  title: string;
  type: string;
  subtitle?: string;
}

registerLocale("es", es);

const Calendar: React.FC<CalendarProps> = ({ title, type }) => {
  const [focused, setFocused] = useState(false);
  // SI ES LA FECHA DE VUELTA DEBE SER IGUAL O POSTERIOR A LA IDA
  const [minDate, setMinDate] = useState(new Date());

  const { updateReserva, reserva, faltantes, updateFaltantes } =
    useContext(InitialContext);

  // SI LA SALIDA ES POSTERIOR A LA VUELTA
  // SE ELIMINA LA VUELTA (DEBE SELECCIONARSE NUEVAMENTE)
  useEffect(() => {
    if (type === "vuelta") {
      setMinDate(new Date(reserva?.salida));

      if (
        reserva.vuelta !== null &&
        new Date(reserva.vuelta).setHours(0, 0, 0, 0) <
          new Date(reserva.salida).setHours(0, 0, 0, 0)
      ) {
        updateReserva({ vuelta: null });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.salida]);

  const handleChange = (date: Date) => {
    let temp = date ? date.toLocaleDateString("en-ZA") : null;
    updateReserva({ [type]: temp });

    // SI SE ELIMINA LA FECHA
    // SE AGREGA COMO REQUERIDO
    if (temp === null && type === "salida") {
      let temp = [...faltantes];
      temp.push(type);

      updateFaltantes(temp);
    }
  };

  const formatedDate = () => {
    if (type === "salida") {
      return reserva.salida !== null ? new Date(reserva.salida) : null;
    } else {
      return reserva.vuelta !== null ? new Date(reserva.vuelta) : null;
    }
  };

  // CUANDO SE AGREGA UNA SALIDA
  // SE CHEQUEA QUE NO ESTE COMO REQUERIDO
  // SE QUITA DE AHI SI HACE FALTA
  useEffect(() => {
    if (type === "salida") {
      let temp = [...faltantes];

      const ind = faltantes.includes(type)
        ? faltantes.findIndex((e) => e === type)
        : null;

      if (reserva?.salida !== null && ind !== null) {
        temp.splice(ind, 1);
      }

      updateFaltantes(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.salida]);

  return (
    <div>
      <label
        className={`sm-fs4 fs6 medium mb2 inline-block ${
          focused ? "color-accent" : "color-grey-darkest"
        }`}
      >
        {title}
      </label>
      <div className="flex">
        <div className="relative left-align flex-auto">
          <div>
            <div className="btrz-input-form">
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <div className="absolute flex justify-end pointer-events-none height100 width100">
                  <div className="mr3">
                    <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
                      <i className="fa fa-calendar "></i>
                    </span>
                  </div>
                </div>
                <DatePicker
                  minDate={minDate}
                  selected={formatedDate()}
                  onChange={(date: any) => {
                    handleChange(date);
                    setFocused(false);
                  }}
                  className="datepicker-rct font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent pr4 box-shadow-accent-focus"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  locale="es"
                  formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
                  calendarStartDay={0}
                  dateFormat={"EEE MMM dd, yyyy" || null}
                  isClearable={type === "vuelta" ? true : false}
                />
              </div>
              {faltantes.includes(type) && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Seleccione
                    una fecha válida (D mmm dd, yyyy)
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
