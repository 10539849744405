import React from "react";

interface BreadcrumbProps {
  title: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title }) => {
  return (
    <div className="col-6 relative">
      <ul className="list-style-none pl0 fs6 xs-hide">
        <li className="inline">
          <span className="text-decoration-none color-grey color-accent-hover">
            Ventas
          </span>
          <span className="inline color-grey"> / </span>
        </li>
        <li className="inline">
          <span className="text-decoration-none color-grey color-grey-hover">
            Limousines
          </span>
        </li>
      </ul>
      <h2 className="font-family-title fs3 sm-fs1 bold sm-regular color-grey-darkest">
        {title}
      </h2>
    </div>
  );
};

export default Breadcrumb;
