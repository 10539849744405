import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import Reservation from "./pages/reservation";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import SelectTrip from "./pages/reservation/SelectTrip/SelectTrip";
import DetallesPasajero from "./pages/reservation/DetallesPasajero";
import Pago from "./pages/reservation/Pago";
import ConfirmacionPago from "./pages/sales-confirmation";
import { TimerProvider } from "./hooks/TimerContext";
import { useAuth } from "./utils/AuthContext";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { PagoProvider } from "./hooks/PagoContext";
import { PasajerosProvider } from "./hooks/PasajerosContext";
import { ResultadosProvider } from "./hooks/ResultadosContext";
import { InitialProvider } from "./hooks/InitialContext";
import aes from "js-crypto-aes";
import { b642ab, getValueFromResult } from "./hooks/login/hooks";

const urlSearchParams = new URLSearchParams(window.location.search);
const encryptedFromUrl = urlSearchParams.get("token") ?? "";

const data = {
  iv: process.env.REACT_APP_AESKEY as string,
  ciphertext: decodeURIComponent(encryptedFromUrl) as string,
};

const App: React.FC = () => {
  const [tokenFromUrl, setTokenFromUrl] = useState("");
  const [csrfFromUrl, setCsrfFromUrl] = useState("");
  const [mailFromUrl, setMailFromUrl] = useState("");

  const auth = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (encryptedFromUrl.length > 1) {
      // En este caso el iv y la key es igual
      const iv = b642ab(data.iv); // 16 bytes IV in Uint8Array for AES-CBC mode that is exactly same as the one used in encryption
      aes
        .decrypt(b642ab(data.ciphertext), iv, { name: "AES-CBC", iv })
        .then((decrypted) => {
          return new TextDecoder().decode(decrypted); // now you get an Uint8Array of decrypted message
        })
        .then((result) => {
          const token = getValueFromResult(result, "token=");
          const csrfToken = getValueFromResult(result, "csrf_token=");
          const mail = getValueFromResult(result, "user=");

          if (token && csrfToken && mail) {
            setTokenFromUrl(token);
            setCsrfFromUrl(csrfToken);
            setMailFromUrl(mail);
          }
        })
        .catch((error) => {
          setTokenFromUrl("");
          setCsrfFromUrl("");
          setMailFromUrl("");
          console.log("Error: ", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedFromUrl]);

  useEffect(() => {
    if (
      tokenFromUrl.length > 1 &&
      csrfFromUrl.length > 1 &&
      mailFromUrl.length > 1
    ) {
      try {
        auth.login(tokenFromUrl, csrfFromUrl, mailFromUrl);
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromUrl, csrfFromUrl]);

  return (
    <Routes>
      {!auth.isAuthenticated ? (
        <Route path="/" element={<LoginPage />} />
      ) : (
        <Route path="/" element={<Layout />}>
          <Route index element={<Reservation />} />
          <Route path="reservation" element={<SelectTrip />} />
          <Route path="detalles" element={<DetallesPasajero />} />
          <Route path="pago" element={<Pago />} />
          <Route path="sales-confirmation" element={<ConfirmacionPago />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        </Route>
      )}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function Layout() {
  return (
    <div className="pl2 pr2 sm-pl3 sm-pr3 pt5 font-family-site fs5 bg-background">
      <Header />
      <InitialProvider>
        <ResultadosProvider>
          <TimerProvider>
            <PasajerosProvider>
              <PagoProvider>
                <Outlet />
              </PagoProvider>
            </PasajerosProvider>
          </TimerProvider>
        </ResultadosProvider>
      </InitialProvider>
      <Footer />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
