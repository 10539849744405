import { Payment } from "../types/PaymentType";
import { request } from "./apiService";

export async function getPayment(logout: any): Promise<Payment> {
  const types = await request<Payment>("/route/payment-methods/", logout);

  if(types.reason) {
    return [];
  } else {
    return types;
  }
}
