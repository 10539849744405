import React, { useContext } from "react";
import { TimerContext } from "../../../hooks/TimerContext";

const MensajeExpiracion: React.FC = () => {
  const { muestraExpira, setMuestraExpira } = useContext(TimerContext);

  return (
    <>
      {muestraExpira?.map((e: any, i: number) => {
        const data = () => {
          if (e.hasOwnProperty("sectionName")) {
            const numero = Number(e.numero) < 10 ? "0" + e.numero : e.numero;
            return e.sectionName + " - " + e.rowLabel + " - " + numero;
          } else {
            const fila =
              e?.occupation?.findIndex((o: any) => o.number === e.numero) + 1;

            return e.cupo + " asientos - " + fila + " - " + e.numero;
          }
        };

        return (
          <div
            className={`z10 fixed absolute right-1 top-${
              i === 0 ? 1 : i * 6 + 1
            } mt5 col-8 sm-col-6 lg-col-4`}
          >
            <div className="mb2 pointer flex fs6 sm-fs4 box-shadow-black-30 letter-spacing bg-warning color-warning-darkest">
              <div className="flex items-center px3 color-white-hover color-warning-lighter">
                <i className="fa fa-exclamation-circle fa-lg"></i>
              </div>
              <div className="flex-auto p3 border-left border-warning-lighter">
                <div className="fs7 mb1">{data()}</div>
                Selección de asiento expirada
              </div>
              <div
                className="p3 flex items-center color-white-hover color-warning-lighter"
                onClick={() => {
                  let temp = [...muestraExpira];
                  temp.splice(i, 1);

                  setMuestraExpira([...temp]);
                }}
              >
                <i className="fa fa-times"></i>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MensajeExpiracion;
