import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

import { InitialContext } from "../../../hooks/InitialContext";
import Pasajeros from "../../../components/detalles/Pasajeros";

import { handleSolicitud, validateData } from "../../../hooks/Detalles/hooks";
import Loader from "../../../components/common/Loader";
import { useAuth } from "../../../utils/AuthContext";
import { PasajerosContext } from "../../../hooks/PasajerosContext";
import { ResultadosContext } from "../../../hooks/ResultadosContext";
import Error from "../../../components/common/Error";
import { TimerContext } from "../../../hooks/TimerContext";

const DetallesPasajero: React.FC = () => {
  const [faltantes, setFaltantes] = useState<{
    campos: string[];
    email: string[];
  }>({ campos: [], email: [] });
  const [isLoading, setIsLoading] = useState(false);

  const { reserva } = useContext(InitialContext);

  const { pasajesReservados, updatePasajesReservados, tarifaSeleccionada, setIdaVuelta } =
    useContext(ResultadosContext);

  const {
    setCartID,
    setMensajeCart,
    mensajeCart,
    textMensajeCart,
    setTextMensajeCart,
    setTextAsientos,
  } = useContext(PasajerosContext);

  const  { resetTimer } = useContext(TimerContext);

  const navigate = useNavigate();

  const { logout } = useAuth();

  const cookies = new Cookies();

  useEffect(() => {
    if (reserva.origen.name.length === 0 || pasajesReservados === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.origen.name, pasajesReservados]);

  useEffect(() => {
    setIsLoading(false);
    setMensajeCart(false);
    setTextMensajeCart("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mail = cookies.get("mail");

  return (
    <Loader isLoading={isLoading}>
      {mensajeCart && (
        <Error
          message={`
        Lo sentimos, no se pudo generar el carrito. Inténtelo nuevamente. 
        ${handleSolicitud(tarifaSeleccionada)}
        ${textMensajeCart}
        `}
          onClose={setMensajeCart}
        />
      )}
      <form>
        <div className="max-width-4 mx-auto">
          <div className="btrz-page-header clearfix flex items-center pt2 pb2 sm-pt3 sm-pb3">
            <Breadcrumb title="Detalles del pasajero" />
            <div id="content"></div>
          </div>
          {pasajesReservados[0]?.pasajes?.map((e: any, ind: number) => {
            return (
              <div key={ind}>
                <div className="bg-white mb1px py3 px3 sm-px3 fs6 color-grey-darkest box-shadow-black-10">
                  <span className="letter-spacing uppercase bold mr3">
                    <i className="fa fa-c() => voidircle-o mr3 color-accent"></i>
                    {e.name}
                  </span>
                </div>
                <Pasajeros
                  seg={ind}
                  faltantes={faltantes}
                  cantidad={pasajesReservados[0]?.pasajes?.length}
                />
              </div>
            );
          })}
          <div className="clearfix mb3 sm-pt4 sm-pb4 pt3 pb3 ">
            <div className="col col-6 sm-col-4 pr2">
              <button
                tabIndex={1}
                type="button"
                onClick={() => navigate(-1)}
                className="relative font-family-title rounded col-12 color-grey-darkest border border-grey bg-transparent bg-black-10-hover bg-black-10-focus py3 sm-fs3 px4 fs4 pointer "
              >
                <i className="fa mr2 fa-caret-left left mr3 mln2 sm-ml0 mt05"></i>
                <span>Anterior</span>
              </button>
            </div>
            <div className="col col-6 sm-col-4 col-right pl2">
              <button
                tabIndex={-1}
                disabled={isLoading || textMensajeCart !== ""}
                type="button"
                onClick={() =>
                  validateData(
                    mail,
                    pasajesReservados,
                    setFaltantes,
                    updatePasajesReservados,
                    navigate,
                    setIsLoading,
                    setCartID,
                    setMensajeCart,
                    setTextMensajeCart,
                    setTextAsientos,
                    setIdaVuelta,
                    resetTimer,
                    logout
                  )
                }
                slot="right"
                className={`relative font-family-title rounded col-12 color-white border py3 sm-fs3 px4 fs4 ${
                  isLoading || textMensajeCart !== ""
                    ? "pointer-events-none  border-grey-lightest bg-accent-lighter"
                    : "pointer border-accent box-shadow-black-50 bg-accent bg-black-10-hover"
                }`}
              >
                <i className="fa mr2 fa-caret-right right ml3 mrn2 sm-mr0 mt05"></i>
                <span>{isLoading ? "Cargando..." : "Completar la compra"}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </Loader>
  );
};

export default DetallesPasajero;
