import React, { useContext, useEffect, useState } from "react";
import { getFormatDate } from "../../hooks/commonFunctions";
import Pasajero from "./Pasajero";

import { ResultadosContext } from "../../hooks/ResultadosContext";

type ViajeProps = {
  title: string;
  origen: string;
  destino: string;
  flag: string;
};

const Viaje: React.FC<ViajeProps> = ({ title, origen, destino, flag }) => {
  const [tramos, setTramos] = useState<any>();

  const { pasajesReservados } = useContext(ResultadosContext);

  useEffect(() => {
    const temp = [...pasajesReservados].filter((e: any) => e.etapa === flag);
    setTramos(temp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasajesReservados]);

  return (
    <>
      {tramos !== undefined && (
        <div>
          <div className="flex justify-between flex-wrap p2 mt3">
            <div className="uppercase mr2 color-info fs6 medium">{title}</div>
          </div>
          <div>
            <div className="p2">
              <div className="medium color-grey-darkest fs5 sm-fs4">
                {origen} Hasta {destino}
                <span className="thin ml2">
                  {getFormatDate(tramos?.[0]?.departure_date, "-")}
                </span>{" "}
                {tramos?.map((e: any, i: number) => {
                  return (
                    <div key={i}>
                      <span>
                        <span>{e.stops[0].origin_name}</span>
                        <span className="color-grey thin ml1 xs-hide">
                          ({e.stops[0].departure_time})
                        </span>
                      </span>
                      {e.stops.map((s: any, ind: number) => {
                        return (
                          <span key={ind}>
                            <span
                              className={
                                i === tramos.length - 1 &&
                                e.stops.length - 1 === ind
                                  ? ""
                                  : "color-grey thin ml1 xs-hide"
                              }
                            >
                              {" "}
                              {s.destination_name}
                            </span>
                            <span className="color-grey thin ml1 xs-hide">
                              ({s.arrival_time})
                            </span>
                          </span>
                        );
                      })}
                      <br />
                      <br />
                      <span className="p2 fs5 sm-fs4 color-grey thin ml1 xs-hide">
                        <i>Los horarios son en tiempo local</i>
                      </span>
                      {e.pasajes?.map((p: any) => {
                        return <Pasajero data={p} segmento={e} />;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Viaje;
