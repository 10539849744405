import React from "react";

type OcupadoType = {
  numero: string;
};

const Ocupado: React.FC<OcupadoType> = ({ numero }) => {
  return (
    <div
      className="seatmap-section-row-seat pointer-events-none"
      style={{
        display: "inline-table",
        width: "16px",
        margin: "1px 0.5px",
        border: "solid 1px #D83A3A",
        backgroundColor: "white",
        color: "#D83A3A",
      }}
    >
      <span className="seat blocked taken">{numero}</span>

      <div className="edit-seat"></div>
    </div>
  );
};

export default Ocupado;
