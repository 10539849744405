import { putCancel } from "../../services/CancelService";
import { putOrder } from "../../services/OrderService";
import { unSelectSeatLIM } from "../../components/resultados/AsientosLIM";
import { updateReservaConPasajes } from "../Resultados/hooks";

const getTotales = (ida: any, vuelta: any, tarifaSeleccionada: any) => {
  let temp: any = {
    iva: 0,
    subtotal: 0,
    total: 0,
  };

  ida.pasajes.map((e: any) => {
    const tarifa_tipo = tarifaSeleccionada.departures.fare_classes.fares.find(
      (f: any) => parseInt(f.id) === parseInt(e.tipo)
    );

    temp.iva += Number(tarifa_tipo.iva_mxn);
    temp.subtotal += Number(tarifa_tipo.subtotal_mxn);
    temp.total += Number(tarifa_tipo.geb_price_mxn);

    return temp;
  });

  if (vuelta !== undefined) {
    vuelta.pasajes.map((e: any) => {
      const tarifa_tipo =
        tarifaSeleccionada?.returns?.fare_classes?.fares?.find(
          (f: any) => parseInt(f.id) === parseInt(e.tipo)
        );

      temp.iva += Number(tarifa_tipo?.iva_mxn);
      temp.subtotal += Number(tarifa_tipo?.subtotal_mxn);
      temp.total += Number(tarifa_tipo?.geb_price_mxn);

      return temp;
    });
  }

  temp.iva = temp.iva.toFixed(2);
  temp.subtotal = temp.subtotal.toFixed(2);
  temp.total = temp.total.toFixed(2);

  return temp;
};

export const handleReserva = async (
  resetTimer: any,
  navigate: any,
  cliente: any,
  pasajesReservados: any,
  tarifaSeleccionada: any,
  pagosRealizados: any,
  cartID: any,
  setMensajeOrder: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: any,
  setNroSolicitud: React.Dispatch<React.SetStateAction<string>>,
  logout: any,
  reserva: any,
  setResultadoOrder: React.Dispatch<React.SetStateAction<string>>,
  setTickets: React.Dispatch<React.SetStateAction<string>>
) => {
  setIsLoading(true);

  // !! EB
  // finaliza con exito EB, vuelve a 0 el timer
  resetTimer();
  // !! LIM
  // ver que onda

  const temp = [...pasajesReservados].filter((e: any) => e.company === "GEB");
  const ida = temp.find((e) => e.etapa === "departures");
  const vuelta = temp.find((e) => e.etapa === "returns");

  const totales = getTotales(ida, vuelta, tarifaSeleccionada);

  let raw: any = {
    limousinesOrder: {
      cartId: cartID.cartId,
      firstName: cliente.firstName,
      lastName: cliente.lastName,
      email: cliente.email,
      phone: cliente.phone,
      total: cartID.total,
      displayTotal: cartID.displayTotal,
      payments: pagosRealizados.map((e: any) => {
        if (e.method === "debit") {
          return {
            method: e.method,
            amount: e.monto,
            authorization: e.autorizacion,
          };
        } else if (e.method === "credit") {
          return {
            method: e.method,
            amount: e.monto,
            cardType: e.cardType,
            authorization: e.autorizacion_credit,
          };
        } else {
          return {
            method: e.method,
            amount: e.monto,
          };
        }
      }),
      origin: reserva.origen.name,
      destination: reserva.destino.name,
    },
    GEBOrder: {
      passenger: {
        firstName: cliente.firstName,
        lastName: cliente.lastName,
        email: cliente.email,
        phone: cliente.phone,
      },
      total_payment: {
        iva: totales.iva, // Iva total del viaje de GEB
        subtotal: totales.subtotal, // Subtotal del viaje de GEB
        total: totales.total, // Total del viaje de GEB
      },
      departureTrip: {
        departureRoom: ida.departure_room,
        departurePlatform: ida.departure_platform,
        company: ida.brand,
        origin: ida.origin_id,
        destination: ida.destination_id,
        departureDate: ida.departure_date.replaceAll("-", "/"),
        departureTime: ida.departure_time,
        arrivalDate: ida.arrival_date.replaceAll("-", "/"),
        arrivalTime: ida.arrival_time,
        duration: tarifaSeleccionada.departures.geb_duration,
        tripId: ida.trip_id,
        service: ida.service,
        area: ida.area,
        availableSeats: ida.cupo,
        controlOffice: ida.control_office,
        validUntil: ida.valid_until,
        serviceId: ida.service_id,
        operatingCompany: ida.operating_company,
        commercialName: ida.commercial_name,
        passengers: ida.pasajes.map((e: any) => {
          const tarifa_tipo =
            tarifaSeleccionada.departures.fare_classes.fares.find(
              (f: any) => parseInt(f.id) === parseInt(e.tipo)
            );

          return {
            seat: e.numero,
            name: e.firstName + " " + e.lastName,
            seatType: e.tipo,
            subtotal: tarifa_tipo.subtotal_mxn,
            iva: tarifa_tipo.iva_mxn,
            price: tarifa_tipo.geb_price_mxn,
            usdPrice: tarifa_tipo.geb_price_usd,
          };
        }),
        requestId: ida.request_id,
      },
    },
  };

  if (vuelta !== undefined) {
    raw.GEBOrder = {
      ...raw.GEBOrder,
      returnTrip: {
        departureRoom: vuelta.departure_room,
        departurePlatform: vuelta.departure_platform,
        company: vuelta.brand,
        origin: vuelta.origin_id,
        destination: vuelta.destination_id,
        departureDate: vuelta.departure_date.replaceAll("-", "/"),
        departureTime: vuelta.departure_time,
        arrivalDate: vuelta.arrival_date.replaceAll("-", "/"),
        arrivalTime: vuelta.arrival_time,
        duration: tarifaSeleccionada?.returns?.geb_duration,
        tripId: vuelta.trip_id,
        service: vuelta.service,
        area: vuelta.area,
        availableSeats: vuelta.cupo,
        controlOffice: vuelta.control_office,
        validUntil: vuelta.valid_until,
        serviceId: vuelta.service_id,
        operatingCompany: vuelta.operating_company,
        commercialName: vuelta.commercial_name,
        passengers: vuelta.pasajes.map((e: any) => {
          const tarifa_tipo =
            tarifaSeleccionada?.returns?.fare_classes?.fares?.find(
              (f: any) => parseInt(f.id) === parseInt(e.tipo)
            );

          return {
            seat: e.numero,
            name: e.firstName + " " + e.lastName,
            seatType: e.tipo,
            subtotal: tarifa_tipo?.subtotal_mxn,
            iva: tarifa_tipo?.iva_mxn,
            price: tarifa_tipo?.geb_price_mxn,
            usdPrice: tarifa_tipo?.geb_price_usd,
          };
        }),
        requestId: vuelta.request_id,
      },
    };
  }

  try {
    const result = await putOrder(JSON.stringify(raw), logout);

    setResultadoOrder(result.lim.order.transactionNumber);
    setTickets(result.tickets);
    window.open(process.env.REACT_APP_URL + "/" + result.tickets, "_blank");

    console.log("Orden creada: ", result);
    setMensajeOrder(false);
    window.scrollTo(0, 0);
    navigate("/sales-confirmation");
  } catch (error) {
    setNroSolicitud(ida.request_id);
    console.error("Error en la solicitud:", error);
    setMensajeOrder(true);
    navigate("/");
  }

  setIsLoading(false);
};

export const validateData = (
  cliente: any,
  resetTimer: any,
  setFaltantes: any,
  navigate: any,
  pagosRealizados: any,
  totalPrice: number,
  setShow: any,
  pasajesReservados: any,
  tarifaSeleccionada: any,
  cartID: any,
  setMensajeOrder: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setNroSolicitud: React.Dispatch<React.SetStateAction<string>>,
  logout: any,
  reserva: any,
  setResultadoOrder: React.Dispatch<React.SetStateAction<string>>,
  setTickets: React.Dispatch<React.SetStateAction<string>>
) => {
  let falta = [];
  let temp = { ...cliente };
  const keys = ["firstName", "lastName", "email", "phone", "checked"];

  for (let i = 0; i < keys.length; i++) {
    if (!temp.hasOwnProperty(keys[i]) || temp?.[keys[i]] === "") {
      falta.push(keys[i]);
    }
  }

  setFaltantes(falta);

  const total = parseFloat(getResto(pagosRealizados, totalPrice));

  if (falta.length === 0) {
    if (total === 0) {
      handleReserva(
        resetTimer,
        navigate,
        cliente,
        pasajesReservados,
        tarifaSeleccionada,
        pagosRealizados,
        cartID,
        setMensajeOrder,
        setIsLoading,
        setNroSolicitud,
        logout,
        reserva,
        setResultadoOrder,
        setTickets
      );
    } else {
      setShow(true);
    }
  }
};

export const getResto = (pagosRealizados: any, totalPrice: number) => {
  const acumulador = [...pagosRealizados]
    ?.map((e: any) => e.monto)
    ?.reduce((acc, current) => acc + Number(current), 0);

  return (totalPrice - acumulador).toFixed(2);
};

// cuando cambia el metodo vacio los campos extra
// seteo monto con el faltante
export const cambiaTipo = (
  value: any,
  pagosRealizados: any,
  totalPrice: number,
  tipos: any,
  setPayment: any,
  setRecibidoValido: any,
  setFaltantes: any
) => {
  let temp: any = {
    method: value,
    monto: getResto(pagosRealizados, totalPrice),
    quierePagar: getResto(pagosRealizados, totalPrice),
    faltaPagar: getResto(pagosRealizados, totalPrice),
    method_data: tipos?.find((e: any) => e.method === value),
  };

  if (value === "credit") {
    const creditTipos = tipos?.find(
      (e: any) => e.method === "credit"
    ).creditCards;
    const trueKeys = [];
    for (const key in creditTipos) {
      if (creditTipos[key] === true) {
        trueKeys.push(key);
      }
    }

    temp = { ...temp, cardType: trueKeys[0] };
  }

  setPayment(temp);
  setRecibidoValido(false);
  setFaltantes([]);
};

export const montoInvalido = (payment: any) => {
  return payment.monto > payment.faltaPagar || payment.monto < 0;
};

export const validoObligatorios = (payment: any, setFaltantes: any) => {
  let temp = [];

  if (payment.monto === 0) {
    temp.push("monto");
  }

  if (payment.method === "debit") {
    if (payment.autorizacion === undefined || payment.autorizacion === "") {
      temp.push("autorizacion");
    }
  } else if (payment.method === "credit") {
    if (
      payment.autorizacion_credit === undefined ||
      payment.autorizacion_credit === ""
    ) {
      temp.push("autorizacion-credit");
    }
  } else if (payment.method === "cash") {
    if (payment.quierePagar === 0) {
      temp.push("quierePagar");
    }
  }

  setFaltantes(temp);

  return temp;
};

export const agregaPago = (
  pagosRealizados: any,
  payment: any,
  totalPrice: number,
  tipos: any,
  setPayment: any,
  setRecibidoValido: any,
  setFaltantes: any,
  setPagosRealizados: any
) => {
  const vacios = validoObligatorios(payment, setFaltantes);

  if (vacios.length === 0) {
    try {
      let temp = [...pagosRealizados, payment];

      let tempPay: any = {
        method: "cash",
        monto: getResto(temp, totalPrice),
        quierePagar: getResto(temp, totalPrice),
        faltaPagar: getResto(temp, totalPrice),
        method_data: tipos?.find((e: any) => e.method === "cash"),
      };

      setPayment(tempPay);
      setRecibidoValido(false);
      setFaltantes([]);
    } finally {
      setPagosRealizados((prevPagosRealizados: any) => [
        ...prevPagosRealizados,
        payment,
      ]);
    }
  }
};

// se desreservan los pasajes seleccionados
export const desreservaAsientos = (
  temp: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setMensaje: any,
  resetTimer: any,
  dataReserve: any,
  logout: any
) => {
  // recorro los pasajes y los desreservo
  for (let i = 0; i < temp.length; i++) {
    for (let n = 0; n < temp[i].pasajes.length; n++) {
      if (temp[i].pasajes[n].numero !== "") {
        // desreserva
        if (temp[i].company === "GEB") {
          const exito = () => {
            resetTimer();
          };

          updateReservaConPasajes(
            temp[i].pasajes[n].numero,
            false,
            temp[i].pasajes[n].tipo,
            temp[i],
            setIsLoading,
            setMensaje,
            { isReturn: temp[i].etapa === "returns", tripType: dataReserve },
            logout,
            exito
          );
        } else {
          // !! aca falla
          unSelectSeatLIM(temp[i].pasajes[n].seatId);
        }
      }
    }
  }
};

// cancela la compra
// se desreservan los pasajes
export const handleCancel = async (
  cartID: any,
  pasajesReservados: any,
  setIsLoading: any,
  setMensaje: any,
  resetTimer: any,
  logout: any,
  setTextMensaje: React.Dispatch<React.SetStateAction<string>>,
  setNroSolicitud: React.Dispatch<React.SetStateAction<string>>,
  navigate?: any
) => {
  const temp = [...pasajesReservados].filter((e: any) => e.company === "GEB");

  const ida = temp.find((e) => e.etapa === "departures");

  let raw: any = {
    limousinesCancel: {
      cartId: cartID.cartId,
      operationId: cartID.items.items[0].operationId,
    },
    GEBCancel: {
      seats: temp.flatMap((segmento: any) => {
        return segmento.pasajes.map((pasaje: any) => {
          return {
            seat: pasaje.numero,
            origin: segmento.origin_id,
            destination: segmento.destination_id,
            departureDate: segmento.departure_date.replaceAll("-", "/"),
            departureTime: segmento.departure_time,
            tripId: segmento.trip_id,
            seatType: pasaje.tipo,
            requestId: segmento.request_id,
            reserve: "false",
            isReturn: (segmento.etapa === "returns").toString(),
            tripType: temp.length === 1 ? "s" : "r",
          };
        });
      }),
    },
  };

  try {
    const result = await putCancel(JSON.stringify(raw), logout);
    console.log("Se canceló la orden: ", result);
    resetTimer();
    setMensaje(false);
    setTextMensaje("");
  } catch (error) {
    setMensaje(true);
    setNroSolicitud(ida.request_id);
    setTextMensaje("Lo sentimos, no pudo cancelarse la orden.");
    console.log("Error inesperado: ", error);
  } finally {
    setIsLoading(false);
    navigate("/");
  }
};
