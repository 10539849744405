import React, { useEffect, useState } from "react";

type PasajeroProps = {
  data: any;
  segmento: any;
};

const Pasajero: React.FC<PasajeroProps> = ({ data, segmento }) => {
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState();
  const [descripcion, setDescripcion] = useState("");

  useEffect(() => {
    if (segmento.company === "GEB") {
      const fila =
        data.occupation?.findIndex((o: any) => o.number === data.numero) + 1;

      setDescripcion(
        data.cupo + " asientos, Fila " + fila + ", Asiento " + data.numero
      );
    } else {
      const numero =
        Number(data.seatNumber) < 10 ? "0" + data.seatNumber : data.seatNumber;

      setDescripcion("Fila " + data.rowLabel + ", Asiento " + numero);
    }
  }, [data, segmento]);

  useEffect(() => {
    const tipo = segmento?.tarifas?.find(
      (e: any) => parseInt(e.id) === parseInt(data.tipo)
    );

    if (tipo) {
      setPrice(
        segmento.company === "LIM" ? tipo.price_lim : tipo.geb_price_usd
      );
    }
  }, [segmento.tarifas, segmento.company, data.tipo]);

  return (
    <div>
      <div>
        <div tabIndex={0} className="outline-grey-lighter-focus">
          <div
            tabIndex={-1}
            className="flex p2 border border-transparent pointer border-accent-hover"
          >
            <div className="flex-auto color-grey fs5 sm-fs4">
              {data.firstName} {data.lastName}
              <span className="thin ml1 xs-hide">
                ({segmento.fare_classes.name} - {data.name})
              </span>
              <div className="sm-inline border border-grey-lighter rounded-max px3 py1 color-grey ml1 fs7 sm-fs6">
                {descripcion}
              </div>
            </div>
            <div
              className="pl2 fs5"
              onClick={() => setShow((prevShow) => !prevShow)}
            >
              <div className="color-info pointer">
                <i
                  className={`fa ${show ? "fa-caret-up" : "fa-caret-down"}`}
                ></i>
              </div>
            </div>
          </div>
        </div>
        {show && (
          <div className="pr3 pl3 mx2 border-left1 border-info mb3 no-content-animation--initial btrz-slideInDown no-content-animation">
            <div className="pt2 border-top border-grey-lightest flex mb1 sm-mb2 sm-mb2">
              <div className="flex-auto fs6 sm-fs5 pl1 color-grey">
                {segmento.fare_classes.name} - {data.name} (Exento de impuestos)
              </div>
              <div className="color-grey-darkest fs6 sm-fs5">
                <span className="fs7 sm-fs6 mr1">$</span>
                {price}
              </div>
            </div>
            <div className="pt2 border-top border-grey-lightest flex mb0">
              <div className="flex-auto fs6 sm-fs5 pl1 uppercase color-info">
                Subtotal
              </div>
              <div className="color-info fs5 sm-fs4 bold">
                <span className="fs7 sm-fs6 mr1">$</span>
                {price}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pasajero;
