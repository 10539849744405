import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getFormatDate } from "../../hooks/commonFunctions";
import Viaje from "./Viaje";
import { getResto } from "../../hooks/Pago/hooks";

import { InitialContext } from "../../hooks/InitialContext";
import { PagoContext } from "../../hooks/PagoContext";
import { PasajerosContext } from "../../hooks/PasajerosContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

const Detalles: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const { reserva } = useContext(InitialContext);

  const { pasajesReservados } = useContext(ResultadosContext);

  const { cartID } = useContext(PasajerosContext);

  const { pagosRealizados, setPagosRealizados, totalPrice, setTotalPrice } =
    useContext(PagoContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (pasajesReservados === undefined || pasajesReservados.length === 0) {
      navigate("/");
    } else {
      setTotalPrice(cartID?.displayTotal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartID, pasajesReservados]);

  const eliminaPago = (indice: number) => {
    let temp = [...pagosRealizados];
    temp.splice(indice, 1);
    setPagosRealizados(temp);
  };

  return (
    <div className="shopping-cart pb4">
      <div className="box-shadow-black-10 bg-white">
        <table className="col-12 border-none font-family-site sm-fs3 fs4 bg-transparent">
          <tbody>
            <tr className="border-bottom border-grey-lighter">
              <td className="center xs-hide p3">
                <div className="xs-hide fs2 xs-border-none rounded border-grey-lighter color-grey-lighter pointer flex justify-center items-center p2">
                  <i className="fa fa-lg fa-ticket"></i>
                </div>
              </td>
              <td className="col-12 sm-fs3 fs4 p2 sm-p0 pointer color-grey-darkest color-accent-hover">
                <div className="flex items-center mb2 pb2 border-bottom border-none mbn2 mr2 border-grey-lighter">
                  <div className="flex-auto">
                    <div className="regular truncate fs6 color-grey">
                      {getFormatDate(reserva.salida, "/")}
                      <div
                        title="El Paso Hasta Delicias  (Ida y vuelta) x 2"
                        className="sm-fs3 fs4 medium color-grey-darkest truncate"
                        style={{ maxWidth: "34rem" }}
                      >
                        <span className="truncate inline-block">
                          {reserva.origen.name} Hasta {reserva.destino.name}
                        </span>
                        <span className="thin ml2 absolute">
                          {reserva.vuelta !== null && "(Ida y vuelta) "}
                          {"x " + pasajesReservados?.[0]?.pasajes?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button className="p0 bg-transparent border-none rounded outline-grey-lighter-focus color-accent-focus sm-fs5 fs6 pointer color-grey">
                    <i
                      tabIndex={-1}
                      onClick={() => setShow((prevShow) => !prevShow)}
                      className={`fa fa-lg p2 ${
                        show ? "fa-caret-up" : "fa-caret-down"
                      }`}
                    ></i>
                  </button>
                </div>
              </td>
              <td className="right-align p2 white-stripes border-left border-grey-lighter relative"></td>
              <td className="pt2 pr2 pb2">
                <div className="fs4 sm-fs3 py2 mtn1 center">
                  <div
                    tabIndex={0}
                    title="Borrar"
                    className="fs6 inline-block color-accent-hover color-accent-focus outline-grey-lighter-focus color-grey-lighter pointer"
                    onClick={() => navigate("/")}
                  >
                    <i tabIndex={-1} className="fa fa-times"></i>
                  </div>
                </div>
              </td>
            </tr>
            {show && (
              <tr className="border-bottom border-grey-lighter">
                <td className="center xs-hide truncate sm-fs3 fs4 sm-pl3"></td>
                <td className="col-12 sm-fs3 fs4 sm-px1 px2">
                  <div className="operation-details">
                    <Viaje
                      title="Salida"
                      origen={reserva.origen.name}
                      destino={reserva.destino.name}
                      flag="departures"
                    />
                    {reserva.vuelta !== null && (
                      <Viaje
                        title="Viaje de regreso"
                        origen={reserva.destino.name}
                        destino={reserva.origen.name}
                        flag="returns"
                      />
                    )}
                    <div className="flex p2 mb2 mt3">
                      <div className="flex-auto color-info medium uppercase fs6">
                        Subtotal
                      </div>
                      <div className="color-info fs5 sm-fs4 medium ml2">
                        <span className="fs7 sm-fs6 mr1">$</span>
                        {totalPrice}
                      </div>
                      <div className="invisible pl2 fs5 color-grey-lighter pointer color-accent0-hover">
                        <div>
                          <i className="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="relative white-stripes border-left border-grey-lighter right-align p2 align-bottom">
                  <div className="pl2 sm-pl3">
                    <div className="medium fs4 sm-fs3 py2">
                      <span className="fs7 sm-fs6 mr1">$</span>
                      {totalPrice}
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
          <tbody>
            <tr className="border-bottom border-grey-lighter">
              <td className="center xs-hide p3">
                <div className="xs-hide fs2 xs-border-none rounded border-grey-lighter color-grey-lighter pointer flex justify-center items-center p2"></div>
              </td>
              <td className="col-12 sm-fs3 fs4 p2 sm-p0">
                <div className="flex items-center">
                  <div className="flex-auto p2 my3">
                    <div className="color-info right-align medium fs4 sm-fs3">
                      <div>Total a pagar:</div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="right-align p2">
                <div className="pl2 sm-pl3">
                  <div className="medium fs4 sm-fs3 py2 color-info">
                    <span className="fs7 sm-fs6 mr1">$</span>
                    {totalPrice}
                  </div>
                </div>
              </td>
              <td className="right-align p2">
                <div className="px2 sm-px3">
                  <div className="medium fs4 sm-fs3 py2"></div>
                </div>
              </td>
            </tr>
            {pagosRealizados?.length > 0 && (
              <>
                {pagosRealizados.map((e: any, i: number) => {
                  return (
                    <tr className="border-bottom border-grey-lighter" key={i}>
                      <td className="center xs-hide p3">
                        <div className="xs-hide fs2 xs-border-none rounded border-grey-lighter color-grey-lighter pointer flex justify-center items-center p2"></div>
                      </td>
                      <td className="col-12 sm-fs3 fs4 p2 sm-p0">
                        <div className="flex items-center">
                          <div className="flex-auto p2 my3">
                            <div className="color-grey right-align medium fs4 sm-fs3">
                              <div>{e?.method_data?.displayName}:</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="right-align p2">
                        <div className="pl2 sm-pl3">
                          <div className="medium fs4 sm-fs3 py2 color-grey flex items-end">
                            <span className="fs7 sm-fs6 mr1 mb1">-</span>
                            <span className="fs7 sm-fs6 mr1 mb1">$</span>
                            {e.monto}
                          </div>
                        </div>
                      </td>
                      <td className="pt2 pr2 pb2">
                        <div className="fs4 sm-fs3 py2 mtn1 center">
                          <div
                            tabIndex={0}
                            className="fs6 inline-block color-accent-hover color-grey-lighter pointer"
                            onClick={() => eliminaPago(i)}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr className="border-bottom border-grey-lighter">
                  <td className="center xs-hide p3">
                    <div className="xs-hide fs2 xs-border-none rounded border-grey-lighter color-grey-lighter pointer flex justify-center items-center p2"></div>
                  </td>
                  <td className="col-12 sm-fs3 fs4 p2 sm-p0">
                    <div className="flex items-center">
                      <div className="flex-auto p2 my3">
                        <div className="color-danger right-align medium fs4 sm-fs3">
                          <div>Balance a pagar:</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="right-align p2">
                    <div className="pl2 sm-pl3">
                      <div className="medium fs4 sm-fs3 py2 color-danger">
                        <span className="fs7 sm-fs6 mr1">$</span>
                        {getResto(pagosRealizados, totalPrice)}
                      </div>
                    </div>
                  </td>
                  <td className="right-align p2">
                    <div className="px2 sm-px3">
                      <div className="medium fs4 sm-fs3 py2"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Detalles;
