import React, { useState, FC, ReactNode, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ITimerContext } from "../types/TimerType";
import { handleExpireGEB } from "./Resultados/hooks";
import { InitialContext } from "./InitialContext";
import { getTickets } from "./Reservation/hooks";
import { useAuth } from "../utils/AuthContext";
import { ResultadosContext } from "./ResultadosContext";

const defaultState = {
  timerActive: false,
  setTimerActive: () => ({}),
  setTimeElapsed: () => ({}),
  timeElapsed: 0,
  resetTimer: () => ({}),
  muestraExpira: [],
  setMuestraExpira: () => ({}),
};

export const TimerContext = React.createContext<ITimerContext>(defaultState);

interface TimerContextProps {
  children: ReactNode;
}

export const TimerProvider: FC<TimerContextProps> = ({ children }) => {
  const [timerActive, setTimerActive] = useState(defaultState.timerActive);
  const [timeElapsed, setTimeElapsed] = useState<number>(
    defaultState.timeElapsed
  );
  const [muestraExpira, setMuestraExpira] = useState(
    defaultState.muestraExpira
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const { logout } = useAuth();

  const { reserva } = useContext(InitialContext);

  const { pasajesReservados, updatePasajesReservados, setMensajeReserva } =
    useContext(ResultadosContext);

  useEffect(() => {
    let countdown: NodeJS.Timeout;

    if (timerActive) {
      countdown = setInterval(() => {
        setTimeElapsed((prevTimeElapsed) => prevTimeElapsed + 1);
      }, 1000);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [timerActive]);

  const resetTimer = () => {
    setTimerActive(defaultState.timerActive);
    setTimeElapsed(defaultState.timeElapsed);
  };

  const handleRedirect = async () => {
    // !! actualizar cuando haya token
    if (location.pathname !== "/reservation") {
      let tickets = await getTickets(reserva);

      navigate(
        `/reservation/?origin=${reserva.origen.id}&originCompany=${
          reserva.origen.company
        }&destination=${reserva.destino.id}&destinationCompany=${
          reserva.destino.company
        }&departureDate=${reserva.salida}&returnDate=${
          reserva.vuelta !== null ? reserva.vuelta : ""
        }&ticketTypes=${tickets}`
      );
    }
  };

  // EXPIRA
  useEffect(() => {
    if (timeElapsed === 540) {
      try {
        if (timerActive) {
          handleExpireGEB(
            pasajesReservados,
            updatePasajesReservados,
            setIsLoading,
            setMensajeReserva,
            reserva.vuelta === null ? "s" : "r",
            muestraExpira,
            setMuestraExpira,
            logout
          );
        }
      } finally {
        resetTimer();
        handleRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeElapsed]);

  return (
    <TimerContext.Provider
      value={{
        timerActive,
        setTimerActive,
        timeElapsed,
        setTimeElapsed,
        resetTimer,
        muestraExpira,
        setMuestraExpira,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
