import React, { useContext, useState, useEffect } from "react";
import { InitialContext } from "../../hooks/InitialContext";

interface SelectProps {
  title: string;
  option: string;
  disabled: boolean;
  options: Array<any>;
  type: "origen" | "destino";
}

const Select: React.FC<SelectProps> = ({
  title,
  option,
  disabled,
  options,
  type,
}) => {
  const [focused, setFocused] = useState(false);

  const { reserva, updateReserva, faltantes, updateFaltantes } =
    useContext(InitialContext);

  const handleChange = (e: any) => {
    const selected = JSON.parse(e.target.value);
    updateReserva({ [type]: selected });

    // SI EL CAMPO QUEDA VACÍO
    // SE AGREGA A LOS FALTANTES
    if (selected.id.length === 0) {
      let temp = [...faltantes];
      temp.push(type);

      updateFaltantes(temp);
    }
  };

  // EL CAMPO ESTABA REQUERIDO E INCOMPLETO
  // SE DESMARCA SI SE COMPLETA
  useEffect(() => {
    let temp = [...faltantes];

    const ind = faltantes.includes(type)
      ? faltantes.findIndex((e) => e === type)
      : null;

    if (reserva?.[type].id.length > 0 && ind !== null) {
      temp.splice(ind, 1);
    }

    updateFaltantes(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva?.[type]]);

  return (
    <div className="btrz-select-form">
      <label
        className={`medium mb2 inline-block sm-fs4 fs6 ${
          focused ? "color-accent" : "color-grey-darkest"
        }`}
      >
        {title}
      </label>
      <div
        className={`rounded border mb1px relative border
        ${disabled ? "pointer-events-none" : "border-accent-hover"} 
        ${
          faltantes.includes(type) ? " border-danger" : "border-grey-lightest"
        }`}
      >
        <div className="absolute flex justify-end pointer-events-none height100 width100">
          <div className="mr3">
            <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
              <i className="fa fa-chevron-down "></i>
            </span>
          </div>
        </div>
        <select
          // SI NO SELECCIONÓ ORIGEN NO PUEDE SELECCIONAR DESTINO
          disabled={disabled}
          name="input"
          className={`ff-max-height font-family-site rounded border-none col-12 sm-fs3 fs4 py3 px3 pr4 box-shadow-accent-focus   
            ${
              disabled
                ? "bg-grey-lightest"
                : "bg-transparent color-grey-darkest"
            }
          `}
          data-focusonload="true"
          // MARCAR EL LABEL CUANDO ESTA FOCUS EL SELECT
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => handleChange(e)}
          value={
            title === "Desde"
              ? JSON.stringify(reserva?.origen)
              : JSON.stringify(reserva?.destino)
          }
        >
          <option key="initial" value={`{"name":"","id":"","company":""}`}>
            {option}
          </option>
          {options?.map((e) => {
            return (
              <option key={e.id} value={JSON.stringify(e)}>
                {e.name}
              </option>
            );
          })}
        </select>
      </div>
      {/* MOSTRAR ERROR SI INTENTA GUARDAR Y NO SELECCIONÓ DATOS */}
      {faltantes.includes(type) && (
        <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded sm-fs4 fs6">
          <span>
            <i className="fa fa-exclamation-circle mr1"></i>Requerido
          </span>
        </div>
      )}
    </div>
  );
};

export default Select;
