import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Detalles from "../../../components/pago/Detalles";
import Cliente from "../../../components/pago/Cliente";
import {
  getResto,
  handleCancel,
  validateData,
} from "../../../hooks/Pago/hooks";
import Payment from "../../../components/pago/Payment";
import { useAuth } from "../../../utils/AuthContext";
import Loader from "../../../components/common/Loader";
import Error from "../../../components/common/Error";
import { handleSolicitud } from "../../../hooks/Detalles/hooks";

import { TimerContext } from "../../../hooks/TimerContext";
import { InitialContext } from "../../../hooks/InitialContext";
import { PagoContext } from "../../../hooks/PagoContext";
import { PasajerosContext } from "../../../hooks/PasajerosContext";
import { ResultadosContext } from "../../../hooks/ResultadosContext";

const Pago: React.FC = () => {
  const [faltantes, setFaltantes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [payment, setPayment] = useState<any>({
    method: "cash",
  });

  const { cliente, reserva } = useContext(InitialContext);

  const { pasajesReservados, tarifaSeleccionada } =
    useContext(ResultadosContext);

  const { cartID } = useContext(PasajerosContext);

  const {
    totalPrice,
    pagosRealizados,
    mensajeOrder,
    setMensajeOrder,
    setNroSolicitud,
    setResultadoOrder,
    setTextMensaje,
    setTickets,
  } = useContext(PagoContext);

  const { resetTimer } = useContext(TimerContext);

  const navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    if (
      reserva.origen.name.length === 0 ||
      pasajesReservados === undefined ||
      pasajesReservados?.[0]?.origin_id === ""
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.origen.name, pasajesReservados]);

  useEffect(() => {
    setMensajeOrder(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <div id="shopping-cart-data">
        {mensajeOrder && (
          <Error
            message={`Lo sentimos, no se pudo generar el pago. Inténtelo nuevamente. ${handleSolicitud(
              tarifaSeleccionada
            )}`}
            onClose={setMensajeOrder}
          />
        )}
        {show && (
          <Error
            message="La cantidad pagada debe ser igual al saldo restante"
            onClose={setShow}
          />
        )}
        <div className="max-width-4 mx-auto clearfix">
          <div className="btrz-page-header clearfix flex items-center pt2 pb2 sm-pt3 sm-pb3">
            <Breadcrumb title="Carrito de compra" />
            <div id="content"></div>
          </div>
          <Detalles />
          <div className="mb4"></div>
        </div>
        <form>
          {parseFloat(getResto(pagosRealizados, totalPrice)) > 0 && (
            <Payment payment={payment} setPayment={setPayment} />
          )}
          <Cliente faltantes={faltantes} />
          <div className="flex items-center fixed top-0 right-0 bottom-0 left-0 zmax hide">
            <div className="absolute z10 p4 sm-p5 right-0 left-0 box-shadow-black-10 bg-white overflow-auto m4 sm-m5 top-0 bottom-0">
              <h3 className="sm-fs4 fs5 uppercase mb4 sm-mb5 color-accent">
                Términos y condiciones
              </h3>
              <div className="absolute right-0 top-0 m4 sm-m5 color-grey color-accent-hover pointer">
                <i className="fa fa-times fa-lg mtn2"></i>
              </div>
              <div>Términos y condiciones para ABC MX Sandbox.</div>
            </div>
            <div className="absolute top-0 right-0 bottom-0 left-0 bg-grey opacity8"></div>
          </div>
          <div className="max-width-4 mx-auto clearfix">
            <div className="clearfix mb3 sm-pt4 sm-pb4 pt3 pb3">
              <div className="sm-col-3 col-6 xs-col inline-block mr2 xs-mr0">
                <button
                  // type="submit"
                  type="button"
                  disabled={isLoading}
                  className={`relative font-family-title rounded col-12 color-white border py3 sm-fs3 px4 fs4 ${
                    isLoading
                      ? "pointer-events-none  border-grey-lightest bg-accent-lighter"
                      : "pointer border-accent box-shadow-black-50 bg-accent bg-black-10-hover"
                  }`}
                  onClick={() => {
                    validateData(
                      cliente,
                      resetTimer,
                      setFaltantes,
                      navigate,
                      pagosRealizados,
                      totalPrice,
                      setShow,
                      pasajesReservados,
                      tarifaSeleccionada,
                      cartID,
                      setMensajeOrder,
                      setIsLoading,
                      setNroSolicitud,
                      logout,
                      reserva,
                      setResultadoOrder,
                      setTickets
                    );
                  }}
                >
                  <i className="fa mr2 fa-caret-right right ml3 mrn2 sm-mr0 mt05"></i>
                  <span>{isLoading ? "Cargando..." : "Pago"}</span>
                </button>
              </div>
              <div className="inline-block xs-col-6 xs-col">
                <button
                  tabIndex={0}
                  disabled={isLoading}
                  onClick={() =>
                    handleCancel(
                      cartID,
                      pasajesReservados,
                      setIsLoading,
                      setMensajeOrder,
                      resetTimer,
                      logout,
                      setTextMensaje,
                      setNroSolicitud,
                      navigate
                    )
                  }
                  type="button"
                  className={`relative font-family-title rounded col-12 border-none py3 sm-fs3 px4 fs4 ${
                    isLoading
                      ? "pointer-events-none  border-grey-lightest color-white"
                      : "pointer border-accent color-grey-darkest bg-transparent bg-black-10-hover bg-black-10-focus"
                  }`}
                  slot="left"
                >
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Loader>
  );
};

export default Pago;
