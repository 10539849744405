import { putCart } from "../../services/CartService";
import { handleLIMTaken } from "../Resultados/hooks";

export function validarCampos(array: any) {
  const objetosConCamposFaltantes = [];
  const objetosConEmailInvalido = [];

  for (let i = 0; i < array.length; i++) {
    const elemento = array[i];
    const camposFaltantes = [];

    if (elemento.firstName === "" || elemento.firstName === undefined) {
      camposFaltantes.push("firstName");
    }
    if (elemento.lastName === "" || elemento.lastName === undefined) {
      camposFaltantes.push("lastName");
    }
    if (elemento.email === "" || elemento.email === undefined) {
      camposFaltantes.push("email");
    } else {
      // Verificamos el formato del campo email
      const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegExp.test(elemento.email)) {
        objetosConEmailInvalido.push(i);
      }
    }

    if (camposFaltantes.length > 0) {
      objetosConCamposFaltantes.push({ indice: i, camposFaltantes });
    }
  }

  return { objetosConCamposFaltantes, objetosConEmailInvalido };
}

// esta replica el primer segmento a los otros
const replicaPasajeros = (
  pasajesReservados: any,
  updatePasajesReservados: any
) => {
  let temp = [...pasajesReservados];
  if (pasajesReservados.length > 1) {
    temp = temp.map((e: any, i: number) => {
      if (i !== 0) {
        return {
          ...e,
          pasajes: e.pasajes.map((p: any, ind: number) => {
            let primero = temp[0].pasajes[ind];
            return {
              ...p,
              firstName: primero.firstName,
              lastName: primero.lastName,
              email: primero.email,
            };
          }),
        };
      } else {
        return e;
      }
    });
    updatePasajesReservados(temp);
  }
  return temp;
};

// PRIMERO REPLICO LOS PASAJEROS Y LUEGO CREO LA CONSULTA
export const handleRedirect = async (
  mail: string,
  pasajesReservados: any,
  updatePasajesReservados: any,
  navigate: any,
  setIsLoading: any,
  setCartID: React.Dispatch<React.SetStateAction<Object>>,
  setMensajeCart: React.Dispatch<React.SetStateAction<boolean>>,
  setTextMensajeCart: React.Dispatch<React.SetStateAction<string>>,
  setTextAsientos: React.Dispatch<React.SetStateAction<string>>,
  setIdaVuelta: React.Dispatch<React.SetStateAction<string>>,  
  resetTimer: () => void,
  logout: any
) => {
  setIsLoading(true);

  // PRIMERO REPLICO LOS PASAJEROS DEL PRIMER SEGMENTO A LOS DEMAS
  const segmentosPasajes = replicaPasajeros(
    pasajesReservados,
    updatePasajesReservados
  );

  const tramoGEBida = [...segmentosPasajes].find(
    (e) => e.company === "GEB" && e.etapa === "departures"
  );
  const tramoGEBvuelta = [...segmentosPasajes].find(
    (e) => e.company === "GEB" && e.etapa === "returns"
  );
  const tramosLIMida: any = [...segmentosPasajes].filter(
    (e: any) => e.company === "LIM" && e.etapa === "departures"
  );
  const tramosLIMvuelta: any = [...segmentosPasajes].filter(
    (e: any) => e.company === "LIM" && e.etapa === "returns"
  );

  let raw: any = {
    GEBCart: {
      departureTrip: {
        requestId: tramoGEBida?.request_id,
        tripId: tramoGEBida?.trip_id,
        departureDate: tramoGEBida?.departure_date.replaceAll("-", "/"),
        fromId: tramoGEBida?.origin_id,
        toId: tramoGEBida?.destination_id,
        passengers: tramoGEBida?.pasajes.map((e: any) => {
          return {
            seat: e.numero,
            name: e.firstName + " " + e.lastName,
            seatType: e.tipo,
            price: tramoGEBida?.fare_classes.fares.find(
              (p: any) => parseInt(p.id) === parseInt(e.tipo)
            )?.geb_price_mxn,
          };
        }),
      },
    },
  };
  // SI HAY VUELTA GEB
  if (tramoGEBvuelta) {
    raw.GEBCart = {
      ...raw.GEBCart,
      returnTrip: {
        requestId: tramoGEBvuelta?.request_id,
        tripId: tramoGEBvuelta?.trip_id,
        departureDate: tramoGEBvuelta?.departure_date.replaceAll("-", "/"),
        fromId: tramoGEBvuelta?.origin_id,
        toId: tramoGEBvuelta?.destination_id,
        passengers: tramoGEBvuelta?.pasajes.map((e: any) => {
          return {
            seat: e.numero,
            name: e.firstName + " " + e.lastName,
            seatType: e.tipo,
            price: tramoGEBvuelta?.fare_classes?.fares?.find(
              (p: any) => parseInt(p.id) === parseInt(e.tipo)
            )?.geb_price_mxn,
          };
        }),
      },
    };
  }

  let totalIda = { ...tramoGEBida }.pasajes
    .map((e: any) => {
      return tramoGEBida.fare_classes.fares.find(
        (f: any) => parseInt(f.id) === parseInt(e.tipo)
      )?.geb_price_btrz;
    })
    .reduce((acc: number, current: number) => acc + current);

  let totalVuelta = tramoGEBvuelta
    ? { ...tramoGEBvuelta }.pasajes
        .map((e: any) => {
          return tramoGEBvuelta.fare_classes.fares.find(
            (f: any) => parseInt(f.id) === parseInt(e.tipo)
          )?.geb_price_btrz;
        })
        .reduce((acc: number, current: number) => acc + current)
    : 0;

  let totalIdaUsd = { ...tramoGEBida }.pasajes
    .map((e: any) => {
      return tramoGEBida.fare_classes.fares.find(
        (f: any) => parseInt(f.id) === parseInt(e.tipo)
      )?.geb_price_usd;
    })
    .reduce((acc: number, current: number) => acc + current);

  let totalVueltaUsd = tramoGEBvuelta
    ? { ...tramoGEBvuelta }.pasajes
        .map((e: any) => {
          return tramoGEBvuelta.fare_classes.fares.find(
            (f: any) => parseInt(f.id) === parseInt(e.tipo)
          )?.geb_price_usd;
        })
        .reduce((acc: number, current: number) => acc + current)
    : 0;

  if (tramosLIMida?.length > 0) {
    raw = {
      ...raw,
      limousinesCart: {
        reservation: {
          fromId: tramosLIMida[0].origin_id,
          toId: tramosLIMida[tramosLIMida.length - 1].destination_id,
          selectedTrips: {
            departureTripId: tramosLIMida[0].trip_id,
          },
          dateFrom: tramosLIMida[0].departure_date.replaceAll("-", "/"),
          passengers: tramosLIMida[0]?.pasajes.map((e: any, i: number) => {
            return {
              firstName: e.firstName,
              lastName: e.lastName,
              fare: e.tipo,
              fareClassIds: {
                departureClass: tramosLIMida[0].fare_classes.id,
              },
              // por cada pasajero van los seats de cada tramo
              seats: tramosLIMida.map((tr: any) => {
                return {
                  seatMapId: tr.seatMapId,
                  sectionId: tr.pasajes[i].sectionId,
                  rowLabel: tr.pasajes[i].rowLabel,
                  seatNumber: tr.pasajes[i].seatNumber,
                  seatId: tr.pasajes[i].seatId,
                  scheduleId: tr.schedule_id,
                  tripId: tr.trip_id,
                };
              }),
            };
          }),
        },
        gebPrice: totalIda + totalVuelta,
        gebPriceUsd: totalIdaUsd + totalVueltaUsd,
        user: mail,
      },
    };
  } else {
    raw = {
      ...raw,
      limousinesCart: {
        gebPrice: totalIda + totalVuelta,
        gebPriceUsd: totalIdaUsd + totalVueltaUsd,
        user: mail,
      },
    };
  }

  // SI HAY VUELTA LIM
  if (tramosLIMvuelta?.length > 0) {
    raw.limousinesCart.reservation = {
      ...raw.limousinesCart.reservation,
      selectedTrips: {
        ...raw.limousinesCart.reservation.selectedTrips,
        returnTripId: tramosLIMvuelta[0].trip_id,
      },
      dateTo: tramosLIMvuelta[0].departure_date.replaceAll("-", "/"),
      passengers: raw.limousinesCart.reservation.passengers.map(
        (e: any, i: number) => {
          return {
            ...e,
            fareClassIds: {
              ...e.fareClassIds,
              returnClass: tramosLIMvuelta[0].fare_classes.id,
            },
            seats: [
              ...e.seats,
              ...tramosLIMvuelta?.map((tr: any) => {
                return {
                  seatMapId: tr.seatMapId,
                  sectionId: tr.pasajes[i].sectionId,
                  rowLabel: tr.pasajes[i].rowLabel,
                  seatNumber: tr.pasajes[i].seatNumber,
                  seatId: tr.pasajes[i].seatId,
                  scheduleId: tr.schedule_id,
                  tripId: tr.trip_id,
                };
              }),
            ],
          };
        }
      ),
    };
  }

  try {
    const result = await putCart(JSON.stringify(raw), logout);
    setCartID(result.limCart);
    setMensajeCart(false);
    window.scrollTo(0, 0);
    setTextAsientos("");
    navigate("/pago");
  } catch (error: any) {
    setMensajeCart(true);

    const textError = JSON.parse(error);

    if (textError.code === "NOSHIFT") {
      setTextMensajeCart(
        "Por favor cree un turno antes de realizar una compra."
      );
    } else if (textError.code === "SEAT_TAKEN") {
      handleLIMTaken(pasajesReservados, updatePasajesReservados, resetTimer);

      setTextMensajeCart("El asiento seleccionado ya fue reservado.");
      setTextAsientos(
        "Hubo un error. Ud. seleccionó asientos que estaban ocupados, inténtelo nuevamente."
      );

      setIdaVuelta("departures");
      navigate(-1);
    } else if (textError.code === "400" || textError.code === "500") {
      setTextMensajeCart(textError.message);
    } else {
      setTextMensajeCart("");
    }
  }

  setIsLoading(false);
};

// VALIDO QUE TENGA LOS CAMPOS REQUERIDOS Y QUE EL EMAIL SEA VALIDO
export const validateData = (
  mail: string,
  pasajesReservados: any,
  setFaltantes: any,
  updatePasajesReservados: any,
  navigate: any,
  setIsLoading: any,
  setCartID: React.Dispatch<React.SetStateAction<any>>,
  setMensajeCart: React.Dispatch<React.SetStateAction<boolean>>,
  setTextMensajeCart: React.Dispatch<React.SetStateAction<string>>,
  setTextAsientos: React.Dispatch<React.SetStateAction<string>>,
  setIdaVuelta: React.Dispatch<React.SetStateAction<string>>,
  resetTimer: () => void,
  logout: any
) => {
  const temp = [...pasajesReservados];
  // primero valido que no falten datos
  const { objetosConCamposFaltantes, objetosConEmailInvalido } = validarCampos(
    temp[0].pasajes
  );

  setFaltantes({
    campos: [...objetosConCamposFaltantes],
    email: [...objetosConEmailInvalido],
  });

  if (
    objetosConCamposFaltantes.length === 0 &&
    objetosConEmailInvalido.length === 0
  ) {
    handleRedirect(
      mail,
      pasajesReservados,
      updatePasajesReservados,
      navigate,
      setIsLoading,
      setCartID,
      setMensajeCart,
      setTextMensajeCart,
      setTextAsientos,
      setIdaVuelta,
      resetTimer,
      logout
    );
  }
};

// SE UTILIZA PARA COPIAR A LOS OTROS PASAJEROS EL NOMBRE DEL PRIMERO
export const copyData = (
  pasajesReservados: any,
  updatePasajesReservados: any
) => {
  let arr = [...pasajesReservados];

  const updatedArr = arr.map((e: any, i: number) => {
    if (i === 0) {
      const firstPasaje = e.pasajes[0];

      const updatedPasajes = e.pasajes.map((p: any) => ({
        ...p,
        firstName: firstPasaje.firstName,
        lastName: firstPasaje.lastName,
        email: firstPasaje.email,
      }));

      return {
        ...e,
        pasajes: updatedPasajes,
      };
    } else {
      return e;
    }
  });

  updatePasajesReservados(updatedArr);
};

export const handleSolicitud = (tarifaSeleccionada: any) => {
  const obj = tarifaSeleccionada[
    "departures" as keyof typeof tarifaSeleccionada
  ]?.segments.find((e: any) => e.company === "GEB");
  return " Solicitud: " + obj.request_id;
};
