import { putLogin } from "../../services/LoginService";

const requeridos = ["user", "password"];

export const handleChange = (
  e: string,
  key: string,
  setUser: React.Dispatch<
    React.SetStateAction<{
      user: string;
      password: string;
    }>
  >
) => {
  setUser((prevUser) => ({ ...prevUser, [key]: e }));
};

export const validaDatos = (
  user: {
    user: string;
    password: string;
  },
  setFaltantes: React.Dispatch<React.SetStateAction<string[]>>
) => {
  let falta: any = [];

  requeridos.map((e: string) => {
    if (
      user[e as keyof typeof user] === undefined ||
      user[e as keyof typeof user] === ""
    ) {
      falta.push(e);
    }

    return falta;
  });

  setFaltantes(falta);

  return falta.length === 0;
};

export const handleLogin = async (
  user: {
    user: string;
    password: string;
  },
  setFaltantes: React.Dispatch<React.SetStateAction<string[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  login: (shortToken: string, csrfToken: string, mail: string) => void,
  setMensaje: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const puedePasar = validaDatos(user, setFaltantes);

  if (puedePasar) {
    setIsLoading(true);

    let raw: any = {
      user: user.user,
      password: user.password,
    };

    try {
      const result = await putLogin(JSON.stringify(raw));
      login(result.shortToken, result.csrfToken, user.user);
      setMensaje(false);
    } catch (error) {
      setMensaje(true);
      console.error("Error en la solicitud:", error);
    }

    setIsLoading(false);
  }
};

export function b642ab(base64String: string) {
  return Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
}

export function getValueFromResult(result: string, key: string): string {
  const keyIndex = result.indexOf(key);
  if (keyIndex !== -1) {
    const ampersandIndex = result.indexOf("&", keyIndex);

    return result.substring(
      keyIndex + key.length,
      ampersandIndex !== -1 ? ampersandIndex : undefined
    );
  }
  return "";
}
