import React from "react";
import "../../styles/loader.css";

const LoaderColectivo: React.FC = () => {
  return (
    <div className="container">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderColectivo;
