import { Occupation } from "../types/OccupationType";
import { request } from "./apiService";

export async function getOccupation(
  query: string,
  logout: any
): Promise<Occupation> {
  const types = await request<Occupation>(`/route/occupation/${query}`, logout);
  return types;
}
