import React from "react";

const Espacio: React.FC = () => {
  return (
    <div
      className="seatmap-section-row-seat"
      style={{
        border: "1px solid transparent",
        backgroundColor: "transparent",
        margin: "1px 0.5px",
        display: "inline-table",
        width: "16px",
        height: "16px",
      }}
    >
      <span className="seat gaps" style={{ color: "#ffffff" }}></span>
      <div className="edit-seat"></div>
    </div>
  );
};

export default Espacio;
