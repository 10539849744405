import React, { useContext, useEffect, useState } from "react";
import Segmentos from "./Segmentos";
import Tarifas from "./Tarifas";
import { getDuracion, coincideViaje } from "../../hooks/commonFunctions";
import { ResultadosContext } from "../../hooks/ResultadosContext";

const getBrands = (segmentos: any) => {
  let count: any = [];
  segmentos.map((e: any) => {
    if (!count.includes(e.brand)) {
      count.push(e.brand);
    }
    return count;
  });

  return segmentos[0].brand + " +" + (count.length - 1);
};

type ResultadoProps = {
  resultado: {
    arrival_date: string;
    arrival_time: string;
    display_arrival_time: string;
    departure_date: string;
    departure_time: string;
    display_departure_time: string;
    destination_company: string;
    destination_id: string;
    destination_name: string;
    duration: string;
    fare_classes: [];
    // {id: string; name: string; description: string; terms: string; fares: Array(1)};
    origin_company: string;
    origin_id: string;
    origin_name: string;
    price: number;
    segments: [
      {
        arrival_date: string;
        arrival_time: string;
        brand: string;
        company: string;
        departure_date: string;
        departure_time: string;
        destination_id: string;
        destination_name: string;
        operating_company: string;
        origin_id: string;
        origin_name: string;
        stops: any;
        display_name: string;
      }
    ];
    transfers: number;
  };
  setSegments: any;
};

const Resultado: React.FC<ResultadoProps> = ({ resultado, setSegments }) => {
  const [showPrecio, setShowPrecio] = useState(false);
  const [showDetalle, setShowDetalle] = useState(false);

  const { tarifaSeleccionada, idaVuelta } = useContext(ResultadosContext);

  useEffect(() => {
    if (!coincideViaje(tarifaSeleccionada, idaVuelta, resultado)) {
      setShowPrecio(false);
      setShowDetalle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarifaSeleccionada, idaVuelta]);

  return (
    <div>
      <div role="listitem" className="relative">
        <div className="flex justify-between items-center bg-white mb1px fs6 px2 color-grey-darkest box-shadow-black-10">
          <div className="flex items-center ml1 height30">
            <span aria-label="AMC" className="letter-spacing uppercase medium">
              {resultado.transfers === 0
                ? resultado.segments?.[0]?.brand
                : getBrands(resultado.segments)}
            </span>
          </div>
          {/* <div className="flex items-center">
            <div>
              <span className="pr3 xs-hide sm-hide color-grey fs6">
                <i
                  title="NotFilterable"
                  className="fa fa-ban color-grey mr1"
                ></i>
                No filtrable
              </span>
            </div>
          </div> */}
        </div>
        <div className="bg-white sm-p3 p2 flex justify-between flex-wrap border-left1 outline-accent-hover border-accent box-shadow-black-50">
          <div className="flex p2 xs-col-12 items-center">
            <div className="flex items-center mb2 pb2 border-bottom border-none mbn2 border-grey-lighter">
              <div className="flex-auto">
                <div className="regular truncate fs6 color-grey">
                  Salida
                  <div
                    title={resultado.display_departure_time}
                    className="sm-fs3 fs4 medium color-grey-darkest truncate"
                  >
                    <span className="">{resultado.display_departure_time}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-auto center px3 sm-px4">
              <div
                aria-label="7hr 40min"
                className="fs6 mb1 regular medium color-grey-darkest minwidth90"
              >
                {getDuracion(resultado.duration)}
              </div>
              <div
                className={`relative border-top flex justify-around ${
                  resultado.transfers === 0 ? "color-success" : "color-info"
                }`}
              >
                {/* {resultado.transfers !== 0 && (
                  <span className="bg-white border rounded-max stops-route-stop"></span>
                )} */}

                {Array.from({ length: resultado.transfers }).map((e, i) => {
                  return (
                    <span
                      className="bg-white border rounded-max stops-route-stop"
                      key={i}
                    ></span>
                  );
                })}
              </div>
              <div
                className={`fs6 inline-block border- border-warning rounded-max py1 sm-uppercase ${
                  resultado.transfers === 0 ? "color-success" : "color-info"
                }`}
              >
                {resultado.transfers === 0
                  ? "Directo"
                  : resultado.transfers > 1
                  ? resultado.transfers + " Transbordos"
                  : resultado.transfers + " Transbordo"}
              </div>
            </div>
            <div className="flex items-center mb2 pb2 border-bottom border-none mbn2 border-grey-lighter">
              <div className="flex-auto">
                <div className="regular truncate fs6 color-grey">
                  Llegada
                  <div
                    title={resultado.display_arrival_time}
                    className="sm-fs3 fs4 medium color-grey-darkest truncate"
                  >
                    <span className="">{resultado.display_arrival_time}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex xs-col-12 relative">
            <div
              onClick={() =>
                setShowDetalle((prevShowDetalle) => !prevShowDetalle)
              }
              tabIndex={0}
              aria-haspopup="true"
              role="button"
              aria-label=""
              className="outline-grey-lighter-focus px3 sm-px2 flex sm-flex-column justify-center items-center sm-fs2 fs6 color-info-hover pointer"
            >
              <i className="fa fa-map-o mr2 sm-mr0 sm-mb2 color-grey-lighter"></i>
              <div className="color-grey fs6">Detalles</div>
            </div>
            <div className="flex-auto xs-center xs-px3 xs-mr0 mr4 sm-ml2 pl3 border-left border-grey-lighter xs-border-none">
              <div className="flex items-center mb2 pb2 border-bottom border-none mbn2 border-grey-lighter">
                <div className="flex-auto">
                  <div className="regular truncate fs6 color-grey">
                    Comenzando en
                    <div className="sm-fs3 fs4 medium color-grey-darkest truncate">
                      <span className="">
                        <span className="mr1 fs6">$</span>
                        {resultado.price}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled={coincideViaje(tarifaSeleccionada, idaVuelta, resultado)}
              type="button"
              className={`relative font-family-title rounded self-center sm-mr3 mr2 border bg-transparent py2 px3 sm-fs5 fs6 pointer show-prices-button box-shadow-inset ${
                coincideViaje(tarifaSeleccionada, idaVuelta, resultado)
                  ? "color-grey-lighter border-grey-lighter pointer-events-none"
                  : "color-grey-darkest border-grey bg-black-10-hover bg-black-10-focus pointer"
              }`}
              onClick={() => setShowPrecio((prevShowPrecio) => !prevShowPrecio)}
            >
              <span>Ver precios</span>
            </button>
          </div>
        </div>

        <div className="overflow-hidden pb3">
          <div
            role="list"
            className="box-shadow-black-10-double bg-white sm-mx4 mx3 btrz-slideInDown--initial no-content-animation--initial btrz-slideInDown no-content-animation"
          >
            {(showPrecio ||
              coincideViaje(tarifaSeleccionada, idaVuelta, resultado)) && (
              <Tarifas
                tarifas={resultado.fare_classes}
                resultado={resultado}
                setSegments={setSegments}
              />
            )}
          </div>
          {showDetalle && (
            <Segmentos segmentos={resultado.segments} waiting={resultado} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Resultado;
