import React, { useContext } from "react";
import { validTarifa } from "../../hooks/Resultados/hooks";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type CantidadPasajesProps = {
  segmento: any;
  cantidadPasajes: number;
  isShowing: boolean;
  segments: any;
  setSegments: any;
  totalSegmentos: number;
};

const CantidadPasajes: React.FC<CantidadPasajesProps> = ({
  cantidadPasajes,
  isShowing,
  segments,
  setSegments,
  segmento,
  totalSegmentos,
}) => {
  const { pasajesReservados, tarifaSeleccionada, idaVuelta } =
    useContext(ResultadosContext);

  const handlePersonas = () => {
    let temp = pasajesReservados
      ?.find((e: any) => e.display_name === segmento.display_name)
      ?.pasajes?.filter((e: any) => {
        return e.numero !== "";
      });

    return temp ? temp.length : 0;
  };

  const habilitaSiguiente = () => {
    return (
      validTarifa(tarifaSeleccionada, idaVuelta).segments.length !== segments &&
      handlePersonas() === cantidadPasajes
    );
  };

  return (
    <div style={{ display: `${isShowing ? "block" : "none"}` }}>
      <div className="clearfix fs6 flex items-center my3">
        <button
          disabled={segments === 1 ? true : false}
          type="button"
          className={`relative font-family-site rounded sm-fs3 fs4 col-12 border bg-transparent square-width36px py2 px2 sm-fs5 fs6 ${
            segments === 1
              ? "color-grey-lighter border-grey-lighter pointer-events-none"
              : "color-grey-darkest border-grey bg-black-10-hover bg-black-10-focus pointer"
          }`}
          onClick={() => {
            setSegments((prevSegments: any) => prevSegments - 1);
          }}
        >
          <i className="fa fa-arrow-left"></i>
        </button>
        <div className="flex-auto center px2 sm-px3">
          <div
            className="fs6 regular medium color-grey-darkest"
            style={{ minWidth: "90px" }}
          >
            {segments} / {totalSegmentos}
          </div>
          <div className="clearfix mb2 pb2 relative">
            {Array.from({ length: totalSegmentos }).map((e: any, i: number) => {
              return (
                <div
                  className={`absolute left-0 right-0 bottom-0 border ${
                    i === segments - 1 ? "border-info" : "border-grey-lightest"
                  }`}
                  style={{
                    width: `${100 / totalSegmentos}%`,
                    marginLeft: `${(100 / totalSegmentos) * i}%`,
                  }}
                  key={i}
                ></div>
              );
            })}
          </div>
        </div>
        <button
          type="button"
          className={`relative font-family-site rounded sm-fs3 fs4 col-12 border bg-transparent square-width36px py2 px2 sm-fs5 fs6 ${
            habilitaSiguiente()
              ? "color-grey-darkest border-grey bg-black-10-hover bg-black-10-focus pointer"
              : "color-grey-lighter border-grey-lighter pointer-events-none"
          }`}
          onClick={() => {
            if (habilitaSiguiente())
              setSegments((prevSegments: any) => prevSegments + 1);
          }}
        >
          <i className="fa fa-arrow-right"></i>
        </button>
      </div>
      <div className="flex justify-between fs5 py2 px3 mb2 color-info-darker bg-info-lightest rounded">
        <span className="bold">
          {handlePersonas()} / {cantidadPasajes}
        </span>
        <span className="bold"></span>
      </div>
    </div>
  );
};

export default CantidadPasajes;
