import React, { useEffect } from "react";

type MethodProps = {
  payment: any;
  setPayment: any;
  setRecibidoValido: any;
  faltantes: any;
};

const Cash: React.FC<MethodProps> = ({
  payment,
  setPayment,
  setRecibidoValido,
  faltantes,
}) => {
  useEffect(() => {
    if (payment?.quierePagar < 0 || payment?.quierePagar < payment?.monto) {
      setRecibidoValido(true);
    } else {
      setRecibidoValido(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment?.quierePagar, payment?.monto]);

  return (
    <>
      <div className="col-12 sm-col sm-col-4 sm-px4 mb4">
        <div className="btrz-input-form">
          <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
            Recibido
          </label>
          <div
            className={`rounded border mb1px relative  ${
              faltantes.includes("quierePagar") ||
              payment?.quierePagar < 0 ||
              payment?.quierePagar < payment?.monto
                ? " border-danger"
                : "border-grey-lightest border-accent-hover"
            } border`}
          >
            <div className="absolute flex justify-start pointer-events-none height100 width100">
              <div className="ml3">
                <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
                  <i className="fa fa-usd "></i>
                </span>
              </div>
            </div>
            <input
              value={payment?.quierePagar ? payment?.quierePagar : 0}
              step="0.01"
              onChange={(e) =>
                setPayment((prevPayment: any) => {
                  return {
                    ...prevPayment,
                    quierePagar: parseFloat(e.target.value),
                  };
                })
              }
              id="_b05t7bp9b"
              name="cashReceived"
              type="number"
              autoComplete="on"
              className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  text-indent"
            />
          </div>
          {(faltantes.includes("quierePagar") ||
            payment?.quierePagar < 0 ||
            payment?.quierePagar < payment?.monto) && (
            <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
              <span>
                <i className="fa fa-exclamation-circle mr1"></i>Importe inválido
              </span>
            </div>
          )}
        </div>
      </div>
      {payment.quierePagar > payment.monto && (
        <div className="col-12 sm-col sm-col-8 sm-px4 mb4">
          <table className="border-none font-family-site sm-fs3 fs4 bg-transparent col-12">
            <tbody>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Recibido:
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  <span>$ {payment?.quierePagar}</span>
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest"></td>
              </tr>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Total:
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  <span>$ {payment.monto}</span>
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest"></td>
              </tr>
              <tr className="">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Cambio:
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  <span>
                    $ {(payment?.quierePagar - payment?.monto).toFixed(2)}
                  </span>
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Cash;
