import React, {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import AsientosEB from "./AsientosEB";
import AsientosLIM from "./AsientosLIM";
import { InitialContext } from "../../hooks/InitialContext";
import { validTarifa } from "../../hooks/Resultados/hooks";
import MensajeExpiracion from "./tipos-asiento/MensajeExpiracion";
import { TimerContext } from "../../hooks/TimerContext";
import LoaderColectivo from "./LoaderColectivo";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type AsientosProps = {
  cantidadPasajes: any;
  setCantidadPasajes: any;
  segments: number;
  setSegments: Dispatch<SetStateAction<number>>;
};

const Asientos: React.FC<AsientosProps> = ({
  cantidadPasajes,
  setCantidadPasajes,
  segments,
  setSegments,
}) => {
  const [loaderColectivo, setLoaderColectivo] = useState(false);
  const [currentSegmento, setCurrentSegmento] = useState<any>();

  const { reserva } = useContext(InitialContext);

  const {
    tarifaSeleccionada,
    idaVuelta,
    mensajeReserva,
    setMensajeReserva,
    mensajeMapa,
    setMensajeMapa,
  } = useContext(ResultadosContext);

  const { setMuestraExpira } = useContext(TimerContext);

  useEffect(() => {
    let temp = validTarifa(tarifaSeleccionada, idaVuelta);

    if (temp) {
      let ind = segments;
      ind = ind - 1;

      setCurrentSegmento(temp.segments[ind]);
    }
  }, [tarifaSeleccionada, idaVuelta, segments]);

  useEffect(() => {
    let temp = 0;
    reserva.pasajes.map((e) => {
      temp = temp + e.cantidad;
      return temp;
    });

    setCantidadPasajes(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva]);

  useEffect(() => {
    setMensajeMapa(false);
    setMensajeReserva(false);
    setMuestraExpira([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {mensajeReserva && (
        <div className="z10 fixed absolute right-1 top-1 mt5 col-8 sm-col-6 lg-col-4">
          <div className="mb2 pointer flex fs6 sm-fs4 box-shadow-black-30 letter-spacing bg-warning color-warning-darkest">
            <div className="flex items-center px3 color-white-hover color-warning-lighter">
              <i className="fa fa-exclamation-circle fa-lg"></i>
            </div>
            <div className="flex-auto p3 border-left border-warning-lighter">
              No se pudo reservar el asiento seleccionado, inténtelo nuevamente.
              {currentSegmento?.request_id &&
                " Solicitud: " + currentSegmento?.request_id}
            </div>
            <div
              className="p3 flex items-center color-white-hover color-warning-lighter"
              onClick={() => setMensajeReserva(false)}
            >
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
      )}
      {mensajeMapa && (
        <div className="z10 fixed absolute right-1 top-1 mt5 col-8 sm-col-6 lg-col-4">
          <div className="mb2 pointer flex fs6 sm-fs4 box-shadow-black-30 letter-spacing bg-warning color-warning-darkest">
            <div className="flex items-center px3 color-white-hover color-warning-lighter">
              <i className="fa fa-exclamation-circle fa-lg"></i>
            </div>
            <div className="flex-auto p3 border-left border-warning-lighter">
              No se pudo cargar el mapa, inténtelo nuevamente.{" "}
              {currentSegmento?.request_id &&
                " Solicitud: " + currentSegmento?.request_id}
            </div>
            <div
              className="p3 flex items-center color-white-hover color-warning-lighter"
              onClick={() => setMensajeMapa(false)}
            >
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
      )}
      <MensajeExpiracion />
      {tarifaSeleccionada !== undefined && (
        <div>
          <div>
            <div className="z1 fixed right-1 top-1 mt5 col-8 sm-col-6 lg-col-4"></div>
          </div>
          <div className="btrz-card overflow-auto xs-hide col-12 sm-col-3 fixed mt4 top-1 bottom-0 right-0 p3 sm-p4 bg-white box-shadow-black-10">
            <div className="relative">
              {loaderColectivo && <LoaderColectivo />}
              <div>
                <span>
                  <div>
                    <div className="mb3">
                      <div className="fs6 sm-fs5 pl1 color-grey pt2 mb1 sm-mb2 sm-mb2">
                        <i className="fa fa-circle-o mr2"></i>
                        {currentSegmento?.["origin_name"]}
                      </div>
                      <div className="fs6 sm-fs5 pl1 color-grey pt2 border-top border-grey-lightest mb1 sm-mb2 sm-mb2">
                        <i className="fa fa-map-marker fs3 mr2"></i>
                        {currentSegmento?.["destination_name"]}
                      </div>
                    </div>
                    {/* paso los segmentos a cada mapa de asientos */}
                    {validTarifa(tarifaSeleccionada, idaVuelta)?.segments?.map(
                      (e: any, i: number) => {
                        return (
                          <div key={i}>
                            {e.company === "GEB" ? (
                              <AsientosEB
                                segmento={currentSegmento}
                                cantidadPasajes={cantidadPasajes}
                                isShowing={segments - 1 === i}
                                segments={segments}
                                setSegments={setSegments}
                                totalSegmentos={
                                  validTarifa(tarifaSeleccionada, idaVuelta)
                                    ?.segments.length
                                }
                                setLoaderColectivo={setLoaderColectivo}
                              />
                            ) : (
                              <AsientosLIM
                                segmento={currentSegmento}
                                cantidadPasajes={cantidadPasajes}
                                isShowing={segments - 1 === i}
                                segments={segments}
                                setSegments={setSegments}
                                totalSegmentos={
                                  validTarifa(tarifaSeleccionada, idaVuelta)
                                    ?.segments.length
                                }
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Asientos;
