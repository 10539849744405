import { Ciudades } from "../types/Ciudades";
import { request } from "./apiService";

export async function getDestinos(
  id: string,
  company: string,
  logout: any
): Promise<Ciudades> {
  const ciudades = await request<Ciudades>(
    `/route/destinations/?origin=${id}&company=${company}`,
    logout
  );
  return ciudades;
}
