import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  setAuthCookie,
  removeAuthCookie,
  isLogged,
} from "../services/AuthService";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (shortToken: string, csrfToken: string, email: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth debe ser utilizado dentro de AuthProvider");
  }
  return context;
};

interface AuthProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() =>
    isLogged()
  );

  const navigate = useNavigate();

  const login = (shortToken: string, csrfToken: string, email: string) => {
    // Lógica para iniciar sesión y establecer el estado de autenticación
    setIsAuthenticated(true);
    setAuthCookie(shortToken, csrfToken, email);
  };

  const logout = () => {
    // Lógica para cerrar sesión y establecer el estado de autenticación
    setIsAuthenticated(false);
    removeAuthCookie();
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
