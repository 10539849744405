import React, { useState, FC, ReactNode } from "react";
import { IInitialContext } from "../types/InitialType";
import { Resultados } from "../types/ResultadoType";

const defaultState = {
  reserva: {
    origen: {
      name: "",
      id: "",
      company: "",
    },
    destino: {
      name: "",
      id: "",
      company: "",
    },
    salida: new Date().toLocaleDateString("en-ZA"),
    vuelta: null,
    pasajes: [{ name: "", cantidad: 0, id: "" }],
  },
  updateReserva: () => ({}),
  restartReserva: () => ({}),
  faltantes: [],
  updateFaltantes: () => [],
  resultados: {
    errorCode: "",
    errorMessage: "",
  },
  updateResultados: () => [],
  cliente: {},
  setCliente: () => ({}),
  fullRestart: () => ({}),
};

export const InitialContext =
  React.createContext<IInitialContext>(defaultState);

interface InitialContextProps {
  children: ReactNode;
}

export const InitialProvider: FC<InitialContextProps> = ({ children }) => {
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------- SE ACTUALIZAN EN VISTA INICIAL -----------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  // RESERVA SE USA PARA GUARDAR LOS DATOS DE ORIGEN, DESTINO, SALIDA, VUELTA, CANTIDAD Y TIPO DE PASAJES
  const [reserva, setReserva] = useState(defaultState.reserva);
  // FALTANTES SE USA PARA MARCAR LOS CAMPOS OBLIGATORIOS FALTANTES EN LA PRIMERA VISTA
  const [faltantes, setFaltantes] = useState<string[]>(defaultState.faltantes);
  const [resultados, setResultados] = useState<Resultados>(
    defaultState.resultados
  );

  // ---------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------- SE ACTUALIZAN EN VISTA CLIENTES ---------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  const [cliente, setCliente] = useState<any>();

  const updateReserva = (updatedProps: Object) => {
    setReserva((prevReserva: any) => ({ ...prevReserva, ...updatedProps }));
  };

  const restartReserva = () => {
    let temp = defaultState.reserva;
    temp.pasajes = reserva.pasajes.map((e) => {
      e.cantidad = 0;
      return e;
    });

    setReserva({ ...temp });
  };

  const updateFaltantes = (falta: string[]) => {
    setFaltantes(falta);
  };

  const updateResultados = (result: Resultados) => {
    setResultados(result);
  };

  const fullRestart = () => {
    setCliente({});
  };

  return (
    <InitialContext.Provider
      value={{
        reserva,
        updateReserva,
        restartReserva,
        faltantes,
        updateFaltantes,
        resultados,
        updateResultados,
        cliente,
        setCliente,
        fullRestart,
      }}
    >
      {children}
    </InitialContext.Provider>
  );
};
