import React, { useContext, useEffect, useState } from "react";

import Asiento from "./Asiento";
import { copyData } from "../../hooks/Detalles/hooks";
import { ResultadosContext } from "../../hooks/ResultadosContext";

interface PasajerosProps {
  seg: number;
  faltantes: any;
  cantidad: number;
}

const Pasajeros: React.FC<PasajerosProps> = ({ seg, faltantes, cantidad }) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [emails, setEmails] = useState<any>();
  const [falta, setFalta] = useState<any>();

  const { pasajesReservados, updatePasajesReservados } =
    useContext(ResultadosContext);

  const salidas = [...pasajesReservados].filter(
    (e) => e.etapa === "departures"
  );
  const vueltas = [...pasajesReservados].filter((e) => e.etapa === "returns");

  const handleChange = (e: string, key: string) => {
    let arr = [...pasajesReservados];
    arr[0].pasajes[seg] = {
      ...arr[0].pasajes[seg],
      [key]: e,
    };

    updatePasajesReservados(arr);
  };

  useEffect(() => {
    setHasCopied(false);
  }, []);

  useEffect(() => {
    setFalta(
      faltantes?.campos?.find((e: any) => e.indice === seg)?.camposFaltantes
    );

    setEmails(faltantes?.email?.includes(seg));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faltantes]);

  return (
    <div className="btrz-card mb3 p4 sm-p5 bg-white box-shadow-black-10">
      <div className="clearfix sm-mxn4 remove-mb4-last-child">
        <div className="col-12 sm-col sm-px4">
          <div>
            <div className="clearfix sm-mxn4">
              <div className="col-12 sm-col sm-col-6 md-col-4 sm-px4 mb4">
                <div className="btrz-input-form">
                  <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                    Nombre
                  </label>
                  <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                    <input
                      value={
                        pasajesReservados?.[0]?.pasajes[seg]?.firstName
                          ? pasajesReservados?.[0]?.pasajes[seg]?.firstName
                          : ""
                      }
                      id="_nxd10z4x2"
                      name="passengers[0][firstName]"
                      type="text"
                      autoComplete="on"
                      className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent"
                      data-focusonload="true"
                      onChange={(e) =>
                        handleChange(e.target.value, "firstName")
                      }
                    />
                  </div>
                </div>
                {falta?.includes("firstName") && (
                  <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                    <span>
                      <i className="fa fa-exclamation-circle mr1"></i>Requerido
                    </span>
                  </div>
                )}
              </div>
              <div className="col-12 sm-col sm-col-6 md-col-4 sm-px4 mb4">
                <div className="btrz-input-form">
                  <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                    Apellido
                  </label>
                  <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                    <input
                      value={
                        pasajesReservados?.[0]?.pasajes[seg]?.lastName
                          ? pasajesReservados?.[0]?.pasajes[seg]?.lastName
                          : ""
                      }
                      id="_jova80ug2"
                      name="passengers[0][lastName]"
                      type="text"
                      autoComplete="on"
                      className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent"
                      onChange={(e) => handleChange(e.target.value, "lastName")}
                    />
                  </div>
                </div>
                {falta?.includes("lastName") && (
                  <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                    <span>
                      <i className="fa fa-exclamation-circle mr1"></i>Requerido
                    </span>
                  </div>
                )}
              </div>
              <div className="col-12 sm-col sm-col-6 md-col-4 sm-px4 mb4">
                <div className="btrz-input-form">
                  <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                    Correo electrónico
                  </label>
                  <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                    <input
                      value={
                        pasajesReservados?.[0]?.pasajes[seg]?.email
                          ? pasajesReservados?.[0]?.pasajes[seg]?.email
                          : ""
                      }
                      id="_6ri35gpn8"
                      name="passengers[0][email]"
                      type="email"
                      autoComplete="on"
                      className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent"
                      onChange={(e) => handleChange(e.target.value, "email")}
                    />
                  </div>
                </div>
                {(falta?.includes("email") || emails) && (
                  <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                    <span>
                      <i className="fa fa-exclamation-circle mr1"></i>Requerido
                      correo válido
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {seg === 0 && cantidad > 1 && (
            <div className="clearfix color-info mb3 fs6 py2">
              <button
                tabIndex={0}
                type="button"
                className="relative font-family-title rounded mr3 color-grey-darkest border border-grey bg-transparent bg-black-10-hover bg-black-10-focus py2 px3 sm-fs5 fs6 pointer "
                onClick={() => {
                  copyData(pasajesReservados, updatePasajesReservados);

                  setHasCopied(true);
                }}
              >
                <i className="fa mr2 fa-copy"></i>
                <span>Copiar información</span>
              </button>
              <span className="p2 inline-block">
                <i className="fa fa-info-circle mr2 fa-lg"></i>
                {hasCopied
                  ? "La información fue copiada abajo para todos los pasajeros"
                  : "La información de arriba puede ser copiada para todos los pasajeros"}
              </span>
            </div>
          )}

          <div className="clearfix">
            <div className="mb4 border-top border-grey-lighter"></div>
            <label className="sm-fs4 fs6 medium mb2 inline-block color-grey-darkest">
              Asiento
            </label>
            <div className="clearfix border rounded border-grey-lightest mb4">
              <div className="col-12 sm-col-6 sm-col p3 sm-p4">
                <label className="fs6 color-grey-darker uppercase sm-fs4 medium mb2 inline-block">
                  Salida
                </label>
                {salidas.map((e: any, i: number) => {
                  return (
                    <div key={i}>
                      <Asiento indice={i} arr={salidas} pasaje={e} seg={seg} />
                    </div>
                  );
                })}
              </div>
              {vueltas?.length > 0 && (
                <div className="col-12 sm-col-6 sm-col p3 sm-p4">
                  <label className="fs6 color-grey-darker uppercase sm-fs4 medium mb2 inline-block">
                    Regreso
                  </label>
                  {vueltas.map((e: any, i: number) => {
                    return (
                      <div key={i}>
                        <Asiento
                          indice={i}
                          arr={vueltas}
                          pasaje={e}
                          seg={seg}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pasajeros;
