export const getDuracion = (tiempo: string): string => {
  var partes = tiempo.split(":");
  return partes[0] + " h " + partes[1] + " min";
};

export const getFormatDate = (date: string, element: string): string => {
  const [año, mes, dia] = date.split(element).map(Number);

  const fecha = new Date(año, mes - 1, dia);
  const diasSemana = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  return `${diasSemana[fecha.getDay()]} ${
    meses[fecha.getMonth()]
  } ${fecha.getDate()}, ${fecha.getFullYear()}`;
};

export const tarifaCoincide = (
  tarifaSeleccionada: any,
  idaVuelta: string,
  resultado: any
): boolean => {
  return tarifaSeleccionada[idaVuelta]?.trip_id === resultado?.trip_id;
};

export const coincideViaje = (
  tarifaSeleccionada: any,
  idaVuelta: string,
  resultado: any
): boolean => {
  return (
    tarifaSeleccionada[idaVuelta]?.arrival_time === resultado?.arrival_time &&
    tarifaSeleccionada[idaVuelta]?.departure_time ===
      resultado?.departure_time &&
    tarifaSeleccionada[idaVuelta]?.segments === resultado?.segments
  );
};
