import React, { useEffect, useState } from "react";

import Resultado from "./Resultado";
import Filtros from "./Filtros";
import { Resultados as ResultadosType } from "../../types/ResultadoType";
import { areResultsValid } from "../../hooks/Resultados/hooks";

type ResultadosProps = {
  resultados: ResultadosType | any;
  setSegments: any;
};

const Resultados: React.FC<ResultadosProps> = ({ resultados, setSegments }) => {
  const [paginaActual, setPaginaActual] = useState(6);
  const [resultadosPaginados, setResultadosPaginados] = useState<any>();
  const [parametro, setParametro] = useState({
    key: "departure_time",
    icono: "fa-clock-o",
  });
  const [parametroOrden, setParametroOrden] = useState(true);
  const [resultadosOrdenados, setResultadosOrdenados] = useState<any>([]);
  const [horario, setHorario] = useState("00:00:00");
  const [resultadosConHorario, setResultadosConHorario] = useState<any>([]);
  const [transfers, setTransfers] = useState("*");

  // resultados lo toma del elemento padre, no hace falta que cambie nada en la ida y vuelta

  useEffect(() => {
    if (areResultsValid(resultados)) {
      let temp = [...resultados];
      let filtrados = temp
        .filter((e) => {
          const horarioResultado = new Date(
            e.departure_date + " " + e.departure_time
          );
          const horarioSeleccionado = new Date(
            e.departure_date + " " + horario
          );

          return horarioResultado >= horarioSeleccionado;
        })
        .filter((e) => {
          if (transfers === "*") {
            return e;
          } else {
            return e.transfers <= Number(transfers);
          }
        });

      setResultadosConHorario(filtrados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horario, transfers, resultados]);

  useEffect(() => {
    let temp = [...resultadosConHorario];

    if (parametro.key === "departure_time") {
      temp.sort((a: any, b: any) => {
        const dateA: any = new Date(a.departure_date + " " + a.departure_time);
        const dateB: any = new Date(b.departure_date + " " + b.departure_time);
        if (parametroOrden === true) {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    } else {
      if (parametroOrden === true) {
        temp.sort((a: any, b: any) => a[parametro.key] - b[parametro.key]);
      } else {
        temp.sort((a: any, b: any) => b[parametro.key] - a[parametro.key]);
      }
    }

    setResultadosOrdenados(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametro, parametroOrden, resultadosConHorario]);

  useEffect(() => {
    let temp = [...resultadosOrdenados];

    setResultadosPaginados(temp.slice(0, paginaActual));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, resultadosOrdenados]);

  return (
    <>
      <Filtros
        parametro={parametro}
        setParametro={setParametro}
        parametroOrden={parametroOrden}
        setParametroOrden={setParametroOrden}
        setHorario={setHorario}
        setTransfers={setTransfers}
      />
      {resultadosPaginados?.map((e: any, i: number) => {
        return (
          <div key={i}>
            <Resultado resultado={e} setSegments={setSegments} />
          </div>
        );
      })}
      {/* paginador */}
      <div className="pt3 flex justify-around">
        <button
          tabIndex={0}
          type="button"
          className={`relative font-family-title rounded border bg-transparent py2 px3 sm-fs5 fs6 ${
            paginaActual < resultados?.length
              ? "pointer color-grey-darkest border-grey  bg-black-10-hover bg-black-10-focus"
              : "pointer-events-none color-grey-lighter border-grey-lighter"
          }`}
          onClick={() =>
            setPaginaActual((prevPaginaActual) => prevPaginaActual + 6)
          }
        >
          <i className="fa mr2 fa-plus"></i>
          <span>Mostrar más resultados</span>
        </button>
      </div>
      <div className="mt3 color-grey-darker center fs6">
        <div className="inline-block mx3">
          Resultados 1 a{" "}
          {paginaActual > resultados?.length
            ? resultados?.length
            : paginaActual}{" "}
          de {resultados?.length}
        </div>
      </div>
    </>
  );
};

export default Resultados;
