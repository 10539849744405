import React, { useContext } from "react";
import { tarifaCoincide } from "../../hooks/commonFunctions";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type TarifasProps = {
  tarifas:
    | []
    | [
        {
          description: string;
          fares: [any];
          id: string;
          name: string;
          terms: string;
        }
      ];
  resultado: {
    arrival_date: string;
    arrival_time: string;
    departure_date: string;
    departure_time: string;
    destination_company: string;
    destination_id: string;
    destination_name: string;
    duration: string;
    fare_classes: [];
    // {id: string; name: string; description: string; terms: string; fares: Array(1)};
    origin_company: string;
    origin_id: string;
    origin_name: string;
    price: number;
    segments: [
      {
        arrival_date: string;
        arrival_time: string;
        brand: string;
        company: string;
        departure_date: string;
        departure_time: string;
        destination_id: string;
        destination_name: string;
        operating_company: string;
        origin_id: string;
        origin_name: string;
      }
    ];
    transfers: number;
  };
  setSegments: any;
};

const Tarifas: React.FC<TarifasProps> = ({
  tarifas,
  resultado,
  setSegments,
}) => {
  const { tarifaSeleccionada, updateTarifaSeleccionada, idaVuelta } =
    useContext(ResultadosContext);

  return (
    <>
      {tarifas.map((e, i) => {
        return (
          <div
            role="listitem"
            key={i}
            className="p3 flex justify-between flex-wrap border-bottom border-grey-lightest outline-accent-hover"
          >
            <div className="xs-col-12 flex mb3 sm-mb0">
              <div
                role="radio"
                aria-label="Estandar. Estandar. "
                tabIndex={0}
                className="outline-grey-lighter-focus pointer fs3 px3 flex flex-column justify-center items-center border-accent"
                data-focusonload="true"
                aria-checked="true"
                onClick={() => {
                  setSegments(1);
                  updateTarifaSeleccionada(
                    { ...resultado, fare_classes: e },
                    idaVuelta
                  );
                }}
              >
                <div className="regular fs6 color-grey mb1">Seleccione</div>
                {tarifaCoincide(tarifaSeleccionada, idaVuelta, {
                  ...resultado,
                  fare_classes: e,
                }) ? (
                  <i className="fa fa-dot-circle-o color-accent"></i>
                ) : (
                  <i className="fa fa-circle-o color-grey-lighter"></i>
                )}
              </div>
              <div className="p2 ml3">
                <div
                  aria-label="Estandar Estandar"
                  className="sm-fs4 fs5 medium mb1 color-accent"
                >
                  {e.name + "  "}
                  <div className="inline-block">
                    <i
                      aria-haspopup="true"
                      role="button"
                      title="Términos y condiciones"
                      className="fa fa-info-circle color-grey-lighter color-info-hover pointer"
                    ></i>
                  </div>
                </div>
                <div className="fs6 italic color-grey">{e.description}</div>
              </div>
            </div>
            <div className="sm-ml4 flex flex-wrap justify-end xs-col-12 border-left border-grey-lighter sm-pl3 xs-border-none">
              {e.fares.map((i, ind) => {
                return (
                  <div
                    className="flex items-center mb2 pb2 border-bottom border-none mbn2 mr3 sm-mr4 border-grey-lighter"
                    key={ind}
                  >
                    <div className="flex-auto">
                      <div className="regular truncate fs6 color-grey capitalize">
                        {i.name}
                        <div className="sm-fs3 fs4 medium color-grey-darkest truncate">
                          <span className="">
                            <span className="mr1 fs6">$</span>
                            {i.price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Tarifas;
