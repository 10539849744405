import React, { useContext, useState } from "react";
import { InitialContext } from "../../hooks/InitialContext";

interface NumberInputProps {
  title: string;
  subtitle?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({ title, subtitle }) => {
  const [focused, setFocused] = useState(false);

  const { reserva, updateReserva } = useContext(InitialContext);

  // ACTUALIZA CONTADOR
  // ACTUALIZA PASAJES EN RESERVA
  const handleChange = (type: string) => {
    let temp = reserva.pasajes;
    temp.map((e) => {
      if (e.name === title) {
        if (type === "decrement") {
          e.cantidad = e.cantidad !== 0 ? e.cantidad - 1 : 0;
        } else {
          e.cantidad = e.cantidad + 1;
        }
      }

      return e;
    });

    updateReserva({ pasajes: [...temp] });
  };

  return (
    <div className="focus-none">
      <div className="flex">
        <div className="square-width">
          <div
            id="decrement"
            className="center font-family-site rounded pointer sm-fs3 fs4 py3 col-12 color-grey-darkest border border-grey bg-transparent bg-black-10-hover"
            onClick={() => handleChange("decrement")}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            tabIndex={-1}
          >
            <i className="fa fa-minus"></i>
          </div>
        </div>
        <div
          className={`flex-auto truncate center mx1 inline-block sm-fs3 fs4 rounded p3 color-grey-darkest border border-accent-hover box-shadow-accent-focus ${
            focused ? "box-shadow-accent" : ""
          } ${
            reserva?.pasajes?.find((e) => e.name === title)?.cantidad === 0
              ? "border-grey"
              : "border-grey-lighter"
          }`}
          tabIndex={-1}
        >
          <span className="bold pr2">
            {reserva?.pasajes?.find((e) => e.name === title)?.cantidad}
          </span>
          <span>{title}</span>
        </div>
        <div className="square-width">
          <div
            id="increment"
            className="center font-family-site rounded pointer sm-fs3 fs4 py3 col-12 color-grey-darkest border border-grey bg-transparent bg-black-10-hover"
            onClick={() => handleChange("increment")}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            tabIndex={-1}
          >
            <i className="fa fa-plus"></i>
          </div>
        </div>
      </div>
      <div className="sm-fs4 fs6 italic pt2 color-grey center">
        {subtitle ? subtitle : ""}
      </div>
    </div>
  );
};

export default NumberInput;
