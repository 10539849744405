import React, { useContext, Dispatch, SetStateAction } from "react";
import { InitialContext } from "../../../hooks/InitialContext";
import { handleReservaGEB } from "../../../hooks/Resultados/hooks";
import { TimerContext } from "../../../hooks/TimerContext";
import { useAuth } from "../../../utils/AuthContext";
import { ResultadosContext } from "../../../hooks/ResultadosContext";

type DisponibleType = {
  columna: any;
  asiento: any;
  segmento: any;
  cupo: number;
  setLoaderColectivo: Dispatch<SetStateAction<boolean>>;
};

const Disponible: React.FC<DisponibleType> = ({
  columna,
  asiento,
  segmento,
  cupo,
  setLoaderColectivo,
}) => {
  const { reserva } = useContext(InitialContext);

  const { pasajesReservados, updatePasajesReservados, setMensajeReserva } =
    useContext(ResultadosContext);

  const { setTimerActive } = useContext(TimerContext);

  const { logout } = useAuth();

  const startTimer = () => {
    setTimerActive(true);
  };

  const handleHover = (m: any) => {
    let temp = pasajesReservados
      ?.find((s: any) => s.display_name === segmento.display_name)
      ?.pasajes.find((e: any) => e.number === m.number);

    return temp ? "seat-hover" : "seat-disponible";
  };

  return (
    <div
      // AGREGO A LA RESERVA
      onClick={() =>
        handleReservaGEB(
          {
            ...columna,
            ...asiento,
            cupo: cupo,
          },
          pasajesReservados,
          segmento,
          updatePasajesReservados,
          startTimer,
          setMensajeReserva,
          reserva.vuelta === null ? "s" : "r",
          setLoaderColectivo,
          logout
        )
      }
      className={`seatmap-section-row-seat ${handleHover(asiento)}`}
      style={{
        display: "inline-table",
        width: "16px",
        margin: "1px 0.5px",
      }}
    >
      <span className="seat available">{asiento.number}</span>

      <div className="edit-seat"></div>
    </div>
  );
};

export default Disponible;
