import React, { useState, FC, ReactNode, useEffect, useContext } from "react";
import { IResultadosContext } from "../types/ResultadosType";
import { TimerContext } from "./TimerContext";
import { desreservaAsientos } from "./Pago/hooks";
import { useAuth } from "../utils/AuthContext";
import { InitialContext } from "./InitialContext";

const defaultState = {
  tarifaSeleccionada: {
    departures: {
      arrival_date: "",
      arrival_time: "",
      departure_date: "",
      departure_time: "",
      destination_company: "",
      destination_id: "",
      destination_name: "",
      duration: "",
      fare_classes: [],
      // {id: string; name: string; description: string; terms: string; fares: Array(1)};
      origin_company: "",
      origin_id: "",
      origin_name: "",
      price: 0,
      segments: [
        {
          arrival_date: "",
          arrival_time: "",
          brand: "",
          company: "",
          departure_date: "",
          departure_time: "",
          destination_id: "",
          destination_name: "",
          operating_company: "",
          origin_id: "",
          origin_name: "",
          stops: [],
        },
      ],
      transfers: 0,
    },
    returns: {
      arrival_date: "",
      arrival_time: "",
      departure_date: "",
      departure_time: "",
      destination_company: "",
      destination_id: "",
      destination_name: "",
      duration: "",
      fare_classes: [],
      // {id: string; name: string; description: string; terms: string; fares: Array(1)};
      origin_company: "",
      origin_id: "",
      origin_name: "",
      price: 0,
      segments: [
        {
          arrival_date: "",
          arrival_time: "",
          brand: "",
          company: "",
          departure_date: "",
          departure_time: "",
          destination_id: "",
          destination_name: "",
          operating_company: "",
          origin_id: "",
          origin_name: "",
          stops: [],
        },
      ],
      transfers: 0,
    },
  },
  updateTarifaSeleccionada: () => ({}),
  firstUpdateTarifaSeleccionada: () => ({}),
  pasajesReservados: [],
  updatePasajesReservados: () => [],
  idaVuelta: "departures",
  setIdaVuelta: () => ({}),
  fullRestartResultados: () => ({}),
  mensajeReserva: false,
  setMensajeReserva: () => ({}),
  mensajeMapa: false,
  setMensajeMapa: () => ({}),
};

export const ResultadosContext =
  React.createContext<IResultadosContext>(defaultState);

interface ResultadosContextProps {
  children: ReactNode;
}

export const ResultadosProvider: FC<ResultadosContextProps> = ({
  children,
}) => {
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------- SE ACTUALIZAN EN VISTA RESULTADOS --------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------------
  const [idaVuelta, setIdaVuelta] = useState<string>(defaultState.idaVuelta);
  // TARIFA SELECCIONADA GUARDA EL RESULTADO SELECCIONADO (ITINERARIO) CON LA CLASE DE TICKET QUE ELIJE
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState<any>(
    defaultState.tarifaSeleccionada
  );
  // ARRAY DE SEGMENTOS CON SUS PASAJES RESPECTIVOS. LE PONGO FLAG DE TIPO PARA IDA O VUELTA
  const [pasajesReservados, setPasajesReservados] = useState<any>(
    defaultState.pasajesReservados
  );
  const [localPasajes, setLocalPasajes] = useState<any>([]);
  const [mensajeReserva, setMensajeReserva] = useState(
    defaultState.mensajeReserva
  );
  const [mensajeMapa, setMensajeMapa] = useState(defaultState.mensajeMapa);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const { resetTimer } = useContext(TimerContext);

  const { reserva } = useContext(InitialContext);

  const { logout } = useAuth();

  const firstUpdateTarifaSeleccionada = (tarifa: any, idaVuelta: string) => {
    setTarifaSeleccionada(() => ({
      [idaVuelta]: { ...tarifa },
    }));
  };

  const updateTarifaSeleccionada = (tarifa: any, idaVuelta: string) => {
    try {
      // agarro los pasajesReservados que ya no van y por cada pasajero deselecciono el asiento
      const temp = [...pasajesReservados].filter(
        (e: any) => e.etapa === idaVuelta
      );

      // recorro los pasajes y los desreservo
      desreservaAsientos(
        temp,
        setIsLoading,
        setMensajeReserva,
        resetTimer,
        reserva.vuelta === null ? "s" : "r",
        logout
      );
    } finally {
      setTarifaSeleccionada((prevTarifaSeleccionada: any) => ({
        ...prevTarifaSeleccionada,
        [idaVuelta]: { ...tarifa },
      }));
    }
  };

  const updatePasajesReservados = (updatedProps: any) => {
    setPasajesReservados(updatedProps);
  };

  const fullRestartResultados = () => {
    setTarifaSeleccionada(defaultState.tarifaSeleccionada);
    setPasajesReservados(defaultState.pasajesReservados);
    setMensajeMapa(defaultState.mensajeMapa);
    setMensajeReserva(defaultState.mensajeReserva);
  };

  useEffect(() => {
    let arrPasajes = {
      ...tarifaSeleccionada[idaVuelta as keyof typeof tarifaSeleccionada],
    };
    let temp = arrPasajes.segments.map((e: any) => {
      return { ...e, fare_classes: arrPasajes.fare_classes };
    });

    setLocalPasajes([...temp]);
  }, [idaVuelta, tarifaSeleccionada]);

  useEffect(() => {
    // mapeo tipos de pasaje con sus respectivas cantidades
    let pas: any = [];
    for (let i = 0; i < reserva?.pasajes?.length; i++) {
      for (let m = 0; m < reserva?.pasajes?.[i]?.cantidad; m++) {
        pas.push({
          tipo: reserva?.pasajes?.[i]?.id,
          name: reserva?.pasajes?.[i]?.name,
          numero: "",
        });
      }
    }

    // mapeo segmentos y les agrego sus tipos de pasajes
    let arrPasajes = localPasajes.map((e: any) => {
      return {
        ...e,
        pasajes: [...pas],
        etapa: idaVuelta,
      };
    });

    if (idaVuelta === "departures") {
      setPasajesReservados([...arrPasajes]);
    } else if (idaVuelta === "returns") {
      let temp = [...pasajesReservados, ...arrPasajes];
      const uniqueData = temp.filter((obj, index, self) => {
        return (
          index === self.findIndex((t) => t.display_name === obj.display_name)
        );
      });

      setPasajesReservados([...uniqueData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserva.pasajes, idaVuelta, localPasajes]);

  return (
    <ResultadosContext.Provider
      value={{
        tarifaSeleccionada,
        firstUpdateTarifaSeleccionada,
        updateTarifaSeleccionada,
        pasajesReservados,
        updatePasajesReservados,
        idaVuelta,
        setIdaVuelta,
        fullRestartResultados,
        mensajeReserva,
        setMensajeReserva,
        mensajeMapa,
        setMensajeMapa,
      }}
    >
      {children}
    </ResultadosContext.Provider>
  );
};
