import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { InitialContext } from "../../hooks/InitialContext";
import { PagoContext } from "../../hooks/PagoContext";

const ConfirmacionPago: React.FC = () => {
  const navigate = useNavigate();

  const { cliente, restartReserva } = useContext(InitialContext);

  const { totalPrice, pagosRealizados, resultadoOrder, tickets } =
    useContext(PagoContext);

  useEffect(() => {
    if (cliente === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente]);

  useEffect(() => {
    restartReserva();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="vue-app" className="max-width-4 mx-auto mb4">
      <div className="btrz-page-header clearfix flex items-center pt2 pb2 sm-pt3 sm-pb3">
        <div className="col-6 relative">
          <h2 className="font-family-title fs3 sm-fs1 bold sm-regular color-grey-darkest">
            Confirmación de pago
          </h2>
        </div>
        <div className="col-6 relative"></div>
        <div id="content"></div>
      </div>
      <div className=" btrz-slideInDown no-content-animation p3 fs6 sm-fs4 box-shadow-black-30 mb3 flex bg-success color-white letter-spacing">
        <div className="">
          <i className="fa mr3 fa-check-circle"></i>
        </div>
        <div className="flex-auto left-align">
          El pago fue aceptado. Email enviado a: {cliente?.email}
        </div>
        <div className="inverted-color hide">
          <div
            tabIndex={0}
            className="p2 color-grey-lighter-hover pointer opacity8"
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>
      <div className="clearfix">
        <div className="btrz-card inline-block mr2 sm-mr3 col-12 p4 sm-p5 bg-white box-shadow-black-10">
          <h3 className="sm-fs4 fs5 uppercase mb2 color-accent mb3">
            Detalles de la transacción
            <div
              title="Print transaction"
              className="right color-accent text-decoration-none pointer"
              onClick={() =>
                window.open(process.env.REACT_APP_URL + "/" + tickets, "_blank")
              }
            >
              <i className="fa fa-print"></i>
            </div>
          </h3>
          <table className="border-none font-family-site sm-fs3 fs4 bg-transparent col-12">
            <tbody>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  # de transacción
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  {/* <a
                    href="/tickets/transactions/652ef58bd856b20539416cb7"
                    className="mr2"
                  > */}
                  {resultadoOrder}
                  {/* </a> */}
                </td>
              </tr>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Total pagado
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  $ {totalPrice}
                </td>
              </tr>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Nombre del cliente
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  {cliente?.firstName + " " + cliente?.lastName}
                </td>
              </tr>
              <tr className="border-bottom border-grey-lighter">
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                  Correo electrónico
                </td>
                <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                  {cliente?.email}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  className="left-align fs6 sm-fs4 pt5 color-grey-darkest medium"
                >
                  <h4 className="font-family-site sm-fs5 fs6 mb2 uppercase color-accent">
                    Pago
                  </h4>
                </td>
              </tr>
              {pagosRealizados.map((e: any, i: number) => {
                return (
                  <tr className="border-bottom border-grey-lighter" key={i}>
                    <td className="left-align fs6 sm-fs4 p3 color-grey-darkest medium">
                      {e.method_data.displayName}
                    </td>
                    <td className="left-align fs6 sm-fs4 p3 color-grey-darkest">
                      $ {e.monto}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ConfirmacionPago;
