import React from "react";

function Footer() {
  return (
    <section className="fs7 center color-grey pt3 pb200">
      Copyright © 2023 Betterez Canada Inc. All rights reserved.
    </section>
  );
}

export default Footer;
