import React, { useContext } from "react";
import { handleCloseLIM } from "../../hooks/Resultados/hooks";
import { unSelectSeatLIM } from "./AsientosLIM";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type DetalleAsientoLIMProps = {
  pasaje: any;
  segmento: any;
};

const DetalleAsientoLIM: React.FC<DetalleAsientoLIMProps> = ({
  pasaje,
  segmento,
}) => {
  const { pasajesReservados, updatePasajesReservados } =
    useContext(ResultadosContext);

  const unSelect = () => {
    unSelectSeatLIM(pasaje.seatId);
  };

  return (
    <div>
      <div
        draggable="false"
        className="btrz-pill mb1 relative rounded-max border flex items-center  fs6 sm-fs5 px2 py2 mb1 color-white bg-success border-success inverted-color"
      >
        <span className="px2 flex-auto">
          {pasaje.sectionName} - {pasaje.rowLabel} - {pasaje.seatNumber}
        </span>
        <span
          tabIndex={0}
          className="right mr2 px1 pointer color-grey-darkest"
          onClick={() =>
            handleCloseLIM(
              pasajesReservados,
              segmento,
              updatePasajesReservados,
              pasaje,
              unSelect
            )
          }
        >
          <i className="fa fa-times opacity8"></i>
        </span>
      </div>
    </div>
  );
};

export default DetalleAsientoLIM;
