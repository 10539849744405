import React, { useEffect, useState } from "react";

type MethodProps = {
  payment: any;
  setPayment: any;
  faltantes: any;
  tipos: any;
};

const Credit: React.FC<MethodProps> = ({
  payment,
  setPayment,
  faltantes,
  tipos,
}) => {
  const [localTipos, setLocalTipos] = useState<any>([]);

  useEffect(() => {
    const trueKeys = [];

    for (const key in tipos) {
      if (tipos[key] === true) {
        trueKeys.push(key);
      }
    }

    setLocalTipos(trueKeys);
  }, [tipos]);

  return (
    <div className="clearfix sm-mxn4 remove-mb4-last-child">
      <div className="mb4 border-top border-grey-lighter"></div>
      <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
        <div className="btrz-select-form">
          <label
            htmlFor="_t26h8wk4z"
            className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest"
          >
            Tipo de tarjeta
          </label>
          <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
            <div className="absolute flex justify-end pointer-events-none height100 width100">
              <div className="mr3">
                <span className="flex items-center height100 pointer-events-none sm-fs3 fs4 color-grey ">
                  <i className="fa fa-chevron-down "></i>
                </span>
              </div>
            </div>
            <select
              id="_t26h8wk4z"
              name="cardType"
              className="ff-max-height font-family-site rounded border-none col-12 sm-fs3 fs4 py3 px3 bg-transparent color-grey-darkest pr4 capitalize"
              value={payment?.cardType ? payment?.cardType : ""}
              onChange={(e) =>
                setPayment((prevPayment: any) => {
                  return {
                    ...prevPayment,
                    cardType: e.target.value,
                  };
                })
              }
            >
              {localTipos.map((e: string) => {
                return (
                  <option value={e} className="capitalize">
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
        <div className="btrz-input-form">
          <label
            htmlFor="_v83lsz1id"
            className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest"
          >
            Autorización #
          </label>
          <div
            className={`rounded border mb1px relative  ${
              faltantes.includes("autorizacion-credit")
                ? " border-danger"
                : "border-grey-lightest border-accent-hover"
            } border`}
          >
            <input
              id="_v83lsz1id"
              name="inPersonCreditAuthorization"
              type="text"
              value={
                payment?.autorizacion_credit ? payment?.autorizacion_credit : ""
              }
              onChange={(e) =>
                setPayment((prevPayment: any) => {
                  return {
                    ...prevPayment,
                    autorizacion_credit: e.target.value,
                  };
                })
              }
              autoComplete="on"
              className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  "
            />
          </div>
          {faltantes.includes("autorizacion-credit") && (
            <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
              <span>
                <i className="fa fa-exclamation-circle mr1"></i>Código inválido
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Credit;
