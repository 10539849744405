import React from "react";
import { getDuracion, getFormatDate } from "../../hooks/commonFunctions";

type SegmentoProps = {
  segmentos: [
    {
      arrival_date: string;
      arrival_time: string;
      brand: string;
      company: string;
      departure_date: string;
      departure_time: string;
      destination_id: string;
      destination_name: string;
      operating_company: string;
      origin_id: string;
      origin_name: string;
      stops: any;
      display_name: string;
    }
  ];
  waiting: any;
};

const Segmentos: React.FC<SegmentoProps> = ({ segmentos, waiting }) => {
  return (
    <div>
      <div
        aria-labelledby="itineraryLbl"
        className="bg-white color-grey-darkest flex p3 box-shadow-black-10 sm-mx4 mx3"
      >
        <div className="flex flex-column justify-center p2">
          <div className="pb1">
            <i className="fa fa-circle-o fs5"></i>
          </div>
          <div
            className="dotted-left-border border-grey-lighter flex-auto"
            style={{ margin: "0 0 0 2px" }}
          ></div>
          <div className="pt1">
            <i className="fa fa-map-marker fs3"></i>
          </div>
        </div>
        <div>
          {segmentos.map((e, index) => {
            return (
              <div key={index}>
                <div role="list" className="flex flex-column">
                  <div role="listitem" className="sm-pl3 sm-py2 sm-pr2">
                    <div
                      aria-label="Lun Sep 25, 2023"
                      className="fs6 regular mb1 color-grey"
                    >
                      {getFormatDate(e.departure_date, "-")}
                    </div>
                    <div className="medium mb2 fs6 letter-spacing uppercase color-grey-darkest">
                      <span className="color-black">{e.brand} - </span>
                      {e.display_name}
                    </div>
                    <div className="px3 py2 border-left1 border-info color-grey fs5 line-height-4">
                      <span className="bold">
                        {e.stops[0].origin_name} ({e.stops[0].departure_time})
                        <i className="fa fa-long-arrow-right ml1"></i>
                      </span>

                      {e.stops.map((s: any, i: number) => {
                        if (i !== e.stops.length - 1) {
                          return (
                            <span className="">
                              {" "}
                              {s.destination_name} ({s.arrival_time})
                              <i className="fa fa-long-arrow-right ml1"></i>
                            </span>
                          );
                        } else {
                          return (
                            <span className="bold">
                              {" "}
                              {s.destination_name} ({s.arrival_time})
                            </span>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                {index < segmentos.length - 1 && (
                  <div className="fs6 color-info mb3 border border-info px3 py2 rounded-max inline-block">
                    <span className="uppercase color-info mr2">
                      <i className="fa fa-exchange mr2"></i>Transbordo
                    </span>{" "}
                    <i className="fa fa-clock-o color-info mr1"></i>{" "}
                    <span aria-label="Tiempo de espera 1hr">
                      Tiempo de espera:{" "}
                      {segmentos[index + 1].company === "LIM"
                        ? getDuracion(
                            segmentos[index + 1].stops[0].waiting_time
                          )
                        : getDuracion(waiting.waiting_time)}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Segmentos;
