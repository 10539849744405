import React, { Dispatch, SetStateAction, useState, useEffect } from "react";

type TypeFiltros = {
  parametro: { key: string; icono: string };
  setParametro: Dispatch<SetStateAction<{ key: string; icono: string }>>;
  parametroOrden: boolean;
  setParametroOrden: Dispatch<SetStateAction<boolean>>;
  setHorario: Dispatch<SetStateAction<string>>;
  setTransfers: Dispatch<SetStateAction<string>>;
};

const Filtros: React.FC<TypeFiltros> = ({
  parametro,
  setParametro,
  parametroOrden,
  setParametroOrden,
  setHorario,
  setTransfers,
}) => {
  const [horas, setHoras] = useState("00");
  const [minutos, setMinutos] = useState("00");

  useEffect(() => {
    setHorario(horas + ":" + minutos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horas, minutos]);

  return (
    <div className="p2 mb3 sm-flex justify-between bg-grey-darker rounded inverted-color">
      {/* COMIENZA ORDENA */}
      <div className="flex mb2 sm-mb0">
        {[
          {
            key: "departure_time",
            icono: "fa-clock-o",
            tooltip: "Ordenar por horario",
          },
          { key: "price", icono: "fa-dollar", tooltip: "Ordenar por precio" },
          {
            key: "transfers",
            icono: "fa-map-marker",
            tooltip: "Ordenar por conexiones",
          },
        ].map(
          (e: { key: string; icono: string; tooltip: string }, i: number) => {
            return (
              <button
                key={i}
                type="button"
                className="relative font-family-site rounded pointer sm-fs3 fs4 col-12 mr2 color-grey-darkest border border-grey bg-transparent bg-black-10-hover bg-black-10-focus square-width36px py2 px2 sm-fs5 fs6 pointer "
                title={e.tooltip}
                onClick={() => {
                  let temp = parametro;
                  setParametroOrden((prevParametroOrden) =>
                    temp.key === e.key ? !prevParametroOrden : true
                  );
                  setParametro(e);
                }}
              >
                <i className={`fa ${e.icono}`}></i>
                <div
                  className="absolute right-0 top-0"
                  style={{ margin: "0.1rem 0.1rem 0px 0px" }}
                >
                  <i
                    className={`fa ${
                      parametro.key === e.key &&
                      (parametroOrden
                        ? "fa-long-arrow-up"
                        : "fa-long-arrow-down")
                    }`}
                  ></i>
                </div>
              </button>
            );
          }
        )}
      </div>
      {/* FIN ORDENA */}
      {/* COMIENZA FILTRA */}
      <div className="sm-flex sm-col-8">
        <div className="sm-f5 fs6 sm-col-3 sm-right-align pr2 py2 color-grey-darkest medium inline-block">
          Salida de:
        </div>
        <div className="btrz-time-picker sm-col-5 permanent-border mb2 sm-mb0 inline-block">
          <div className="flex">
            <div className="flex border border-accent-hover rounded bg-transparent border-grey-lightest">
              <div className="btrz-select-form center-last hour">
                <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                  <select
                    id="_nfzhb8m52"
                    name="hours"
                    className="ff-max-height font-family-site rounded border-none col-12 py2 px3 sm-fs5 fs6  bg-transparent color-grey-darkest "
                    onChange={(e) => setHoras(e.target.value)}
                  >
                    {Array.from({ length: 24 }).map((e, i) => {
                      return (
                        <option key={i} value={i}>
                          {i < 10 ? "0" + i : i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="px1 font-family-site bg-transparent color-grey-darkest sm-fs5 fs6 py2">
                :
              </div>
              <div className="btrz-select-form center-last minute">
                <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                  <select
                    id="_thr4aj4lg"
                    name="minutes"
                    className="ff-max-height font-family-site rounded border-none col-12 py2 px3 sm-fs5 fs6  bg-transparent color-grey-darkest "
                    onChange={(e) => setMinutos(e.target.value)}
                  >
                    {Array.from({ length: 60 }).map((e, i) => {
                      return (
                        <option key={i} value={i}>
                          {i < 10 ? "0" + i : i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* <button
              tabIndex={0}
              type="button"
              className="relative font-family-title rounded ml2 px3 color-grey-darkest border border-grey bg-transparent bg-black-10-hover bg-black-10-focus undefined pointer "
            >
              <span>AM</span>
            </button> */}
          </div>
        </div>

        <div className="sm-col-5">
          <div className="btrz-select-form sm-ml2 permanent-border">
            <div className="rounded border mb1px relative border-accent-hover border border-accent-hover border-grey-lightest">
              <div className="absolute flex justify-end pointer-events-none height100 width100">
                <div className="mr3">
                  <span className="flex items-center height100 pointer-events-none sm-fs4 fs5 line-height-6 color-grey ">
                    <i className="fa fa-chevron-down "></i>
                  </span>
                </div>
              </div>
              <select
                id="_9t7l4yo9k"
                name="trip-bar-options"
                className="ff-max-height font-family-site rounded border-none col-12 py2 px3 sm-fs5 fs6  bg-transparent color-grey-darkest pr4"
                onChange={(e) => setTransfers(e.target.value)}
              >
                <option key="*" value="*">
                  Mostrar todo
                </option>
                <option key="0" value="0">
                  Directo
                </option>
                <option key="1" value="1">
                  Hasta 1 conexión
                </option>
                <option key="2" value="2">
                  Hasta 2 conexiones
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* FIN FILTRA */}
    </div>
  );
};

export default Filtros;
