import { getDestinos } from "../../services/DestinosService";
import { getResultados } from "../../services/ResultadosService";

export const getTickets = async (reserva: any) => {
  let tickets = "";
  let temp = [...reserva.pasajes].filter((e: any) => e.cantidad !== 0);

  temp.map((e: any, i: number) => {
    if (i !== 0) {
      tickets = tickets + "," + e.id + ":" + e.cantidad;
    } else {
      tickets = tickets + e.id + ":" + e.cantidad;
    }

    return tickets;
  });

  return tickets;
};

// CON LOS PARAMETROS INGRESADOS
// COMPRUEBA QUE NO HAYA CAMPOS REQUERIDOS VACÍOS
// REALIZA LA BÚSQUEDA
export const handleSearch = async (
  campos_obligatorios: any,
  reserva: any,
  updateFaltantes: any,
  setIsLoading: any,
  setError: any,
  updateResultados: any,
  firstUpdateTarifaSeleccionada: any,
  navigate: any,
  idaVuelta: string,
  logout: any
) => {
  try {
    let requeridos: string[] = [];

    campos_obligatorios.map((e: any) => {
      if (e === "origen" || e === "destino") {
        if (reserva[e].id.length === 0) {
          requeridos.push(e);
        }
      } else if (e === "salida") {
        if (reserva.salida === null) {
          requeridos.push(e);
        }
      } else {
        let pasaje = reserva.pasajes.filter((m: any) => {
          return m.cantidad > 0;
        });

        if (pasaje.length === 0) {
          requeridos.push(e);
        } else {
          let necesitaCompanion = pasaje.find((m: any) => {
            return m.companion === "requiresCompanion";
          });
          let hayCompanion = pasaje.some((m: any) => {
            return m.companion === "isCompanion";
          });

          if (necesitaCompanion && hayCompanion === false) {
            requeridos.push("companion");
          }
        }
      }

      return e;
    });

    updateFaltantes(requeridos);

    // SI NO HAY CAMPOS OBLIGATORIOS FALTANTES
    if (requeridos?.length === 0) {
      setIsLoading(true);
      await fetchDataSearch(
        reserva,
        setError,
        updateResultados,
        firstUpdateTarifaSeleccionada,
        navigate,
        idaVuelta,
        logout
      );
    }
  } finally {
    setIsLoading(false);
  }
};

export const fetchDataSearch = async (
  reserva: any,
  setError: any,
  updateResultados: any,
  firstUpdateTarifaSeleccionada: any,
  navigate: any,
  idaVuelta: string,
  logout: any
) => {
  try {
    let tickets = await getTickets(reserva);
    let temp: any = await getResultados(
      `?origin=${reserva.origen.id}&originCompany=${
        reserva.origen.company
      }&destination=${reserva.destino.id}&destinationCompany=${
        reserva.destino.company
      }&departureDate=${reserva.salida}&returnDate=${
        reserva.vuelta !== null ? reserva.vuelta : ""
      }&ticketTypes=${tickets}`,
      logout
    );

    // Y EXISTE LA RUTA
    if (temp?.departures?.[0]) {
      setError(false);

      try {
        updateResultados(temp);
        firstUpdateTarifaSeleccionada(
          {
            ...temp.departures[0],
            fare_classes: temp.departures[0].fare_classes[0],
          },
          idaVuelta
        );
      } finally {
        navigate(
          `/reservation/?origin=${reserva.origen.id}&originCompany=${
            reserva.origen.company
          }&destination=${reserva.destino.id}&destinationCompany=${
            reserva.destino.company
          }&departureDate=${reserva.salida}&returnDate=${
            reserva.vuelta !== null ? reserva.vuelta : ""
          }&ticketTypes=${tickets}`
        );
      }
    } else {
      setError(true);
    }
  } catch (error) {
    setError(true);
  }
};

export const fetchDataDestinos = async (reserva: any, logout: any) => {
  try {
    const result = await getDestinos(
      reserva.origen.id,
      reserva.origen.company,
      logout
    );
    return result;
  } catch (error) {
    console.error("Error en la solicitud:", error);
    return [];
  }
};
