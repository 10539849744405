import React, { useContext } from "react";
import Calendar from "../common/Calendar";
import Select from "../common/Select";
import { InitialContext } from "../../hooks/InitialContext";

type DatosProps = {
  origenes: any;
  destinos: any;
  isLoadingDestinos: any;
};

const Datos: React.FC<DatosProps> = ({
  origenes,
  destinos,
  isLoadingDestinos,
}) => {
  const { reserva } = useContext(InitialContext);

  return (
    <div className="btrz-card mb3 p4 sm-p5 bg-white box-shadow-black-10">
      <div className="clearfix sm-mxn4 remove-mb4-last-child">
        <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
          <Select
            title="Desde"
            option="Seleccione un origen"
            disabled={false}
            options={origenes}
            type="origen"
          />
        </div>
        <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
          <Select
            title="Hasta"
            option={isLoadingDestinos ? "Cargando..." : "Seleccione un destino"}
            disabled={
              reserva?.origen?.name !== "" && !isLoadingDestinos ? false : true
            }
            options={destinos}
            type="destino"
          />
        </div>
      </div>
      <div className="clearfix sm-mxn4 remove-mb4-last-child">
        <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
          <Calendar title="Fecha de salida (mm/dd/yyyy)" type="salida" />
        </div>
        <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
          <Calendar title="Fecha de regreso (opcional)" type="vuelta" />
        </div>
      </div>
    </div>
  );
};

export default Datos;
