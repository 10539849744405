import { getOccupation } from "../../services/OccupationService";
import { getReserve } from "../../services/ReserveService";
import { Resultados } from "../../types/ResultadoType";
import { TarifaSeleccionadaType } from "../../types/TarifaSeleccionadaType";

export const areResultsValid = (resultados: Resultados) => {
  return !(
    resultados === undefined ||
    (typeof resultados === "object" && "errorCode" in resultados)
  );
};

export const validTarifa = (
  tarifaSeleccionada: TarifaSeleccionadaType,
  idaVuelta: string
) => {
  let temp = tarifaSeleccionada[idaVuelta as keyof typeof tarifaSeleccionada];
  if (temp) {
    return temp;
  } else {
    return { segments: {} };
  }
};

export const updateReservaConPasajes = async (
  numeroPasaje: any,
  estaReservando: boolean,
  indice: string,
  segmento: any,
  setIsLoading: any,
  setMensajeReserva: React.Dispatch<React.SetStateAction<boolean>>,
  dataReserve: any,
  logout: any,
  exito?: any
) => {
  var formdata = new FormData();
  formdata.append("seat", numeroPasaje);
  formdata.append("origin", segmento.origin_id);
  formdata.append("destination", segmento.destination_id);
  formdata.append("departureTime", segmento.departure_time);
  formdata.append(
    "departureDate",
    segmento.departure_date.replaceAll("-", "/")
  );
  formdata.append("tripId", segmento.trip_id);
  formdata.append("seatType", indice);
  formdata.append("requestId", segmento.request_id);
  formdata.append("reserve", estaReservando.toString());
  formdata.append("isReturn", dataReserve.isReturn.toString()); // es viaje de vuelta
  formdata.append("tripType", dataReserve.tripType); // r (redondo, ida y vuelta) o s (simple)

  try {
    const result = await getReserve(formdata, logout);
    setMensajeReserva(false);
    console.log("Respuesta exitosa: ", result);
    exito();
  } catch (error) {
    console.error("Error en la solicitud:", error);
    setMensajeReserva(true);
  }

  setIsLoading(false);
};

export const fetchDataOccupation = async (
  segmento: any,
  setMapa: any,
  setIsLoading: any,
  setMensajeMapa: React.Dispatch<React.SetStateAction<boolean>>,
  idaVuelta: string,
  pasajesReservados: any,
  logout: any
) => {
  if (segmento?.trip_id && segmento?.request_id) {
    const ida = pasajesReservados.find(
      (e: any) => e.etapa === "departures" && e.company === "GEB"
    );

    if (idaVuelta === "departures") {
      try {
        let query = "";

        query = `?origin=${segmento.origin_id}&destination=${
          segmento.destination_id
        }&departureTime=${
          segmento.departure_time
        }&departureDate=${segmento.departure_date.replaceAll(
          "-",
          "/"
        )}&departureTripId=${segmento.trip_id}&requestId=${
          segmento.request_id
        }`;

        const result = await getOccupation(query, logout);
        setMapa(result);
        setMensajeMapa(false);
      } catch (error) {
        console.error("Error en la solicitud:", error);
        setMensajeMapa(true);
      }
    } else if (
      idaVuelta === "returns" &&
      ida.departure_time !== segmento.departure_time
    ) {
      try {
        let query = "";
        query = `?origin=${ida.origin_id}&destination=${
          ida.destination_id
        }&departureTime=${
          ida.departure_time
        }&departureDate=${ida.departure_date.replaceAll(
          "-",
          "/"
        )}&departureTripId=${
          ida.trip_id
        }&returnDate=${segmento.departure_date.replaceAll(
          "-",
          "/"
        )}&returnTime=${segmento.departure_time}&returnTripId=${
          segmento.trip_id
        }&requestId=${segmento.request_id}`;

        const result = await getOccupation(query, logout);
        setMapa(result);
        setMensajeMapa(false);
      } catch (error) {
        console.error("Error en la solicitud:", error);
        setMensajeMapa(true);
      }
    }

    setIsLoading(false);
  }
};

export const handleReservaGEB = (
  pasaje: any,
  pasajesReservados: any,
  segmento: any,
  updatePasajesReservados: any,
  startTimer: any,
  setMensajeReserva: React.Dispatch<React.SetStateAction<boolean>>,
  dataReserve: any,
  setLoaderColectivo: any,
  logout: any
) => {
  let arrPas = [...pasajesReservados];
  let objPasaje = arrPas.find((e) => e.display_name === segmento.display_name);

  if (objPasaje !== undefined) {
    let primero = objPasaje.pasajes.findIndex((e: any) => e.numero === "");

    if (primero !== -1) {
      setLoaderColectivo(true);

      const exito = () => {
        objPasaje.pasajes[primero] = {
          ...objPasaje.pasajes[primero],
          numero: pasaje.number,
          ...pasaje,
        };

        startTimer();
        updatePasajesReservados(arrPas);
      };

      // interaccion con base solo para GEB
      updateReservaConPasajes(
        pasaje.number,
        true,
        objPasaje.pasajes[primero].tipo,
        segmento,
        setLoaderColectivo,
        setMensajeReserva,
        {
          isReturn: objPasaje.etapa === "returns",
          tripType: dataReserve,
        },
        logout,
        exito
      );
    }
  }
};

export const handleReservaLIM = (
  pasaje: any,
  pasajesReservados: any,
  segmento: any,
  updatePasajesReservados: any,
  postMessage: any
) => {
  let pas = [...pasajesReservados];
  let objPasaje = pas.find((e) => e.display_name === segmento.display_name);

  if (objPasaje !== undefined) {
    let ind = objPasaje.pasajes.findIndex((e: any) => e.numero === "");

    // valido que no tenga los pasajes necesarios antes de agregarlo
    if (ind !== -1) {
      let existe = objPasaje.pasajes.some(
        (p: any) => p.numero === pasaje.seatNumber
      );

      if (!existe) {
        objPasaje.pasajes[ind] = {
          ...objPasaje.pasajes[ind],
          numero: pasaje.seatNumber,
          ...pasaje,
        };

        // interaccion con iframe
        postMessage();

        updatePasajesReservados(pas);
      }
    }
  }
};

export const handleCloseGEB = (
  pasajesReservados: any,
  segmento: any,
  updatePasajesReservados: any,
  pasaje: any,
  resetTimer: any,
  setIsLoading: any,
  setMensajeReserva: React.Dispatch<React.SetStateAction<boolean>>,
  dataReserve: any,
  logout: any
) => {
  let temp = [...pasajesReservados];

  if (temp !== undefined) {
    setIsLoading(true);

    // VACIO EL TIPO DE PASAJE PARA QUE SE PUEDA SELECCIONAR
    let ind = temp.findIndex(
      (e: any) => e.display_name === segmento.display_name
    );
    let indPas = temp[ind].pasajes.findIndex(
      (e: any) => e.numero === pasaje.number
    );

    const exito = () => {
      temp[ind].pasajes[indPas] = {
        tipo: temp[ind].pasajes[indPas].tipo,
        name: temp[ind].pasajes[indPas].name,
        numero: "",
      };

      resetTimer();
      updatePasajesReservados(temp);
    };

    updateReservaConPasajes(
      pasaje.number,
      false,
      temp[ind].pasajes[indPas].tipo,
      segmento,
      setIsLoading,
      setMensajeReserva,
      {
        isReturn: temp[ind].etapa === "returns",
        tripType: dataReserve,
      },
      logout,
      exito
    );
  }
};

export const handleCloseLIM = (
  pasajesReservados: any,
  segmento: any,
  updatePasajesReservados: any,
  pasaje: any,
  unSelect: any
) => {
  let temp = [...pasajesReservados];

  if (temp !== undefined) {
    let ind = temp?.findIndex(
      (e: any) => e.display_name === segmento.display_name
    );
    let indPas = temp[ind].pasajes.findIndex(
      (e: any) => e.numero === pasaje.seatNumber
    );

    if (ind !== -1 && indPas !== -1) {
      temp[ind].pasajes[indPas] = {
        tipo: temp[ind].pasajes[indPas].tipo,
        name: temp[ind].pasajes[indPas].name,
        numero: "",
      };

      unSelect();

      updatePasajesReservados(temp);
    }
  }
};

export const handleLIMTaken = (
  pasajesReservados: any,
  updatePasajesReservados: any, 
  resetTimer: () => void
) => {
  let temp = [...pasajesReservados];

  temp.map((e) => {
  //   if (e.company === "GEB") {
  //     e.pasajes.map((pasaje: any) => {
  // updateReservaConPasajes(
  //       pasaje.numero,
  //       false,
  //       pasaje.tipo,
  //       segmento,
  //       setIsLoading,
  //       setMensajeReserva,
  //       {
  //         isReturn: pasaje.etapa === "returns",
  //         tripType: dataReserve,
  //       },
  //       logout,
  //       exito
  //     )
  // })
  //   } 
    
      e.pasajes = e.pasajes.map((pasaje: any) => {
        return {
          tipo: pasaje.tipo,
          name: pasaje.name,
          numero: "",
        };
      });

    return e;
  });

  resetTimer();
  updatePasajesReservados(temp);
};

export const handleExpireLIM = (
  pasajesReservados: any,
  segmento: any,
  updatePasajesReservados: any,
  pasaje: any,
  unSelect: any,
  muestraExpira: any,
  setMuestraExpira: any
) => {
  let temp = [...pasajesReservados];

  if (temp !== undefined) {
    let obj = temp.find((e: any) => e?.display_name === segmento?.display_name);

    if (obj !== undefined) {
      let ind = obj.pasajes.findIndex((e: any) => e.seatId === pasaje.seat_id);

      if (ind !== -1) {
        try {
          let tempExpira = [...muestraExpira];
          tempExpira = [...tempExpira, obj.pasajes[ind]];
          setMuestraExpira([...tempExpira]);
        } finally {
          obj.pasajes[ind] = {
            tipo: obj.pasajes[ind].tipo,
            name: obj.pasajes[ind].name,
            numero: "",
          };

          unSelect();

          updatePasajesReservados(temp);
        }
      }
    }
  }
};

export const handleExpireGEB = (
  pasajesReservados: any,
  updatePasajesReservados: any,
  setIsLoading: any,
  setMensajeReserva: React.Dispatch<React.SetStateAction<boolean>>,
  dataReserve: any,
  muestraExpira: any,
  setMuestraExpira: any,
  logout: any
) => {
  let temp = [...pasajesReservados];

  if (temp !== undefined) {
    let tempExpira = [...muestraExpira];

    // VACIO EL TIPO DE PASAJE PARA QUE SE PUEDA SELECCIONAR
    let vaciados = temp.map((p: any) => {
      if (p.company === "GEB") {
        return {
          ...p,
          // eslint-disable-next-line array-callback-return
          pasajes: p.pasajes.map((e: any) => {
            if (e.numero !== "") {
              tempExpira = [...tempExpira, e];

              const exito = () => {
                console.log("");
              };

              updateReservaConPasajes(
                e.numero,
                false,
                e.tipo,
                p,
                setIsLoading,
                setMensajeReserva,
                {
                  isReturn: p.etapa === "returns",
                  tripType: dataReserve,
                },
                logout,
                exito
              );
            }

            return { tipo: e.tipo, name: e.name, numero: "" };
          }),
        };
      } else {
        return { ...p };
      }
    });

    setMuestraExpira([...tempExpira]);

    updatePasajesReservados(vaciados);
  }
};
