import { Resultados } from "../types/ResultadoType";
import { request } from "./apiService";

export async function getResultados(
  query: string,
  logout: any
): Promise<Resultados> {
  const types = await request<Resultados>(`/route/trips/${query}`, logout);
  return types;
}
