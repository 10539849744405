import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getFormatDate } from "../../hooks/commonFunctions";
import { Resultado } from "../../types/ResultadoType";

type PropsResultados = {
  resultado: Resultado;
};

const HeaderResultados: React.FC<PropsResultados> = ({ resultado }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (resultado === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultado]);

  return (
    <>
      {resultado !== undefined && (
        <div className="sm-center mb3 bg-background pt3 sm-pt0 sticky sticky-submenu z1">
          <div
            draggable="false"
            className="btrz-pill mb1 relative rounded-max border flex items-center inline-block xs-col-12 mr2  fs6 sm-fs5 px2 py2 mb1 color-info border-info"
          >
            <i className="ml2 fa mr2 fa-calendar color-info"></i>
            <span className="px2 flex-auto">
              {getFormatDate(resultado?.departure_date, "-")}
            </span>
          </div>
          <div
            draggable="false"
            className="btrz-pill mb1 relative rounded-max border flex items-center inline-block xs-col-12  fs6 sm-fs5 px2 py2 mb1 color-white bg-info border-info inverted-color"
          >
            <i className="ml2 fa mr2 fa-map-marker color-grey-lighter"></i>
            <span className="px2 flex-auto capitalize">
              {resultado?.origin_name}
            </span>
          </div>
          <div className="inline-block absolute right-0 mr4 sm-hide md-hide lg-hide z1 fs7 sm-center color-info">
            <span className="fa-stack fa-lg mtn50">
              <i className="fa fa-circle fa-stack-2x fa-inverse "></i>
              <i className="fa fa-arrow-down fa-stack-1x"></i>
            </span>
          </div>
          <div className="inline-block xs-hide align-middle fs7 sm-center color-info">
            <span className="fa-stack fa-lg">
              <i className="fa fa-circle fa-stack-2x fa-inverse "></i>
              <i className="fa fa-arrow-right fa-stack-1x"></i>
            </span>
          </div>
          <div
            draggable="false"
            className="btrz-pill mb1 relative rounded-max border flex items-center inline-block xs-col-12  fs6 sm-fs5 px2 py2 mb1 color-white bg-info border-info inverted-color"
          >
            <i className="ml2 fa mr2 fa-map-marker color-grey-lighter"></i>
            <span className="px2 flex-auto capitalize">
              {resultado?.destination_name}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderResultados;
