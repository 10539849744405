import React, { useContext } from "react";
import { ResultadosContext } from "../../hooks/ResultadosContext";

interface AsientoProps {
  indice: number;
  arr: any;
  pasaje: any;
  seg: number;
}

const Asiento: React.FC<AsientoProps> = ({ indice, arr, pasaje, seg }) => {
  const { pasajesReservados } = useContext(ResultadosContext);

  const getDescripcion = () => {
    const obj = [...pasajesReservados].find(
      (p) => p.display_name === pasaje.display_name
    )?.pasajes?.[seg];

    if (pasaje.company === "GEB") {
      const numero = obj?.numero;
      const cupo = obj?.cupo;
      const fila =
        obj?.occupation?.findIndex(
          (o: any) => o.number === pasaje.pasajes[seg].numero
        ) + 1;

      return cupo + " asientos, Fila " + fila + ", Asiento " + numero;
    } else {
      const numero =
        Number(obj.seatNumber) < 10 ? "0" + obj.seatNumber : obj.seatNumber;

      return obj.sectionName + ", Fila " + obj.rowLabel + ", Asiento " + numero;
    }
  };

  return (
    <div
      key={indice}
      className={`flex items-center mb2 pb2 ${
        indice < arr.length - 1 && "border-bottom"
      } border-grey-lighter`}
    >
      <div className="flex-auto">
        <div className="regular truncate fs6 color-grey">
          {pasaje.origin_name}
          <div
            title={getDescripcion()}
            className="sm-fs3 fs4 medium color-grey-darkest wrap break-all"
          >
            <span className="">{getDescripcion()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Asiento;
