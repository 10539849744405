import React, {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import DetalleAsiento from "./DetalleAsiento";
import Espacio from "./tipos-asiento/Espacio";
import Ocupado from "./tipos-asiento/Ocupado";
import Disponible from "./tipos-asiento/Disponible";
import { fetchDataOccupation } from "../../hooks/Resultados/hooks";
import CantidadPasajes from "./CantidadPasajes";
import { useAuth } from "../../utils/AuthContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type AsientosEBProps = {
  segmento: any;
  cantidadPasajes: number;
  isShowing: boolean;
  segments: number;
  setSegments: Dispatch<SetStateAction<number>>;
  totalSegmentos: number;
  setLoaderColectivo: Dispatch<SetStateAction<boolean>>;
};

const AsientosEB: React.FC<AsientosEBProps> = ({
  segmento,
  cantidadPasajes,
  isShowing,
  segments,
  setSegments,
  totalSegmentos,
  setLoaderColectivo,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mapa, setMapa] = useState<any>();

  const { pasajesReservados, setMensajeMapa, mensajeReserva, idaVuelta } =
    useContext(ResultadosContext);

  const { logout } = useAuth();

  // al cambiar el segmento seleccionado
  // pido la ocupacion del colectivo
  useEffect(() => {
    setIsLoading(true);
    fetchDataOccupation(
      segmento,
      setMapa,
      setIsLoading,
      setMensajeMapa,
      idaVuelta,
      pasajesReservados,
      logout
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmento]);

  // si falla la reserva de asientos (principalmente si ya estaba ocupado el asiento)
  // recargo el mapa
  useEffect(() => {
    if (mensajeReserva === true) {
      setIsLoading(true);
      fetchDataOccupation(
        segmento,
        setMapa,
        setIsLoading,
        setMensajeMapa,
        idaVuelta,
        pasajesReservados,
        logout
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeReserva]);

  return (
    <>
      {segmento !== undefined &&
        (isLoading ? (
          <div style={{ display: `${isShowing ? "block" : "none"}` }}>
            <p>Cargando...</p>
          </div>
        ) : (
          <div>
            <CantidadPasajes
              cantidadPasajes={cantidadPasajes}
              isShowing={isShowing}
              segments={segments}
              setSegments={setSegments}
              segmento={segmento}
              totalSegmentos={totalSegmentos}
            />
            <div style={{ display: `${isShowing ? "block" : "none"}` }}>
              <div className="relative" style={{ minHeight: "450px" }}>
                <section
                  id="seatmaps-content"
                  className="seatmap-container seatmap-container-embed"
                >
                  <div
                    className="seatmap-components"
                    tabIndex={0}
                    aria-label="Use arrow keys to navigate through seats then click to select a seat."
                    style={{ backgroundColor: "rgb(255, 255, 255)" }}
                  >
                    <div
                      className="components"
                      style={{
                        transformOrigin: "left top",
                        transform: "scale(1.66667)",
                      }}
                    >
                      <div
                        className="component"
                        data-rotation="0"
                        style={{
                          zIndex: "2",
                          position: "absolute",
                        }}
                      >
                        <div className="component-content">
                          <div className="seatmap-section">
                            <div
                              aria-hidden="true"
                              id="rows-div"
                              className="rows-container-xlarge window-selectable"
                            >
                              <div className="rows">
                                <div className="seatmap-section-row">
                                  <div
                                    className="seats-container"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {mapa?.ground_floor?.map(
                                      (e: any, index: number) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                            key={index}
                                          >
                                            {e.occupation.map(
                                              (m: any, ind: number) => {
                                                return (
                                                  <div key={ind}>
                                                    {m.type === "space" ? (
                                                      <Espacio />
                                                    ) : m.available === false &&
                                                      !pasajesReservados
                                                        ?.find(
                                                          (s: any) =>
                                                            s.display_name ===
                                                            segmento.display_name
                                                        )
                                                        ?.pasajes?.some(
                                                          (s: any) =>
                                                            s.numero ===
                                                            m.number
                                                        ) ? (
                                                      <Ocupado
                                                        numero={m.number}
                                                      />
                                                    ) : (
                                                      <Disponible
                                                        columna={e}
                                                        asiento={m}
                                                        segmento={segmento}
                                                        cupo={mapa.cupo}
                                                        setLoaderColectivo={
                                                          setLoaderColectivo
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {mapa?.first_floor && (
                            <div className="seatmap-section">
                              <div
                                aria-hidden="true"
                                id="rows-div"
                                className="rows-container-xlarge window-selectable"
                              >
                                <div className="rows">
                                  <div className="seatmap-section-row">
                                    <div
                                      className="seats-container"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {mapa?.first_floor?.map(
                                        (e: any, index: number) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                              key={index}
                                            >
                                              {e.occupation.map(
                                                (m: any, ind: number) => {
                                                  return (
                                                    <div key={ind}>
                                                      {m.type === "space" ? (
                                                        <Espacio />
                                                      ) : m.available ===
                                                          false &&
                                                        !pasajesReservados
                                                          ?.find(
                                                            (s: any) =>
                                                              s.display_name ===
                                                              segmento.display_name
                                                          )
                                                          ?.pasajes?.some(
                                                            (s: any) =>
                                                              s.numero ===
                                                              m.number
                                                          ) ? (
                                                        <Ocupado
                                                          numero={m.number}
                                                        />
                                                      ) : (
                                                        <Disponible
                                                          columna={e}
                                                          asiento={m}
                                                          segmento={segmento}
                                                          cupo={mapa.cupo}
                                                          setLoaderColectivo={
                                                            setLoaderColectivo
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {pasajesReservados
                ?.find((i: any) => i.display_name === segmento.display_name)
                ?.pasajes?.filter((e: any) => {
                  return e.numero !== "";
                })
                .map((e: any, i: number) => {
                  return (
                    <div key={i}>
                      <DetalleAsiento
                        pasaje={e}
                        mapa={mapa}
                        segmento={segmento}
                        setLoaderColectivo={setLoaderColectivo}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
    </>
  );
};

export default AsientosEB;
