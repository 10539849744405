import React, { ReactNode } from "react";

interface LoaderProps {
  isLoading: boolean;
  children: ReactNode;
}

const Loader: React.FC<LoaderProps> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className="max-width-4 mx-auto mb4">
          <div className="top-0 bottom-0 left-0 right-0 z9 center color-grey justify-around flex items-center py3 fixed bg-background">
            <i className="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Loader;
