import React from "react";

interface ButtonProps {
  text: string;
  handleClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, handleClick }) => {
  return (
    <button
      // type="submit"
      type="button"
      className="relative font-family-title rounded col-12 color-white border border-accent box-shadow-black-50 bg-accent bg-black-10-hover py3 sm-fs3 px4 fs4 pointer"
      onClick={handleClick}
    >
      <i className="fa mr2 fa-caret-right right ml3 mrn2 sm-mr0 mt05"></i>
      <span>{text}</span>
    </button>
  );
};

export default Button;
