import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { InitialContext } from "../../hooks/InitialContext";
import { ResultadosContext } from "../../hooks/ResultadosContext";

type ClienteProps = {
  faltantes: any;
};

const Cliente: React.FC<ClienteProps> = ({ faltantes }) => {
  const { cliente, setCliente } = useContext(InitialContext);

  const { pasajesReservados } = useContext(ResultadosContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (pasajesReservados?.[0]?.pasajes?.[0]) {
      setCliente(pasajesReservados[0].pasajes[0]);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasajesReservados]);

  const handleChange = (key: string, value: string) => {
    setCliente((prevCliente: any) => {
      return { ...prevCliente, [key]: value };
    });
  };

  return (
    <div className="customer-information max-width-4 mx-auto clearfix">
      <div className="btrz-card mb3 p4 sm-p5 bg-white box-shadow-black-10">
        <h3 className="sm-fs4 fs5 sm-mb4 mb3 uppercase color-accent">
          Información del cliente
        </h3>
        <div className="clearfix sm-mxn4 remove-mb4-last-child">
          <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
            <div className="btrz-input-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Nombre
              </label>
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <input
                  value={cliente?.firstName ? cliente?.firstName : ""}
                  id="_3egvgrkvk"
                  name="firstName"
                  type="text"
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  autoComplete="on"
                  className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  "
                />
              </div>
              {faltantes?.includes("firstName") && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Requerido
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
            <div className="btrz-input-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Apellido
              </label>
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <input
                  value={cliente?.lastName ? cliente?.lastName : ""}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  id="_yg8s04p6z"
                  name="lastName"
                  type="text"
                  autoComplete="on"
                  className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  "
                />
              </div>
              {faltantes?.includes("lastName") && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Requerido
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="clearfix sm-mxn4 remove-mb4-last-child">
          <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
            <div className="btrz-input-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Teléfono
              </label>
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <input
                  value={cliente?.phone ? cliente?.phone : ""}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  id="_admp9wwjj"
                  name="phone"
                  type="text"
                  autoComplete="on"
                  className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  "
                />
              </div>
              {faltantes?.includes("phone") && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Requerido
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 sm-col sm-col-6 sm-px4 mb4">
            <div className="btrz-input-form">
              <label className="medium mb2 inline-block sm-fs4 fs6 color-grey-darkest">
                Correo electrónico
              </label>
              <div className="rounded border mb1px relative border-accent-hover border-grey border-accent-hover border">
                <input
                  value={cliente?.email ? cliente?.email : ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  id="_ih7q7mlcg"
                  name="email"
                  type="text"
                  autoComplete="on"
                  className="font-family-site rounded border-none color-grey-darkest col-12 sm-fs3 fs4 p3 bg-transparent  "
                />
              </div>
              {faltantes?.includes("email") && (
                <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                  <span>
                    <i className="fa fa-exclamation-circle mr1"></i>Requerido
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 sm-col sm-col-6 sm-px4 mb4"></div>
        </div>
        <div className="col-12 sm-col sm-col-6 mb4 fs6 sm-fs4">
          <div className="btrz-input-form">
            <input
              type="checkbox"
              className="outline-grey-lighter-focus"
              checked={cliente?.checked ? cliente?.checked === "true" : false}
              onChange={(e) =>
                handleChange("checked", e.target.checked.toString())
              }
            />
            <label className="color-grey-darkest">He leido y acepto los</label>
            <a className="color-accent pointer" href="/">
              Términos y condiciones
            </a>
          </div>
          <div>
            {faltantes?.includes("checked") && (
              <div className="btrz-error bg-danger text-shadow-black color-white p1 rounded   sm-fs4 fs6">
                <span>
                  <i className="fa fa-exclamation-circle mr1"></i>Requerido
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cliente;
